html {
  background-color: #ffffff;
  position: relative;
  height: 100%;
}

body {
  position: relative;
  height: 100%;
  color: #43454e;
  background-color: #ffffff;
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.46666667 !important;
  font-weight: 400;
  overflow: unset !important;
  text-rendering: optimizeLegibility;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;

  @media only screen and (max-width: 599px) {
    font-size: 14px;
    // font-size: 13px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 1.429em;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.2);
  }

  &::-webkit-scrollbar {
    height: 0.65em;
    width: 0.65em;
    background-color: transparent;

  }
}

@media screen and (max-width: 1279px) {

  // For app and mobile
  * {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
  }

  input,
  textarea {
    -webkit-user-select: auto !important;
    -moz-user-select: auto !important;
    -ms-user-select: auto !important;
    user-select: auto !important;
  }
}

:focus {
  outline-color: rgba(0, 0, 0, .24);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #43454e;
  // color: #294661;
  line-height: 1.2 !important;
  font-family: Roboto, Arial, sans-serif;
  text-transform: none
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
small,
mat-icon,
mat-cell,
mat-header-cell,
mat-footer-cell {
  &.dark-primary {
    color: #571f2f;
  }

  &.light-primary {
    color: #92344f;
  }

  &.dark-accent {
    color: #294661;
  }
}

p {
  // font-size: 1rem;
  font-size: 1em;
  font-family: Roboto, Arial, sans-serif;
}

textarea {
  max-height: 21.429em;
  font-family: Roboto, Arial, sans-serif;
}

ul {
  font-size: 1rem;
  list-style: none
}

strong,
.strong {
  font-weight: bold;
}

.modal-body,
.tou-container,
.scrollbar {
  touch-action: manipulation;

  &::-webkit-scrollbar-track {
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 1.429em;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.2);
  }

  &::-webkit-scrollbar {
    height: 0.357em;
    width: 0.357em;
    background-color: transparent;
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.clickable {
  cursor: pointer !important;
  outline-color: rgba(245, 247, 250, .24);

  &.clickable_hover {

    &:hover,
    &:focus {
      background-color: rgba(245, 247, 250, .72);
    }
  }
}

.break-line {
  white-space: normal !important;
  word-break: break-word !important;
}

.min-h-auto {
  min-height: auto !important;
}

form {
  width: 100%;
  max-width: 100%;
  margin: 0
}

.animated {
  visibility: visible !important;
}

.hide-on-init {
  visibility: hidden;
}


/* -- Mobile Nav -- */

@media only screen and (max-width: 599px) {
  .app-sidebar {
    display: none;
  }

  .sidebar-mobile-open .app-sidebar {
    display: block;
  }
}

/* -- Mobile Nav -- */

// Shadow for svg images
// 

img {
  &.shadow {
    -webkit-filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.72));
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.72));
  }
}

/* -- Mat Card -- */

.mat-card {
  // display: block;
  // position: relative;
  // float: left;
  background-color: #fff;
  margin-bottom: 1.071rem;
  border-radius: 8px !important;
  width: 100%;

  &.no-float {
    float: none !important;
  }
}

.mat-card.transparent {
  box-shadow: none;
  background: transparent;
}

.mat-card.transparent .head h2 {
  color: #43454e;
  font-size: 1.286em;
  margin-bottom: 0.714em;
  padding: 0;
  text-align: left;
  font-weight: 400;
}

//  h2.head {
//      color: #43454e;
//      font-size: 1.143em;
//      margin-bottom: 10px;
//      padding: 0;
//      text-align: left;
//      font-weight: 400;
//      width: 100%;
//      float: left;
//  }

.head {
  font-size: 1.071rem;
  font-weight: 500;
  line-height: 1.2;
  font-family: Roboto, Arial, sans-serif;
  text-transform: none;
  -webkit-margin-after: .8em;
  margin-block-end: .8em;
}

.apps {

  &:not(.apps_header) {

    position: relative;
    top: 1.786em;
  }

  .material-icons-outlined,
  .material-icons {
    font-size: 2.857em;
    width: 2.857em;
    height: auto;
    margin: 0 auto;
    color: inherit;
  }

  .mat-card {
    background: transparent;
    color: #43454e;
    width: auto;
    min-width: 6.214em;
    box-shadow: none;
    text-align: center;
    padding: 0.429em 0;
    margin: 0.143em 0;
    cursor: pointer;
    float: right;

    &.active,
    &:hover,
    &:focus {
      background: #607588;
      color: #fff;
      width: auto;
      box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);

      .material-icons-outlined,
      .material-icons {
        color: #fff;
      }
    }

    &.disabled {
      color: rgba(0, 0, 0, 0.24);
      cursor: not-allowed;
      pointer-events: none;
    }

    &.active {
      pointer-events: none;
    }

    p {
      color: inherit;
      padding: 0.357em 1.071rem 0;
      margin-bottom: 0;
      font-weight: 500;
      word-break: break-word;
    }
  }

  &.apps_header {
    .mat-card p {
      color: inherit;
      line-height: 1rem;
      padding: 0.357em 0.143em 0;
      word-break: break-word;
      white-space: normal;
    }
  }
}


/* -- Button Disabled CSS -- */

button {
  cursor: pointer;
}

button:focus {
  outline-color: transparent;
}

/* -- End Button Disabled CSS -- */

/* -- Misc. Tools -- */

.text-muted {
  color: #8e8f94 !important;
}

.modal-body {
  // max-height: 66vh;
  overflow-y: auto;

  @media (max-height: 568px) {
    max-height: calc(100vh - 7.857em);
  }

  // iPhone X
  @media (max-height: 845px) {
    max-height: 58vh;
  }

  // @media (max-height: 845px) {
  //     max-height: 60vh;
  // }

  // @media (max-height: 700px) {
  //     max-height: 55vh;
  // }

  // @media (max-height: 502px) {
  //     max-height: 45vh;
  // }
}

form h4,
.modal-body h4,
h4.modal-title {
  font-size: 1rem;
  font-weight: 500;
}

form h4.header {
  background-color: #ffffff;
  margin: 0 -1.071rem;
  padding: 0.714em 1.071rem;
  border-top: 0.071rem solid rgba(0, 0, 0, 0.1);
  border-bottom: 0.071rem solid rgba(0, 0, 0, 0.1);
}

.modal-footer {
  background: #ffffff;
  border-top: none;

  @media (max-height: 501px) {
    padding: 0.357em 1.071rem;
  }
}

.no-padding {
  padding: 0
}

.no-margin {
  margin: 0
}

.full-width {
  width: 100%
}

.half-width {
  width: 50%
}

/* -- End Misc. Tools -- */

/* -- Empty Full -- */

.empty-full {
  position: absolute;
  top: 4.286em;
  right: 0;
  bottom: 3.143em;
  left: 17.857em;
  z-index: 1;
  text-align: center;
  background-color: transparent;
  transition: left 0.3s cubic-bezier(0, 0, 0.2, 1);

  .empty-container {
    padding-top: 29vh;
    font-size: 1rem;

    img {
      max-height: 10.714em;
      margin: 0 auto;
      -webkit-filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.32));
      filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.32));

      @media (max-width: 500px) {
        max-height: 8.143em;
      }

      // @media (max-width: 375px) {
      //   max-height: 5.357em;
      // }
    }

    h2 {
      margin: 0;
      color: #43454e;
      word-break: break-word;
      z-index: 1;
      font-weight: 400;
      // font-size: 1.286em;
      font-size: 1rem;

      &.empty_long-text {
        margin: 0 auto;
        max-width: 435px;

        @media (max-width: 599px) {
          max-width: 320px;
        }
      }

      button {
        font-size: 14px;
      }
    }

    a {
      color: #607588;
    }

    small {
      margin-top: 1.071rem;
      display: block;
      font-weight: 400;
    }

    button {
      margin-top: 1.786em;
    }

    @media (max-width: 991px) {
      left: 0;
      padding-top: 12vh;
      padding-left: 1.071rem;
      padding-right: 1.071rem;
    }

    @media (max-width: 450px) {
      padding-top: 20vh;

      button,
      .empty button {
        margin-bottom: 1.786em;
      }
    }
  }
}

.nav-collapsed .empty-full {
  left: 0;
}

.empty-full-width {
  position: relative;
  display: block;
  width: 100%;
  padding: 10% 0;
  text-align: center;
  background-color: transparent;

  h2 {
    margin: 0;
    color: #43454e;
    word-break: break-word;
    font-weight: 400;

    &.empty_long-text {
      margin: 0 auto;
      max-width: 435px;

      @media (max-width: 599px) {
        max-width: 320px;
      }
    }

    button {
      font-size: 14px;
    }
  }

  button {
    margin-top: 1.786em;
  }

  a {
    color: #607588;
  }

  @media (max-width: 450px) {
    .empty-full-width button {
      margin-bottom: 1.786em;
    }
  }
}

/* -- End Empty Full -- */

/* -- Empty Contain -- */

.empty-contain {
  z-index: 1;
  text-align: center;
  background-color: transparent;
  transition: left 0.3s cubic-bezier(0, 0, 0.2, 1);

  img {
    max-height: 10.714em;
    margin: 0 auto;
    -webkit-filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.32));
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.32));

    @media (max-width: 500px) {
      max-height: 7.143em;
    }

    @media (max-width: 375px) {
      max-height: 5.357em;
    }
  }

  h2 {
    margin: 0;
    padding: 5em 2.857em;
    color: #43454e;
    word-break: break-word;
    z-index: 1;
    // font-size: 1.286em;
    font-size: 1rem;
    font-weight: 400;

    &.empty_long-text {
      margin: 0 auto;
      max-width: 435px;

      @media (max-width: 599px) {
        max-width: 320px;
      }
    }

    button {
      font-size: 14px;
    }
  }

  small {
    margin-top: 1.071rem;
    display: block;
    font-weight: 400;
  }

  a {
    color: #607588;
  }

  button {
    margin-top: 1.786em;
  }
}

/* -- End Empty Contain -- */

/* -- Empty -- */

.empty {
  img {
    max-height: 6.571rem;
    margin: 0 auto;
    margin-bottom: 1.071rem;
    -webkit-filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.32));
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.32));
    pointer-events: none;
  }

  h2 {
    margin: 0;
    padding: 5em 2.857em;
    color: #43454e;
    word-break: break-word;
    z-index: 1;
    // font-size: 1.286em;
    font-size: 1rem;
    font-weight: 400;

    &.empty_long-text {
      margin: 0 auto;
      max-width: 435px;

      @media (max-width: 599px) {
        max-width: 320px;
      }
    }

    small {
      // font-size: 0.7em;
      font-weight: 400;
    }

    &.lean {
      padding: 2.143em 2.857em;
    }

    i {
      // padding-bottom: 1.429em;
      padding-bottom: 0.429em;
    }

    &.pb-15 {
      padding-bottom: 1.071rem;
    }

    button {
      font-size: 14px;
    }
  }

  @media (max-width: 570px) {
    h2 {
      padding: 2.857em 1.786em;
    }
  }

  @media (max-width: 450px) {
    button {
      margin-bottom: 1.786em;
    }
  }
}

/* -- End Empty -- */

/* -- Empty Boxes / Cards -- */

.mat-card .empty {
  width: 100%;
  height: auto;
  // max-height: 25.143em;
  background-color: transparent;
  text-align: center;
}

/* -- End Empty Boxes / Cards -- */

// Credit Cards
/* Jp-card */

.jp-card {
  min-width: 7.143em !important;

  .jp-card-front {
    .jp-card-lower {
      .jp-card-number {
        @media (max-width: 600px) {
          font-size: 1.429em;
          margin-bottom: 1.071rem;
        }
      }
    }
  }
}

.card-wrapper-modal .jp-card {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, .18), 0 4px 15px 0 rgba(0, 0, 0, .15);
}

.card-wrapper {
  .jp-card-container {
    margin: 0;

    @media (max-width: 575px) {
      margin: 0 auto;
    }
  }
}

.card-wrapper .jp-card-container,
.card-wrapper-modal .jp-card-container {
  width: 100%;
  max-width: 26.786em;

  @media (max-width: 600px) {
    height: 14.143em;
    max-width: 24.286em;
  }

  @media (max-width: 365px) {
    height: 11.571rem;
    max-width: 20em;
  }

  .jp-card {
    min-width: 7.143em !important;

    .jp-card-front {
      .jp-card-lower {
        .jp-card-number {
          @media (max-width: 600px) {
            font-size: 1.429em;
            margin-bottom: 2.143em;
          }
        }

        .jp-card-name {
          @media (max-width: 600px) {
            font-size: 1.143em;
          }
        }

        .jp-card-expiry {
          @media (max-width: 600px) {
            font-size: 1.143em;
          }
        }
      }
    }
  }
}

@media (max-width: 1500px) and (min-width: 1200px) {
  .jp-card .jp-card-front .jp-card-lower .jp-card-expiry {
    bottom: -1.071rem;
    position: relative;
  }

  .jp-card .jp-card-front .jp-card-lower .jp-card-number {
    margin-bottom: 1.429em !important;
    white-space: normal;
    word-wrap: break-word;
    font-size: 1.429em !important;
  }
}

.jp-card:hover,
.jp-card:active,
.jp-card:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, .18), 0 4px 15px 0 rgba(0, 0, 0, .15);
}

.interaction {
  position: absolute;
  left: 5%;
  top: 3%;
}

.interaction i {
  display: inline-block;
  padding: 0.357em;
  font-size: 2em;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.6);
}

.interaction i:hover,
.interaction i:focus,
.interaction i:active {
  color: rgba(255, 255, 255, 1);
}

.jp-card-bank::before {
  font-family: "Material Icons";
  content: "\E84F";
  position: absolute;
  right: 5%;
  top: 8%;
  font-size: 3.429em;
  color: rgba(255, 255, 255, 0.6);
  z-index: 1;
}

.jp-card-logo.jp-card-jcb {
  font-family: Roboto, Arial, sans-serif;
  height: 2.857em;
  width: 7.143em;
  font-style: normal;
  color: #fff;
  letter-spacing: 0.071rem;
}

.jp-card-jcb::before {
  content: "JCB";
  position: absolute;
  right: 5%;
  font-size: 2em;
  top: 8%;
  font-weight: 600;
}

.jp-card-blank::before {
  font-family: "Material Icons";
  content: "\E870";
  position: absolute;
  right: 5%;
  top: 8%;
  font-size: 3.429em;
}

.jp-card-logo.jp-card-dinersclub::before {
  content: '';
}

.jp-card-bank-name {
  position: absolute;
  top: -2.286em;
  right: -1rem;
  text-align: right;
  padding: 0 0.357em;
  font-size: 1.214em;
  color: white;
  font-weight: normal;

  @media (max-width: 500px) {
    top: -1.786em;
  }
}

// Hamburger
// Settings
// ==================================================
$hamburger-padding-x: 0.357em !default;
$hamburger-padding-y: 0.357em !default;
$hamburger-layer-width: 2.143em !default;
$hamburger-layer-height: 0.143em !default;
$hamburger-layer-spacing: 0.429em !default;
$hamburger-layer-color: #fff !default;
$hamburger-layer-border-radius: 4px !default;
$hamburger-hover-opacity: 1 !default;
$hamburger-hover-transition-duration: 0.15s !default;
$hamburger-hover-transition-timing-function: linear !default; // To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false !default;
$hamburger-hover-filter: opacity(50%) !default; // Types (Remove or comment out what you don’t need)
// ==================================================
$hamburger-types: (squeeze) !default; // Base Hamburger (We need this)

// ==================================================
// Hamburger types
// ==================================================
// ==================================================
// Cooking up additional types:
//
// The Sass for each hamburger type should be nested
// inside an @if directive to check whether or not
// it exists in $hamburger-types so only the CSS for
// included types are generated.
//
// e.g. hamburgers/types/_new-type.scss
//
// @if index($hamburger-types, new-type) {
//   .hamburger--new-type {
//     ...
//   }
// }
// Hamburger
// ==================================================
.hamburger {
  padding: $hamburger-padding-y $hamburger-padding-x;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, -webkit-filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  transition-duration: $hamburger-hover-transition-duration;
  transition-timing-function: $hamburger-hover-transition-timing-function; // Normalize (<button>)
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;

  &:hover {
    @if $hamburger-hover-use-filter==true {
      -webkit-filter: $hamburger-hover-filter;
      filter: $hamburger-hover-filter;
    }

    @else {
      opacity: $hamburger-hover-opacity;
    }
  }
}

.hamburger-box {
  width: $hamburger-layer-width;
  height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
  display: inline-block;
  position: relative;
  top: -0.414em;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: $hamburger-layer-height;

  &,
  &::before,
  &::after {
    width: $hamburger-layer-width;
    height: $hamburger-layer-height;
    background-color: $hamburger-layer-color;
    border-radius: $hamburger-layer-border-radius;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  &::before,
  &::after {
    content: "";
    display: block;
  }

  &::before {
    top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }

  &::after {
    bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }
}

.hamburger-dark {
  .hamburger-inner {

    &,
    &::before,
    &::after {
      background-color: #ffffff;
    }
  }
}

/*!
  * Hamburgers
  * @description Tasty CSS-animated hamburgers
  * @author Jonathan Suh @jonsuh
  * @site https://jonsuh.com/hamburgers
  * @link https://github.com/jonsuh/hamburgers
  */

@if index($hamburger-types,
  squeeze) {

  /*
  * Squeeze
  */
  .hamburger--squeeze {
    .hamburger-inner {
      transition-duration: 0.075s;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

      &::before {
        transition: top 0.075s 0.12s ease, opacity 0.075s ease;
      }

      &::after {
        transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
    }
  }
}

.sidebar-mobile-open {
  .hamburger-inner:not(.hamburger_team-menu) {
    transform: rotate(45deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

    &::before {
      top: 0;
      opacity: 0;
      transition: top 0.075s ease, opacity 0.075s 0.12s ease;
    }

    &::after {
      bottom: 0;
      transform: rotate(-90deg);
      transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  }
}

// MortgagePartner Print
.print-only {
  display: none !important;
}

@media print {
  .print-only {
    display: block !important;
  }

  .no-print {
    display: none !important;
  }

  .page-fab,
  .mat-button,
  .mat-raised-button,
  .help-button,
  .breadcrumb {
    display: none !important;
  }

  a,
  a:not([href]):not([tabindex]) {
    color: #000;
  }

  .mat-card {
    box-shadow: none !important;

    .cell-detail {
      p {
        color: #000;
        font-weight: 500;
      }
    }
  }
}

//  scroll-container

.scroll-container {

  overflow-y: auto;
  max-height: 25em;
  padding: 0 0.357em 0 0;
  overflow-x: hidden;
  touch-action: manipulation;


  @media (max-height: 812) {
    max-height: 21.429em;
  }

  @media (max-height: 500) {
    max-height: 17.5em;
  }
}

// Highcharts

highcharts-chart {
  * {
    font-family: Roboto, Arial, sans-serif;
  }
}

.highcharts-background {
  fill: transparent !important;
}

.highcharts-title {
  font-size: 16px;
  font-weight: 400;
  font-family: Roboto, Arial, sans-serif;

  tspan {
    font-size: 16px;
  }
}

.highcharts-credits {
  display: none !important;
}

.highcharts-label {
  text {
    fill: #43454e !important;
    color: #43454e !important;

    tspan {
      stroke-width: 0 !important;
      font-weight: 400 !important;
    }
  }
}

.dashboard-chart .highcharts-legend-item.highcharts-series-1 {
  rect {
    color: rgb(73, 147, 73) !important;
    fill: rgb(73, 147, 73) !important;
  }
}

// New UI

.text-thin {
  font-weight: 300;
}

// Status texts

// .mat-card-title span,
// .status-text,
// .mat-cell {
.New {
  color: rgba(0, 0, 0, 0.64) !important;
}

.Rejected,
.Failed {
  color: #B5504A !important;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span,
  small,
  mat-icon,
  mat-cell,
  mat-header-cell,
  mat-footer-cell {
    color: #B5504A !important;
  }

  button,
  a {
    .mat-button-wrapper {
      color: inherit !important;
    }
  }

}

.Disabled,
.OnHold,
.Canceled {
  color: rgba(0, 0, 0, 0.44) !important;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span,
  small,
  mat-icon,
  mat-cell,
  mat-header-cell,
  mat-footer-cell {
    color: rgba(0, 0, 0, 0.52) !important;
  }
}

.Completed {
  // color: #228264 !important;
  color: #228264 !important;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span,
  small,
  mat-icon,
  mat-cell,
  mat-header-cell,
  mat-footer-cell {
    // color: #228264 !important;
    color: #228264 !important;
  }
}

// }


.more-button {
  position: absolute !important;
  // top: 0.357em;
  top: 0.571rem;
  right: 0.714em;
  z-index: 1;

  &.second {
    right: 3.571rem;
  }

  &.third {
    right: 6.429em;
  }

  &.fourth {
    right: 9.286em;
  }

  &.fifth {
    right: 12.143em;
  }

  &.sixth {
    right: 15em;
  }
}


// Used to shine under icons (subscription account icons)
.mat-menu-panel .mat-menu-item .mat-icon-no-color.shine,
.shine {
  cursor: default;

  &.mat-gold,
  &.gold {
    text-decoration: none;
    transition: all .5s;
    color: #f4b400;
    -webkit-animation: 1s ease-in-out infinite alternate goldShine;
    animation: 1s ease-in-out infinite alternate goldShine;
  }

  &.mat-purple,
  &.purple {
    text-decoration: none;
    transition: all .5s;
    color: #5456a2;
    -webkit-animation: 1s ease-in-out infinite alternate purpleShine;
    animation: 1s ease-in-out infinite alternate purpleShine;
  }

  &.shine_limit-animation {
    -webkit-animation-iteration-count: 3;
    animation-iteration-count: 3;
  }
}

@-webkit-keyframes goldShine {
  0% {
    text-shadow: 0 0 3.334px #fff, 0 0 6.667px #fff, 0 0 10px #fff, 0 0 6.667px rgba(244, 180, 0, .45), 0 0 11.667px rgba(244, 180, 0, .45), 0 0 13.3335px rgba(244, 180, 0, .45), 0 0 16.667px rgba(244, 180, 0, .45), 0 0 20px rgba(244, 180, 0, .45);
  }

  100% {
    text-shadow: 0 0 1.667px #fff, 0 0 3.334px #fff, 0 0 5px #fff, 0 0 3.334px rgba(244, 180, 0, .45), 0 0 5.8335px rgba(244, 180, 0, .45), 0 0 6.667px rgba(244, 180, 0, .45), 0 0 8.334px rgba(244, 180, 0, .45), 0 0 12.5px rgba(244, 180, 0, .45);
  }
}

@keyframes goldShine {
  0% {
    text-shadow: 0 0 3.334px #fff, 0 0 6.667px #fff, 0 0 10px #fff, 0 0 6.667px rgba(244, 180, 0, .45), 0 0 11.667px rgba(244, 180, 0, .45), 0 0 13.3335px rgba(244, 180, 0, .45), 0 0 16.667px rgba(244, 180, 0, .45), 0 0 20px rgba(244, 180, 0, .45);
  }

  100% {
    text-shadow: 0 0 1.667px #fff, 0 0 3.334px #fff, 0 0 5px #fff, 0 0 3.334px rgba(244, 180, 0, .45), 0 0 5.8335px rgba(244, 180, 0, .45), 0 0 6.667px rgba(244, 180, 0, .45), 0 0 8.334px rgba(244, 180, 0, .45), 0 0 12.5px rgba(244, 180, 0, .45);
  }
}


@-webkit-keyframes purpleShine {
  0% {
    text-shadow: 0 0 3.334px #fff, 0 0 6.667px #fff, 0 0 10px #fff, 0 0 6.667px rgba(100, 65, 164, .45), 0 0 11.667px rgba(100, 65, 164, .45), 0 0 13.3335px rgba(100, 65, 164, .45), 0 0 16.667px rgba(100, 65, 164, .45), 0 0 20px rgba(100, 65, 164, .45);
  }

  100% {
    text-shadow: 0 0 1.667px #fff, 0 0 3.334px #fff, 0 0 5px #fff, 0 0 3.334px rgba(100, 65, 164, .45), 0 0 5.8335px rgba(100, 65, 164, .45), 0 0 6.667px rgba(100, 65, 164, .45), 0 0 8.334px rgba(100, 65, 164, .45), 0 0 12.5px rgba(100, 65, 164, .45);
  }
}

@keyframes purpleShine {
  0% {
    text-shadow: 0 0 3.334px #fff, 0 0 6.667px #fff, 0 0 10px #fff, 0 0 6.667px rgba(100, 65, 164, .45), 0 0 11.667px rgba(100, 65, 164, .45), 0 0 13.3335px rgba(100, 65, 164, .45), 0 0 16.667px rgba(100, 65, 164, .45), 0 0 20px rgba(100, 65, 164, .45);
  }

  100% {
    text-shadow: 0 0 1.667px #fff, 0 0 3.334px #fff, 0 0 5px #fff, 0 0 3.334px rgba(100, 65, 164, .45), 0 0 5.8335px rgba(100, 65, 164, .45), 0 0 6.667px rgba(100, 65, 164, .45), 0 0 8.334px rgba(100, 65, 164, .45), 0 0 12.5px rgba(100, 65, 164, .45);
  }
}


// Table of Contents


.tableOfContentContainer {
  padding-top: 1rem;
  padding-left: 2em;
  margin-top: 4px;
  position: relative;
  width: 100%;

  @media screen and (max-width: 959px) {
    position: relative;
    padding-top: 0;
    padding-left: 0;
    margin-bottom: 1.071rem;
  }

  .docs-toc-container {
    // border-left: 0.286em solid #92344f;
    font-weight: 700;
    // padding: 0.357em 0 0.714em 0.714em;
    font-size: 0.9286em;
    border-radius: 8px;

    .docs-toc-heading {
      // border-bottom: 0.071rem solid rgba(0, 0, 0, .12);
      padding: 1rem;
      font-weight: 500;
    }

    button:not(.mat-button-base),
    a:not(.mat-button-base) {
      text-align: left;
      text-decoration: none;
      color: #43454e;
      font-weight: 400;
      padding: 0.5em 0.714em;
      display: block;

      // &:first-of-type {
      //   margin-top: 0.714em;
      // }

      &:hover,
      &:focus {
        color: #43454e;
        font-weight: 500;
      }

      &.toc-disabled {
        cursor: not-allowed;
        color: rgba(0, 0, 0, .36);
      }
    }

  }
}


// shows which cards we accept.
.ccbrands {
  list-style: none;
  padding: 0;
  margin: 0;
  display: block;
  background: transparent;

  li {
    height: 1.786em;
    width: 3.214em;
    margin: 1.071rem 0.357em 0 0;
    display: inline-block;
    overflow: hidden;
    text-indent: -35.714em;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;

    &:last-child {
      margin-right: 0
    }

    &.ccbrands_icon {
      text-indent: 0;
    }
  }

  .visa {
    margin: 1.071rem 0.714em 0 0;
    background-image: url(https://MortgagePartner.com/images/VISA.svg);
  }

  .masterCard {
    background-image: url(https://MortgagePartner.com/images/MasterCard.svg);
  }

  .amex {
    background-image: url(https://MortgagePartner.com/images/amex.svg);
  }

  .discover {
    background-image: url(https://MortgagePartner.com/images/Discover.svg);
  }

  .jcb {
    background-image: url(https://MortgagePartner.com/images/jcb.svg);
  }

  i {
    color: #AAB7C4;
  }
}

.vert-button_break {
  height: 1.429em;
  width: 0.357em;
  margin-right: 0.214em;
  margin-left: 0.214em;
  margin-bottom: 0.357em;
  border-left: 0.071rem solid rgba(0, 0, 0, .36);
}

// Animate clicks
:not(.cdk-focused) {
  mat-icon.animate-click {
    -webkit-animation: none;
    animation: none;
    transform: scale(1);
  }
}

.cdk-focused {
  mat-icon.animate-click {
    font-family: "Material Icons";
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation: 300ms ease-in-out animateClick;
    animation: 300ms ease-in-out animateClick;
    transform: scale(1);
  }
}


@-webkit-keyframes animateClick {
  0% {
    transform: scale(.75);
  }

  100% {
    font-family: "Material Icons";
    transform: scale(1);
  }
}


@keyframes animateClick {
  0% {
    transform: scale(.75);
  }

  100% {
    font-family: "Material Icons";
    transform: scale(1);
  }
}

// User profile


.user-profile {
  background: #dfe1e9;
  font-family: Roboto, Arial, sans-serif;
  margin-right: 0.625rem;
  color: #43454e;
  padding: 2px;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  &.sm {
    display: inline-flex;
    font-size: 0.70rem;
    height: 1.625rem;
    width: 1.625rem;
  }

  &.lg {
    display: flex;
    font-size: 2.5rem;
    height: 5.625rem;
    width: 5.625rem;

    @media screen and (max-width: 599px) {
      position: relative;
      top: 3px;
      font-size: 1.8rem;
      height: 3.625rem;
      width: 3.625rem;
    }
  }

  &.user-profile_light {
    background: #fff;
    color: #43454e;
  }

  &.profile-Img {
    display: inline-flex;
    padding: 2px;
    background: #fff;
    height: 28px;
    width: 28px;
  }
}

// Person Header
.personHeaderContainer {
  position: relative;
  background: #e8eaef;
  background: #ffffff;
  padding: 0.938rem;
  overflow: hidden;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);

  .personName {

    @media screen and (max-width: 599px) {
      font-size: 2em;
    }
  }

  .personType {
    position: absolute;
    right: 0;
    bottom: -2.8vw;
    font-size: 6vw;
    font-weight: 600;
    color: darken(#e8eaef, 7%);

    @media screen and (max-width: 1024px) {
      font-size: 8vw;
      bottom: -3.8vw;
    }

    @media screen and (max-width: 676px) {
      display: none;
    }

  }
}


// Mortgage Partner


.btn-social {

  display: inline-flex;

  .mat-icon-button-social {
    height: 18px;
    color: $text-color;
    position: absolute;
    top: 11px;
    left: 11px;
  }
}

.light-bg {
  background-color: #f5f7fa;
}

// Hero
.hero {
  position: relative;
  width: 100%;
  padding: 150px 0;
  min-height: 35vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0% 60%;

  @media screen and (max-width: 991px) {
    padding: 80px 0;
  }

  @media screen and (max-width: 599px) {
    padding: 40px 0;
    background-position: 50% 50%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: $white;
  }

  h1 {
    font-weight: 600;
  }

  h3 {
    font-weight: 400;
  }

  .hero-text-backdrop {
    padding: 50px 15px;
  }

}




.overlay-container {
  position: relative;
}

.overlay {
  background-color: rgba(255, 255, 255, .75);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: none;

  &.overlay_has-back-btn {
    padding: 0em 2.143em;
  }

  &.white-90 {
    background-color: rgba(255, 255, 255, .90);

    h2 {
      text-align: center;
    }
  }

  &.white-65 {
    background-color: rgba(255, 255, 255, .65);

    h2 {
      text-align: center;
    }
  }

  &.white-95 {
    background-color: rgba(255, 255, 255, .95);

    h2 {
      text-align: center;
    }
  }

  &.dark {
    background-color: rgba(0, 0, 0, .40);
  }

  &.show {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    z-index: 501;

    h2,
    h3,
    h4 {
      color: #43454e;
      font-weight: 500;
      font-size: 1.071em;

      &.header-text {
        font-size: 1.357em;
      }
    }

    .overlay-icon {
      border-radius: 50%;
      border: 0.286em solid gray;
      -webkit-box-sizing: content-box;
      box-sizing: content-box;
      height: 5.714em;
      margin: 1.429em auto;
      padding: 0;
      position: relative;
      width: 5.714em; // Success icon

      &.overlay-success,
      &.overlay-error {
        border-color: #A5DC86;

        &:after,
        &:before {
          background: #fff;
          content: '';
          height: 8.571em;
          position: absolute;
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
          width: 4.286em;
        }

        &:before {
          border-radius: 8.571em 0 0 8.571em;
          left: -2.357em;
          top: -0.5em;
          -webkit-transform-origin: 4.286em 4.286em;
          transform-origin: 4.286em 4.286em;
          -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }

        &:after {
          border-radius: 0 8.571em 8.571em 0;
          left: 2.143em;
          top: -0.786em;
          -webkit-transform-origin: 0 4.286em;
          transform-origin: 0 4.286em;
          -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }

        .overlay-placeholder {
          border-radius: 50%;
          border: 0.286em solid rgba(165, 220, 134, .2);
          -webkit-box-sizing: content-box;
          box-sizing: content-box;
          height: 5.714em;
          left: -0.286em;
          position: absolute;
          top: -0.286em;
          width: 5.714em;
          z-index: 2;
        }

        .overlay-fix {
          background: #fff;
          height: 6.429em;
          left: 2em;
          position: absolute;
          top: 0.571em;
          -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
          width: 0.357em;
          z-index: 1;
        }

        .overlay-line {
          background-color: #A5DC86;
          border-radius: 0.143em;
          display: block;
          height: 0.357em;
          position: absolute;
          z-index: 2;

          &.overlay-tip {
            left: 1em;
            top: 3.286em;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            width: 1.786em;
          }

          &.overlay-long {
            right: 0.571em;
            top: 2.714em;
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
            width: 3.357em;
          }
        }
      }

      // Error icon
      &.overlay-error {
        border-color: #F27474;

        .overlay-placeholder {
          border: 0.286em solid rgba(200, 0, 0, .2);
        }

        .overlay-line {
          background-color: #F27474;
          top: 2.643em;
          width: 3.357em;

          &.overlay-left {
            left: 1.214em;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
          }

          &.overlay-right {
            right: 1.143em;
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
          }
        }
      }

      // Warning icon
      &.overlay-warning {
        border-color: #F8BB86;

        &:before {
          -webkit-animation: pulseWarning 2s linear infinite;
          animation: pulseWarning 2s linear infinite;
          background-color: #fff;
          border-radius: 50%;
          content: "";
          display: inline-block;
          height: 100%;
          opacity: 0;
          position: absolute;
          width: 100%;
        }

        &:after {
          background: transparent;
          border-radius: 50%;
          content: '';
          display: block;
          height: 100%;
          position: absolute;
          width: 100%;
          z-index: 1;
        }
      }

      &.overlay-warning .overlay-body {
        background-color: #F8BB86;
        border-radius: 0.143em;
        height: 3.357em;
        left: 50%;
        margin-left: -0.143em;
        position: absolute;
        top: 0.714em;
        width: 0.357em;
        z-index: 2;
      }

      &.overlay-warning .overlay-dot {
        background-color: #F8BB86;
        border-radius: 50%;
        bottom: 0.714em;
        height: 0.5em;
        left: 50%;
        margin-left: -0.214em;
        position: absolute;
        width: 0.5em;
        z-index: 2;
      }

      +.overlay-icon {
        margin-top: 3.571em;
      }

      .animateSuccessTip {
        -webkit-animation: animateSuccessTip .75s;
        animation: animateSuccessTip .75s;
      }

      .animateSuccessLong {
        -webkit-animation: animateSuccessLong .75s;
        animation: animateSuccessLong .75s;
      }

      &.overlay-success.animate:after {
        -webkit-animation: rotatePlaceholder 4.25s ease-in;
        animation: rotatePlaceholder 4.25s ease-in;
      }

      &.overlay-error:after {
        -webkit-animation: rotatePlaceholder 4.25s ease-in;
        animation: rotatePlaceholder 4.25s ease-in;
      }

      .animateErrorIcon {
        -webkit-animation: animateErrorIcon .5s;
        animation: animateErrorIcon .5s;
      }

      .animateXLeft {
        -webkit-animation: animateXLeft .75s;
        animation: animateXLeft .75s;
      }

      .animateXRight {
        -webkit-animation: animateXRight .75s;
        animation: animateXRight .75s;
      }

      .scaleWarning {
        -webkit-animation: scaleWarning 0.75s infinite alternate;
        animation: scaleWarning 0.75s infinite alternate;
      }

      .pulseWarningIns {
        -webkit-animation: pulseWarningIns 0.75s infinite alternate;
        animation: pulseWarningIns 0.75s infinite alternate;
      }

      @-webkit-keyframes animateSuccessTip {

        0%,
        54% {
          width: 0;
          left: 0.071em;
          top: 1.357em;
        }

        70% {
          width: 3.571em;
          left: -0.571em;
          top: 2.643em;
        }

        84% {
          width: 1.214em;
          left: 1.5em;
          top: 3.429em;
        }

        100% {
          width: 1.786em;
          left: 1em;
          top: 3.214em;
        }
      }

      @keyframes animateSuccessTip {

        0%,
        54% {
          width: 0;
          left: 0.071em;
          top: 1.357em;
        }

        70% {
          width: 3.571em;
          left: -0.571em;
          top: 2.643em;
        }

        84% {
          width: 1.214em;
          left: 1.5em;
          top: 3.429em;
        }

        100% {
          width: 1.786em;
          left: 1em;
          top: 3.214em;
        }
      }

      @-webkit-keyframes animateSuccessLong {

        0%,
        65% {
          width: 0;
          right: 3.286em;
          top: 3.857em;
        }

        84% {
          width: 3.929em;
          right: 0;
          top: 2.5em;
        }

        100% {
          width: 3.357em;
          right: 0.571em;
          top: 2.714em;
        }
      }

      @keyframes animateSuccessLong {

        0%,
        65% {
          width: 0;
          right: 3.286em;
          top: 3.857em;
        }

        84% {
          width: 3.929em;
          right: 0;
          top: 2.5em;
        }

        100% {
          width: 3.357em;
          right: 0.571em;
          top: 2.714em;
        }
      }

      @-webkit-keyframes rotatePlaceholder {

        0%,
        5% {
          -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }

        100%,
        12% {
          -webkit-transform: rotate(-405deg);
          transform: rotate(-405deg);
        }
      }

      @keyframes rotatePlaceholder {

        0%,
        5% {
          -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }

        100%,
        12% {
          -webkit-transform: rotate(-405deg);
          transform: rotate(-405deg);
        }
      }

      @-webkit-keyframes animateErrorIcon {
        0% {
          -webkit-transform: rotateX(100deg);
          transform: rotateX(100deg);
          opacity: 0;
        }

        100% {
          -webkit-transform: rotateX(0deg);
          transform: rotateX(0deg);
          opacity: 1;
        }
      }

      @keyframes animateErrorIcon {
        0% {
          -webkit-transform: rotateX(100deg);
          transform: rotateX(100deg);
          opacity: 0;
        }

        100% {
          -webkit-transform: rotateX(0deg);
          transform: rotateX(0deg);
          opacity: 1;
        }
      }

      @-webkit-keyframes animateXLeft {

        0%,
        65% {
          left: 5.857em;
          top: 6.786em;
          width: 0;
        }

        84% {
          left: 1em;
          top: 2.357em;
          width: 3.357em;
        }

        100% {
          left: 1.214em;
          top: 2.643em;
          width: 3.357em;
        }
      }

      @keyframes animateXLeft {

        0%,
        65% {
          left: 5.857em;
          top: 6.786em;
          width: 0;
        }

        84% {
          left: 1em;
          top: 2.357em;
          width: 3.357em;
        }

        100% {
          left: 1.214em;
          top: 2.643em;
          width: 3.357em;
        }
      }

      @-webkit-keyframes animateXRight {

        0%,
        65% {
          right: 5.857em;
          top: 6.786em;
          width: 0;
        }

        84% {
          right: 1em;
          top: 2.357em;
          width: 3.357em;
        }

        100% {
          right: 1.143em;
          top: 2.643em;
          width: 3.357em;
        }
      }

      @keyframes animateXRight {

        0%,
        65% {
          right: 5.857em;
          top: 6.786em;
          width: 0;
        }

        84% {
          right: 1em;
          top: 2.357em;
          width: 3.357em;
        }

        100% {
          right: 1.143em;
          top: 2.643em;
          width: 3.357em;
        }
      }

      @-webkit-keyframes scaleWarning {
        0% {
          -webkit-transform: scale(1);
          transform: scale(1);
        }

        30% {
          -webkit-transform: scale(1.02);
          transform: scale(1.02);
        }

        100% {
          -webkit-transform: scale(1);
          transform: scale(1);
        }
      }

      @keyframes scaleWarning {
        0% {
          -webkit-transform: scale(1);
          transform: scale(1);
        }

        30% {
          -webkit-transform: scale(1.02);
          transform: scale(1.02);
        }

        100% {
          -webkit-transform: scale(1);
          transform: scale(1);
        }
      }

      @-webkit-keyframes pulseWarning {
        0% {
          background-color: #fff;
          -webkit-transform: scale(1);
          transform: scale(1);
          opacity: 0.5;
        }

        30% {
          background-color: #fff;
          -webkit-transform: scale(1);
          transform: scale(1);
          opacity: 0.5;
        }

        100% {
          background-color: #F8BB86;
          -webkit-transform: scale(2);
          transform: scale(2);
          opacity: 0;
        }
      }

      @keyframes pulseWarning {
        0% {
          background-color: #fff;
          -webkit-transform: scale(1);
          transform: scale(1);
          opacity: 0.5;
        }

        30% {
          background-color: #fff;
          -webkit-transform: scale(1);
          transform: scale(1);
          opacity: 0.5;
        }

        100% {
          background-color: #F8BB86;
          -webkit-transform: scale(2);
          transform: scale(2);
          opacity: 0;
        }
      }

      @-webkit-keyframes pulseWarningIns {
        0% {
          background-color: #F8D486;
        }

        100% {
          background-color: #F8BB86;
        }
      }

      @keyframes pulseWarningIns {
        0% {
          background-color: #F8D486;
        }

        100% {
          background-color: #F8BB86;
        }
      }
    }
  }

  &.overlay-coming-soon {
    * {
      color: lighten(#43454e, 20%);
    }

    h2 {
      font-size: 1.714em;
      color: lighten(#43454e, 20%);
    }
  }
}

// Markdown

.markdown {

  h2:first-of-type,
  h4:first-of-type {
    font-weight: 400;
    text-align: center;
    margin-bottom: 10px;
    text-transform: inherit;
  }

  dl,
  ol,
  ul {
    margin-top: 1rem;

    li {
      font-size: 0.938rem;
      list-style-type: disc;
      margin-bottom: 15px;
    }
  }
}

// Articles

.article-body-image {
  border-radius: 8px;
  max-height: 525px;
  margin: 0 auto;
}
