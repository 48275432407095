$image_path: "assets/images" !default;

// Colors
$white: #fff !default;
$text-color: #43454e !default;
$dark-white: #d3d3d3 !default;
$Elite-primary_dark: #5456a2 !default;
$dark_bg: #444d54 !default;
$dark_bg_100: #444d54 !default;
$dark_bg_even: rgba(63, 71, 77, .85) !default;
$Completed: #447e49 !default;
$PastDue: rgba(243, 112, 50, .70) !default;
$Rejected: #dfb2b0 !default;
$Canceled: rgba(0, 0, 0, .16) !default;
$Elite-Purple-85: darken(#606188, 5%) !default;
$Elite-Purple: darken(#606188, 5%) !default;
$Elite-Purple-85: darken(#606188, 5%) !default;
$Elite-Accent-Background: darken(#606188, 5%) !default;
$Elite-Accent-Color: darken(#606188, 5%) !default;
$Elite-Accent-Light: #8d8eb5 !default;
$Elite-Blue: #607588 !default;
$Elite-Blue-85: rgba(96, 117, 136, .85) !default;
$Elite-Purple: #464775 !default;
$Elite-Purple-85: #606188 !default;
$Elite-Main-Background: #607588 !default;
$Elite-Main-Color: #607588 !default;
$Elite-Accent-Background: #606188 !default;
$Elite-Accent-Color: #606188 !default;
$Elite-Default-Text: #43454e !default;
$Elite-Danger: #BF2D29 !default;
$dark-muted: #afbfce !default;
$dark: #343a40 !default; // $gray-dark
$dark-60: rgba (52, 58, 64, .60) !default; //
$dark-75: rgba(52, 58, 64, .75) !default; //
$dark-90: rgba(52, 58, 64, .90) !default; //

/* SCSS HEX */
$mp-light-red: #92344f;
$mp-red: #490014ff;
$royal-purple: #864fa5ff;
$heliotrope-gray: #af9eabff;
$platinum: #eaeaeaff;
$palatinate-purple: #5b1452ff;

/* SCSS HSL */
$mp-red: hsla(344, 100%, 14%, 1);
$royal-purple: hsla(278, 35%, 48%, 1);
$heliotrope-gray: hsla(314, 10%, 65%, 1);
$platinum: hsla(0, 0%, 92%, 1);
$palatinate-purple: hsla(308, 64%, 22%, 1);

/* SCSS RGB */
$mp-red: rgba(73, 0, 20, 1);
$royal-purple: rgba(134, 79, 165, 1);
$heliotrope-gray: rgba(175, 158, 171, 1);
$platinum: rgba(234, 234, 234, 1);
$palatinate-purple: rgba(91, 20, 82, 1);

/* SCSS Gradient */
$gradient-top: linear-gradient(0deg, #490014ff, #864fa5ff, #af9eabff, #eaeaeaff, #5b1452ff);
$gradient-right: linear-gradient(90deg, #490014ff, #864fa5ff, #af9eabff, #eaeaeaff, #5b1452ff);
$gradient-bottom: linear-gradient(180deg, #490014ff, #864fa5ff, #af9eabff, #eaeaeaff, #5b1452ff);
$gradient-left: linear-gradient(270deg, #490014ff, #864fa5ff, #af9eabff, #eaeaeaff, #5b1452ff);
$gradient-top-right: linear-gradient(45deg, #490014ff, #864fa5ff, #af9eabff, #eaeaeaff, #5b1452ff);
$gradient-bottom-right: linear-gradient(135deg, #490014ff, #864fa5ff, #af9eabff, #eaeaeaff, #5b1452ff);
$gradient-top-left: linear-gradient(225deg, #490014ff, #864fa5ff, #af9eabff, #eaeaeaff, #5b1452ff);
$gradient-bottom-left: linear-gradient(315deg, #490014ff, #864fa5ff, #af9eabff, #eaeaeaff, #5b1452ff);
$gradient-radial: radial-gradient(#490014ff, #864fa5ff, #af9eabff, #eaeaeaff, #5b1452ff);
