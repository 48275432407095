@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
/* SCSS HEX */
/* SCSS HSL */
/* SCSS RGB */
/* SCSS Gradient */
.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Muli, Roboto, "Helvetica Neue", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1, .mat-headline, .mat-typography h1 {
  font: 400 24px / 32px Muli, Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2, .mat-title, .mat-typography h2 {
  font: 500 20px / 32px Muli, Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3, .mat-subheading-2, .mat-typography h3 {
  font: 400 15px / 28px Muli, Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4, .mat-subheading-1, .mat-typography h4 {
  font: 400 15px / 24px Muli, Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5, .mat-typography h5 {
  font: 400 calc(15px * 0.83) / 20px Muli, Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-h6, .mat-typography h6 {
  font: 400 calc(15px * 0.67) / 20px Muli, Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong, .mat-body-2 {
  font: 500 15px / 24px Muli, Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-body, .mat-body-1, .mat-typography {
  font: 400 15px / 20px Muli, Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body p, .mat-body-1 p, .mat-typography p {
  margin: 0 0 12px;
}

.mat-small, .mat-caption {
  font: 400 15px / 20px Muli, Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-display-4, .mat-typography .mat-display-4 {
  font: 300 112px / 112px Muli, Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 56px;
}

.mat-display-3, .mat-typography .mat-display-3 {
  font: 400 56px / 56px Muli, Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-display-2, .mat-typography .mat-display-2 {
  font: 400 45px / 48px Muli, Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-display-1, .mat-typography .mat-display-1 {
  font: 400 34px / 40px Muli, Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 15px / 20px Muli, Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Muli, Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: Muli, Roboto, "Helvetica Neue", sans-serif;
}

.mat-card {
  font-family: Muli, Roboto, "Helvetica Neue", sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 15px;
}

.mat-checkbox {
  font-family: Muli, Roboto, "Helvetica Neue", sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 15px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: Muli, Roboto, "Helvetica Neue", sans-serif;
}

.mat-header-cell {
  font-size: 15px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 15px;
}

.mat-calendar {
  font-family: Muli, Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 16px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px / 32px Muli, Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: Muli, Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 15px / 20px Muli, Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field {
  font: 400 16px / 1.25 Muli, Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.4375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.25;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.25em;
  line-height: 1.25;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.9375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.4375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.43749em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.9375em;
  padding-top: 0.9375em;
}

.mat-form-field-label {
  top: 1.4375em;
}

.mat-form-field-underline {
  bottom: 1.4375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.9166666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.3125em) scale(0.75) perspective(100px) translateZ(0.001px);
  -ms-transform: translateY(-1.3125em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.3125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  -ms-transform: translateY(-1.31249em) scale(0.75);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.3125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  -ms-transform: translateY(-1.31248em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.3125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.4166666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.31247em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.31246em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.31245em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.1875em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.6875em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.68749em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.9375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.6875em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.68749em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 15px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 15px;
}

input.mat-input-element {
  margin-top: -0.125em;
}

.mat-menu-item {
  font-family: Muli, Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Muli, Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
}

.mat-radio-button {
  font-family: Muli, Roboto, "Helvetica Neue", sans-serif;
}

.mat-select {
  font-family: Muli, Roboto, "Helvetica Neue", sans-serif;
}

.mat-select-trigger {
  height: 1.25em;
}

.mat-slide-toggle-content {
  font-family: Muli, Roboto, "Helvetica Neue", sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: Muli, Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Muli, Roboto, "Helvetica Neue", sans-serif;
}

.mat-step-label {
  font-size: 15px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 15px;
}

.mat-step-label-selected {
  font-size: 15px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: Muli, Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: Muli, Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px / 32px Muli, Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: Muli, Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: Muli, Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-option {
  font-family: Muli, Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 15px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 15px;
}
.mat-list-base .mat-list-option {
  font-size: 15px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 15px;
}
.mat-list-base .mat-subheader {
  font-family: Muli, Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 15px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 15px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 15px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 15px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Muli, Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 500;
}

.mat-option {
  font-family: Muli, Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
}

.mat-optgroup-label {
  font: 500 15px / 24px Muli, Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: Muli, Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: Muli, Roboto, "Helvetica Neue", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 15px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start {
  /*!*/
}
@keyframes cdk-text-field-autofill-end {
  /*!*/
}
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}

.mat-mdc-focus-indicator {
  position: relative;
}

/*
  .mat-select-hide-underline .mat-form-field-underline {
    display: none;
  }
  */
/* For use in src/lib/core/theming/_palette.scss */
.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #92344f;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #43454e;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #d4504c;
}

.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #92344f;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #43454e;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #d4504c;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}

.mat-badge {
  position: relative;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-badge-content {
  color: white;
  background: #92344f;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #43454e;
  color: white;
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #d4504c;
}

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #92344f;
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: #43454e;
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: #d4504c;
}
.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #92344f;
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #43454e;
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #d4504c;
}
.mat-button.mat-button-disabled .mat-button-focus-overlay, .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: black;
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: white;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: white;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #92344f;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #43454e;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: #d4504c;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone,
.mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-checkmark {
  fill: #fafafa;
}

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}

.mat-checkbox-mixedmark {
  background-color: #fafafa;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #92344f;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #43454e;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #d4504c;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox .mat-ripple-element {
  background-color: black;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #92344f;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #43454e;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #d4504c;
}

.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: black;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #92344f;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #d4504c;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #43454e;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-table {
  background: white;
}

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}

.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-in-range::before {
  background: rgba(146, 52, 79, 0.2);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(146, 52, 79, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(146, 52, 79, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: #92344f;
  color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(146, 52, 79, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(146, 52, 79, 0.3);
}

.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(67, 69, 78, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(67, 69, 78, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(67, 69, 78, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #43454e;
  color: white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(67, 69, 78, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(67, 69, 78, 0.3);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(212, 80, 76, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(212, 80, 76, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(212, 80, 76, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #d4504c;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(212, 80, 76, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(212, 80, 76, 0.3);
}

.mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #92344f;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #43454e;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #d4504c;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-hint {
  color: rgba(0, 0, 0, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #92344f;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #43454e;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #d4504c;
}

.mat-focused .mat-form-field-required-marker {
  color: #43454e;
}

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #92344f;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #43454e;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #d4504c;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #92344f;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #43454e;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #d4504c;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #d4504c;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #d4504c;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #d4504c;
}

.mat-error {
  color: #d4504c;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #92344f;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #43454e;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #d4504c;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #d4504c;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}

.mat-icon.mat-primary {
  color: #92344f;
}
.mat-icon.mat-accent {
  color: #43454e;
}
.mat-icon.mat-warn {
  color: #d4504c;
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-input-element {
  caret-color: #92344f;
}
.mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-form-field.mat-accent .mat-input-element {
  caret-color: #43454e;
}

.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #d4504c;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #d4504c;
}

.mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}

.mat-list-item-disabled {
  background-color: #eeeeee;
}

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}

.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}

.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.mat-menu-item[disabled], .mat-menu-item[disabled]::after,
.mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-item-submenu-trigger::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-paginator {
  background: white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-paginator-container {
  min-height: 56px;
}

.mat-progress-bar-background {
  fill: #e0c9cf;
}

.mat-progress-bar-buffer {
  background-color: #e0c9cf;
}

.mat-progress-bar-fill::after {
  background-color: #92344f;
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #cccdcf;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #cccdcf;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #43454e;
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f1d0cf;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f1d0cf;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #d4504c;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #92344f;
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #43454e;
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #d4504c;
}

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #92344f;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #92344f;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #43454e;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #43454e;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #d4504c;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #d4504c;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button .mat-ripple-element {
  background-color: black;
}

.mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #92344f;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #43454e;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #d4504c;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #d4504c;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #43454e;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(67, 69, 78, 0.54);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #43454e;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #92344f;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(146, 52, 79, 0.54);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #92344f;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #d4504c;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(212, 80, 76, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #d4504c;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-primary .mat-slider-track-fill,
.mat-primary .mat-slider-thumb,
.mat-primary .mat-slider-thumb-label {
  background-color: #92344f;
}
.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.mat-primary .mat-slider-focus-ring {
  background-color: rgba(146, 52, 79, 0.2);
}

.mat-accent .mat-slider-track-fill,
.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label {
  background-color: #43454e;
}
.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.mat-accent .mat-slider-focus-ring {
  background-color: rgba(67, 69, 78, 0.2);
}

.mat-warn .mat-slider-track-fill,
.mat-warn .mat-slider-thumb,
.mat-warn .mat-slider-thumb-label {
  background-color: #d4504c;
}
.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.mat-warn .mat-slider-focus-ring {
  background-color: rgba(212, 80, 76, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-disabled .mat-slider-track-background,
.mat-slider-disabled .mat-slider-track-fill,
.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #92344f;
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #43454e;
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #d4504c;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #d4504c;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: #d4504c;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}

.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-tab-group[class*=mat-background-] .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(222, 194, 202, 0.3);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #92344f;
}
.mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(199, 199, 202, 0.3);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #43454e;
}
.mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(242, 203, 201, 0.3);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #d4504c;
}
.mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(222, 194, 202, 0.3);
}
.mat-tab-group.mat-background-primary > .mat-tab-header, .mat-tab-group.mat-background-primary > .mat-tab-link-container, .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #92344f;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(199, 199, 202, 0.3);
}
.mat-tab-group.mat-background-accent > .mat-tab-header, .mat-tab-group.mat-background-accent > .mat-tab-link-container, .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #43454e;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(242, 203, 201, 0.3);
}
.mat-tab-group.mat-background-warn > .mat-tab-header, .mat-tab-group.mat-background-warn > .mat-tab-link-container, .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #d4504c;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}

.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-primary {
  background: #92344f;
  color: white;
}
.mat-toolbar.mat-accent {
  background: #43454e;
  color: white;
}
.mat-toolbar.mat-warn {
  background: #d4504c;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }

  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
  color: #43454e;
}

/** Colors variables */
/** share button variables */
/** share buttons colors */
/** Colors variables */
/** share button variables */
/** share buttons colors */
.sb-group,
.sb-button {
  display: inline-flex;
  align-items: flex-start;
}

.sb-group {
  flex-wrap: wrap;
}

.sb-button {
  margin: var(--sb-margin);
}

/** Default style */
.sb-wrapper {
  font-size: inherit;
  cursor: pointer;
  position: relative;
  outline: 0;
  min-width: 4.125em;
  height: 2.5em;
  border: none;
  border-radius: 1px;
  padding: 0;
  line-height: 2.571em;
  background-color: transparent;
  -webkit-print-color-adjust: exact;
}
.sb-wrapper .sb-icon,
.sb-wrapper .sb-text {
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}
.sb-wrapper .sb-content {
  flex: 1;
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
}
.sb-wrapper .sb-text {
  flex: 1;
  height: 100%;
  white-space: nowrap;
  padding: 0 0.7em;
}
.sb-wrapper .sb-icon {
  text-align: center;
  width: 100%;
  height: 100%;
  font-size: 1.2em;
  min-width: 2em;
}
.sb-wrapper .sb-text {
  font-weight: bold;
}

.sb-modern-light .sb-wrapper {
  color: var(--button-color) !important;
  background-color: #fff;
  transition: box-shadow ease-in 150ms, transform ease-in 150ms;
  border-radius: 3px;
  box-shadow: 0 0.1em 0.5em rgba(165, 165, 165, 0.6);
}
.sb-modern-light .sb-wrapper.sb-facebook {
  color: #4267B2;
}
.sb-modern-light .sb-wrapper.sb-twitter {
  color: #00acee;
}
.sb-modern-light .sb-wrapper.sb-google {
  color: #db4437;
}
.sb-modern-light .sb-wrapper.sb-mix {
  color: #ff8226;
}
.sb-modern-light .sb-wrapper.sb-line {
  color: #00b900;
}
.sb-modern-light .sb-wrapper.sb-linkedin {
  color: #006fa6;
}
.sb-modern-light .sb-wrapper.sb-pinterest {
  color: #bd081c;
}
.sb-modern-light .sb-wrapper.sb-reddit {
  color: #ff4006;
}
.sb-modern-light .sb-wrapper.sb-tumblr {
  color: #36465d;
}
.sb-modern-light .sb-wrapper.sb-whatsapp {
  color: #25d366;
}
.sb-modern-light .sb-wrapper.sb-messenger {
  color: #0080FF;
}
.sb-modern-light .sb-wrapper.sb-telegram {
  color: #0088cc;
}
.sb-modern-light .sb-wrapper.sb-xing {
  color: #006567;
}
.sb-modern-light .sb-wrapper.sb-sms {
  color: #20c16c;
}
.sb-modern-light .sb-wrapper.sb-email {
  color: #FF961C;
}
.sb-modern-light .sb-wrapper.sb-viber {
  color: #665ca7;
}
.sb-modern-light .sb-wrapper.sb-vk {
  color: #4C75A3;
}
.sb-modern-light .sb-wrapper.sb-copy {
  color: #607D8B;
}
.sb-modern-light .sb-wrapper.sb-print {
  color: #765AA2;
}
.sb-modern-light .sb-wrapper.sb-expand {
  color: #FF6651;
}
.sb-modern-light .sb-wrapper:active {
  box-shadow: 0 0.4em 1em rgba(165, 165, 165, 0.9);
}
.sb-modern-light .sb-wrapper .sb-icon {
  min-width: 2em;
}
.sb-modern-light .sb-wrapper.sb-show-text {
  padding: 0;
}
.sb-modern-light .sb-wrapper.sb-show-text .sb-icon {
  box-shadow: 1px 0 1px -1px rgba(0, 0, 0, 0.3);
}

*, ::after, ::before {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[data-original-title], abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

dl, ol, ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ol ul, ul ol, ul ul {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub, sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}

code, kbd, pre, samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

[type=button], [type=reset], [type=submit], button {
  -webkit-appearance: button;
}

[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
  cursor: pointer;
}

[type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner, button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date], input[type=datetime-local], input[type=month], input[type=time] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

html {
  height: 100%;
}

::selection {
  background: rgba(146, 52, 79, 0.42);
  /* WebKit/Blink Browsers */
}

::-moz-selection {
  background: rgba(146, 52, 79, 0.42);
  /* Gecko Browsers */
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: #3a3a40;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: #43454e;
  line-height: 1.75;
}

h1,
.mat-typography h1 {
  font-size: 2.875rem;
  font-weight: 600;
  text-transform: uppercase;
}
@media screen and (max-width: 599px) {
  h1,
.mat-typography h1 {
    font-size: 2rem;
  }
}

h2,
.mat-typography h2 {
  font-weight: 500;
  font-size: 2.125rem;
  margin-bottom: 1.071em;
  text-transform: uppercase;
}
@media screen and (max-width: 599px) {
  h2,
.mat-typography h2 {
    font-size: 1.625rem;
  }
}

h3,
.mat-typography h3 {
  font-weight: 400;
  font-size: 1.625rem;
}
@media screen and (max-width: 599px) {
  h3,
.mat-typography h3 {
    font-size: 1.375rem;
  }
}

h4,
.mat-typography h4 {
  font-weight: 500;
  font-size: 1.25rem;
}
@media screen and (max-width: 599px) {
  h4,
.mat-typography h4 {
    font-size: 1.125rem;
  }
}

h5,
.mat-typography h5 {
  font-size: 1rem;
  font-weight: 500;
}
@media screen and (max-width: 599px) {
  h5,
.mat-typography h5 {
    font-size: 1.125rem;
  }
}

h6,
.mat-typography h6 {
  font-size: 0.929em;
  font-weight: 700;
}
@media screen and (max-width: 599px) {
  h6,
.mat-typography h6 {
    font-size: 0.929em;
  }
}

p {
  margin-bottom: 1.071em;
}

label {
  margin-bottom: auto;
}

small,
.small {
  font-size: 85%;
  font-weight: 500;
  color: inherit;
  word-break: break-word;
  letter-spacing: 0 0.071em;
}

a:not([href]):not([tabindex]),
a {
  cursor: pointer;
  color: #3262ff;
  transition: all 0.2s ease-in-out;
}

a:not([href]):not([tabindex]):hover,
a:hover {
  color: #3262ff;
}

a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):active,
a:focus,
a:active {
  outline: none;
  color: #3262ff;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

/* Lists---------------------------------- */
ul {
  list-style: square;
}

/* 2 Layout----------------------------------------------------------------------------- */
/* Miscellaneous---------------------------------- */
.text-primary {
  color: #43454e;
}

.d-block {
  display: block !important;
}

.text-thin {
  font-weight: 300;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.no-padding {
  padding: 0 !important;
}

.font-weight-300 {
  font-weight: 300 !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.pr {
  position: relative;
}

.prt--01 {
  position: relative;
  top: -1px !important;
}

.prt--02 {
  position: relative;
  top: -2px !important;
}

.prt--03 {
  position: relative;
  top: -3px !important;
}

.prt--04 {
  position: relative;
  top: -4px !important;
}

.prt--05 {
  position: relative;
  top: -5px !important;
}

.prt--06 {
  position: relative;
  top: -6px !important;
}

.prt--07 {
  position: relative;
  top: -7px !important;
}

.prt-01 {
  position: relative;
  top: 1px !important;
}

.prt-02 {
  position: relative;
  top: 2px !important;
}

.prt-03 {
  position: relative;
  top: 3px !important;
}

.prt-04 {
  position: relative;
  top: 4px !important;
}

.prt-05 {
  position: relative;
  top: 5px !important;
}

.prt-06 {
  position: relative;
  top: 6px !important;
}

.prt-07 {
  position: relative;
  top: 7px !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-5 {
  padding-left: 0.3125rem !important;
}

.pl-10 {
  padding-left: 0.625rem !important;
}

.pl-15 {
  padding-left: 0.9375rem !important;
}

.pl-20 {
  padding-left: 1.25rem !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pr-5 {
  padding-right: 0.3125rem !important;
}

.pr-10 {
  padding-right: 0.625rem !important;
}

.pr-15 {
  padding-right: 0.9375rem !important;
}

.pr-20 {
  padding-right: 1.25rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-10 {
  padding: 0.625rem !important;
}

.p-20 {
  padding: 1.25rem !important;
}

.p-30 {
  padding: 1.875rem !important;
}

.p-40 {
  padding: 2.5rem !important;
}

.ph-05 {
  padding-left: 0.3125rem !important;
  padding-right: 0.3125rem !important;
}

.ph-15 {
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}

.ph-20 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.ph-30 {
  padding-left: 1.875rem;
  padding-right: 1.875rem;
}

.ph-40 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

@media screen and (max-width: 1025px) {
  .ph-10-sm {
    padding-left: 0.625rem !important;
    padding-right: 0.625rem !important;
  }
}

@media screen and (max-width: 1025px) {
  .ph-05-sm {
    padding-left: 0.3125rem !important;
    padding-right: 0.3125rem !important;
  }
}

.pv-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.pv-05 {
  padding-top: 0.3125rem !important;
  padding-bottom: 0.3125rem !important;
}

.pv-10 {
  padding-top: 0.625rem !important;
  padding-bottom: 0.625rem !important;
}

.pv-15 {
  padding-top: 0.938rem !important;
  padding-bottom: 0.938rem !important;
}

.pv-20 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.pv-30 {
  padding-top: 1.875rem !important;
  padding-bottom: 1.875rem !important;
}

.pv-40 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-05 {
  margin-top: 0.3125rem !important;
}

@media (max-width: 599px) {
  .mt-05-sm {
    margin-top: 0.3125rem !important;
  }
}
.mb-05 {
  margin-bottom: 0.3125rem !important;
}

.mt-10 {
  margin-top: 0.625rem !important;
}

.mb-10 {
  margin-bottom: 0.625rem !important;
}

.mt-20 {
  margin-top: 1.25rem !important;
}

.mb-20 {
  margin-bottom: 1.25rem !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-5 {
  margin-top: 0.3125rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-15 {
  margin-bottom: 0.9375rem !important;
}

.mt-15 {
  margin-top: 0.9375rem !important;
}

.mb-30 {
  margin-bottom: 1.875rem !important;
}

.mt-30 {
  margin-top: 1.875rem !important;
}

.mb-40 {
  margin-bottom: 2.5rem !important;
}

.mt-40 {
  margin-top: 2.5rem !important;
}

.mb-50 {
  margin-bottom: 3.125rem !important;
}

.mt-50 {
  margin-top: 3.125rem !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-05 {
  margin-left: 0.3125rem !important;
}

.ml-15 {
  margin-left: 0.9375rem !important;
}

.ml-30 {
  margin-left: 1.875rem !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-05 {
  margin-right: 0.3125rem !important;
}

.mr-10 {
  margin-right: 0.625rem !important;
}

.mr-15 {
  margin-right: 0.9375rem !important;
}

.mr-30 {
  margin-right: 1.875rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-05 {
  padding-top: 5px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-15 {
  padding-top: 0.9375rem !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-05 {
  padding-bottom: 5px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-15 {
  padding-bottom: 0.9375rem !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.mw-305 {
  min-width: 19.0625rem;
}

.maxw-315 {
  max-width: 19.6875rem;
}

.maxw-450 {
  max-width: 28.125rem;
}

.margin-clear {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.circle {
  border-radius: 100% !important;
}

/* Tabs---------------------------------- */
@media (min-width: 481px) and (max-width: 767px) {
  .nav-tabs > li > a {
    padding: 0.857em 0.714em;
    font-size: 0.786em;
    margin-right: 0;
  }
}
@media (max-width: 480px) {
  .nav-tabs {
    background-color: #eaeaea;
    border-bottom: none !important;
  }

  .nav-tabs > li {
    float: none;
  }

  .nav-tabs > li a {
    color: #fff;
    margin-right: 0;
    border-radius: 0 !important;
  }

  .nav-tabs > li a:after,
.nav-tabs > li a:before {
    border-color: transparent transparent transparent transparent !important;
  }
}
/* Forms---------------------------------- */
textarea {
  resize: vertical;
}

/*Images and Overlays---------------------------------- */
@-webkit-keyframes slideInUp {
  from {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInUp {
  from {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}
@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.animate-logo:hover svg #_1, .animate-logo:focus svg #_1 {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  perspective: 1000;
  transform-origin: bottom right;
  transform-box: fill-box;
  -webkit-animation: hiddenToVisible 0.4s linear;
  animation: hiddenToVisible 0.4s linear;
  -webkit-animation-delay: 0;
  animation-delay: 0;
}
.animate-logo:hover svg #_2, .animate-logo:focus svg #_2 {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  perspective: 1000;
  transform-origin: bottom right;
  transform-box: fill-box;
  -webkit-animation: hiddenToVisible 0.4s linear;
  animation: hiddenToVisible 0.4s linear;
  -webkit-animation-delay: 50ms;
  animation-delay: 50ms;
}
.animate-logo:hover svg #_3, .animate-logo:focus svg #_3 {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  perspective: 1000;
  transform-origin: bottom right;
  transform-box: fill-box;
  -webkit-animation: hiddenToVisible 0.4s linear;
  animation: hiddenToVisible 0.4s linear;
  -webkit-animation-delay: 100ms;
  animation-delay: 100ms;
}
.animate-logo:hover svg #_4, .animate-logo:focus svg #_4 {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  perspective: 1000;
  transform-origin: bottom right;
  transform-box: fill-box;
  -webkit-animation: hiddenToVisible 0.4s linear;
  animation: hiddenToVisible 0.4s linear;
  -webkit-animation-delay: 150ms;
  animation-delay: 150ms;
}
.animate-logo:hover svg #_5, .animate-logo:focus svg #_5 {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  perspective: 1000;
  transform-origin: bottom right;
  transform-box: fill-box;
  -webkit-animation: hiddenToVisible 0.4s linear;
  animation: hiddenToVisible 0.4s linear;
  -webkit-animation-delay: 200ms;
  animation-delay: 200ms;
}
.animate-logo:hover svg #_6, .animate-logo:focus svg #_6 {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  perspective: 1000;
  transform-origin: bottom right;
  transform-box: fill-box;
  -webkit-animation: hiddenToVisible 0.4s linear;
  animation: hiddenToVisible 0.4s linear;
  -webkit-animation-delay: 250ms;
  animation-delay: 250ms;
}
.animate-logo:hover svg #_7, .animate-logo:focus svg #_7 {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  perspective: 1000;
  transform-origin: bottom right;
  transform-box: fill-box;
  -webkit-animation: hiddenToVisible 0.4s linear;
  animation: hiddenToVisible 0.4s linear;
  -webkit-animation-delay: 300ms;
  animation-delay: 300ms;
}
.animate-logo:hover svg #_8, .animate-logo:focus svg #_8 {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  perspective: 1000;
  transform-origin: bottom right;
  transform-box: fill-box;
  -webkit-animation: hiddenToVisible 0.4s linear;
  animation: hiddenToVisible 0.4s linear;
  -webkit-animation-delay: 350ms;
  animation-delay: 350ms;
}
.animate-logo:hover svg #_9, .animate-logo:focus svg #_9 {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  perspective: 1000;
  transform-origin: bottom right;
  transform-box: fill-box;
  -webkit-animation: hiddenToVisible 0.4s linear;
  animation: hiddenToVisible 0.4s linear;
  -webkit-animation-delay: 400ms;
  animation-delay: 400ms;
}

@-webkit-keyframes hiddenToVisible {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
@keyframes hiddenToVisible {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
html {
  background-color: #ffffff;
  position: relative;
  height: 100%;
}

body {
  position: relative;
  height: 100%;
  color: #43454e;
  background-color: #ffffff;
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.46666667 !important;
  font-weight: 400;
  overflow: unset !important;
  text-rendering: optimizeLegibility;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
}
@media only screen and (max-width: 599px) {
  body {
    font-size: 14px;
  }
}
body::-webkit-scrollbar-track {
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;
}
body::-webkit-scrollbar-thumb {
  border-radius: 1.429em;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.2);
}
body::-webkit-scrollbar {
  height: 0.65em;
  width: 0.65em;
  background-color: transparent;
}

@media screen and (max-width: 1279px) {
  * {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
  }

  input,
textarea {
    -webkit-user-select: auto !important;
    -moz-user-select: auto !important;
    -ms-user-select: auto !important;
    user-select: auto !important;
  }
}
:focus {
  outline-color: rgba(0, 0, 0, 0.24);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #43454e;
  line-height: 1.2 !important;
  font-family: Roboto, Arial, sans-serif;
  text-transform: none;
}

h1.dark-primary,
h2.dark-primary,
h3.dark-primary,
h4.dark-primary,
h5.dark-primary,
h6.dark-primary,
p.dark-primary,
span.dark-primary,
small.dark-primary,
mat-icon.dark-primary,
mat-cell.dark-primary,
mat-header-cell.dark-primary,
mat-footer-cell.dark-primary {
  color: #571f2f;
}
h1.light-primary,
h2.light-primary,
h3.light-primary,
h4.light-primary,
h5.light-primary,
h6.light-primary,
p.light-primary,
span.light-primary,
small.light-primary,
mat-icon.light-primary,
mat-cell.light-primary,
mat-header-cell.light-primary,
mat-footer-cell.light-primary {
  color: #92344f;
}
h1.dark-accent,
h2.dark-accent,
h3.dark-accent,
h4.dark-accent,
h5.dark-accent,
h6.dark-accent,
p.dark-accent,
span.dark-accent,
small.dark-accent,
mat-icon.dark-accent,
mat-cell.dark-accent,
mat-header-cell.dark-accent,
mat-footer-cell.dark-accent {
  color: #294661;
}

p {
  font-size: 1em;
  font-family: Roboto, Arial, sans-serif;
}

textarea {
  max-height: 21.429em;
  font-family: Roboto, Arial, sans-serif;
}

ul {
  font-size: 1rem;
  list-style: none;
}

strong,
.strong {
  font-weight: bold;
}

.modal-body,
.tou-container,
.scrollbar {
  touch-action: manipulation;
}
.modal-body::-webkit-scrollbar-track,
.tou-container::-webkit-scrollbar-track,
.scrollbar::-webkit-scrollbar-track {
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;
}
.modal-body::-webkit-scrollbar-thumb,
.tou-container::-webkit-scrollbar-thumb,
.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 1.429em;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.2);
}
.modal-body::-webkit-scrollbar,
.tou-container::-webkit-scrollbar,
.scrollbar::-webkit-scrollbar {
  height: 0.357em;
  width: 0.357em;
  background-color: transparent;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.clickable {
  cursor: pointer !important;
  outline-color: rgba(245, 247, 250, 0.24);
}
.clickable.clickable_hover:hover, .clickable.clickable_hover:focus {
  background-color: rgba(245, 247, 250, 0.72);
}

.break-line {
  white-space: normal !important;
  word-break: break-word !important;
}

.min-h-auto {
  min-height: auto !important;
}

form {
  width: 100%;
  max-width: 100%;
  margin: 0;
}

.animated {
  visibility: visible !important;
}

.hide-on-init {
  visibility: hidden;
}

/* -- Mobile Nav -- */
@media only screen and (max-width: 599px) {
  .app-sidebar {
    display: none;
  }

  .sidebar-mobile-open .app-sidebar {
    display: block;
  }
}
/* -- Mobile Nav -- */
img.shadow {
  -webkit-filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.72));
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.72));
}

/* -- Mat Card -- */
.mat-card {
  background-color: #fff;
  margin-bottom: 1.071rem;
  border-radius: 8px !important;
  width: 100%;
}
.mat-card.no-float {
  float: none !important;
}

.mat-card.transparent {
  box-shadow: none;
  background: transparent;
}

.mat-card.transparent .head h2 {
  color: #43454e;
  font-size: 1.286em;
  margin-bottom: 0.714em;
  padding: 0;
  text-align: left;
  font-weight: 400;
}

.head {
  font-size: 1.071rem;
  font-weight: 500;
  line-height: 1.2;
  font-family: Roboto, Arial, sans-serif;
  text-transform: none;
  -webkit-margin-after: 0.8em;
  margin-block-end: 0.8em;
}

.apps:not(.apps_header) {
  position: relative;
  top: 1.786em;
}
.apps .material-icons-outlined,
.apps .material-icons {
  font-size: 2.857em;
  width: 2.857em;
  height: auto;
  margin: 0 auto;
  color: inherit;
}
.apps .mat-card {
  background: transparent;
  color: #43454e;
  width: auto;
  min-width: 6.214em;
  box-shadow: none;
  text-align: center;
  padding: 0.429em 0;
  margin: 0.143em 0;
  cursor: pointer;
  float: right;
}
.apps .mat-card.active, .apps .mat-card:hover, .apps .mat-card:focus {
  background: #607588;
  color: #fff;
  width: auto;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.apps .mat-card.active .material-icons-outlined,
.apps .mat-card.active .material-icons, .apps .mat-card:hover .material-icons-outlined,
.apps .mat-card:hover .material-icons, .apps .mat-card:focus .material-icons-outlined,
.apps .mat-card:focus .material-icons {
  color: #fff;
}
.apps .mat-card.disabled {
  color: rgba(0, 0, 0, 0.24);
  cursor: not-allowed;
  pointer-events: none;
}
.apps .mat-card.active {
  pointer-events: none;
}
.apps .mat-card p {
  color: inherit;
  padding: 0.357em 1.071rem 0;
  margin-bottom: 0;
  font-weight: 500;
  word-break: break-word;
}
.apps.apps_header .mat-card p {
  color: inherit;
  line-height: 1rem;
  padding: 0.357em 0.143em 0;
  word-break: break-word;
  white-space: normal;
}

/* -- Button Disabled CSS -- */
button {
  cursor: pointer;
}

button:focus {
  outline-color: transparent;
}

/* -- End Button Disabled CSS -- */
/* -- Misc. Tools -- */
.text-muted {
  color: #8e8f94 !important;
}

.modal-body {
  overflow-y: auto;
}
@media (max-height: 568px) {
  .modal-body {
    max-height: calc(100vh - 7.857em);
  }
}
@media (max-height: 845px) {
  .modal-body {
    max-height: 58vh;
  }
}

form h4,
.modal-body h4,
h4.modal-title {
  font-size: 1rem;
  font-weight: 500;
}

form h4.header {
  background-color: #ffffff;
  margin: 0 -1.071rem;
  padding: 0.714em 1.071rem;
  border-top: 0.071rem solid rgba(0, 0, 0, 0.1);
  border-bottom: 0.071rem solid rgba(0, 0, 0, 0.1);
}

.modal-footer {
  background: #ffffff;
  border-top: none;
}
@media (max-height: 501px) {
  .modal-footer {
    padding: 0.357em 1.071rem;
  }
}

.no-padding {
  padding: 0;
}

.no-margin {
  margin: 0;
}

.full-width {
  width: 100%;
}

.half-width {
  width: 50%;
}

/* -- End Misc. Tools -- */
/* -- Empty Full -- */
.empty-full {
  position: absolute;
  top: 4.286em;
  right: 0;
  bottom: 3.143em;
  left: 17.857em;
  z-index: 1;
  text-align: center;
  background-color: transparent;
  transition: left 0.3s cubic-bezier(0, 0, 0.2, 1);
}
.empty-full .empty-container {
  padding-top: 29vh;
  font-size: 1rem;
}
.empty-full .empty-container img {
  max-height: 10.714em;
  margin: 0 auto;
  -webkit-filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.32));
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.32));
}
@media (max-width: 500px) {
  .empty-full .empty-container img {
    max-height: 8.143em;
  }
}
.empty-full .empty-container h2 {
  margin: 0;
  color: #43454e;
  word-break: break-word;
  z-index: 1;
  font-weight: 400;
  font-size: 1rem;
}
.empty-full .empty-container h2.empty_long-text {
  margin: 0 auto;
  max-width: 435px;
}
@media (max-width: 599px) {
  .empty-full .empty-container h2.empty_long-text {
    max-width: 320px;
  }
}
.empty-full .empty-container h2 button {
  font-size: 14px;
}
.empty-full .empty-container a {
  color: #607588;
}
.empty-full .empty-container small {
  margin-top: 1.071rem;
  display: block;
  font-weight: 400;
}
.empty-full .empty-container button {
  margin-top: 1.786em;
}
@media (max-width: 991px) {
  .empty-full .empty-container {
    left: 0;
    padding-top: 12vh;
    padding-left: 1.071rem;
    padding-right: 1.071rem;
  }
}
@media (max-width: 450px) {
  .empty-full .empty-container {
    padding-top: 20vh;
  }
  .empty-full .empty-container button,
.empty-full .empty-container .empty button {
    margin-bottom: 1.786em;
  }
}

.nav-collapsed .empty-full {
  left: 0;
}

.empty-full-width {
  position: relative;
  display: block;
  width: 100%;
  padding: 10% 0;
  text-align: center;
  background-color: transparent;
}
.empty-full-width h2 {
  margin: 0;
  color: #43454e;
  word-break: break-word;
  font-weight: 400;
}
.empty-full-width h2.empty_long-text {
  margin: 0 auto;
  max-width: 435px;
}
@media (max-width: 599px) {
  .empty-full-width h2.empty_long-text {
    max-width: 320px;
  }
}
.empty-full-width h2 button {
  font-size: 14px;
}
.empty-full-width button {
  margin-top: 1.786em;
}
.empty-full-width a {
  color: #607588;
}
@media (max-width: 450px) {
  .empty-full-width .empty-full-width button {
    margin-bottom: 1.786em;
  }
}

/* -- End Empty Full -- */
/* -- Empty Contain -- */
.empty-contain {
  z-index: 1;
  text-align: center;
  background-color: transparent;
  transition: left 0.3s cubic-bezier(0, 0, 0.2, 1);
}
.empty-contain img {
  max-height: 10.714em;
  margin: 0 auto;
  -webkit-filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.32));
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.32));
}
@media (max-width: 500px) {
  .empty-contain img {
    max-height: 7.143em;
  }
}
@media (max-width: 375px) {
  .empty-contain img {
    max-height: 5.357em;
  }
}
.empty-contain h2 {
  margin: 0;
  padding: 5em 2.857em;
  color: #43454e;
  word-break: break-word;
  z-index: 1;
  font-size: 1rem;
  font-weight: 400;
}
.empty-contain h2.empty_long-text {
  margin: 0 auto;
  max-width: 435px;
}
@media (max-width: 599px) {
  .empty-contain h2.empty_long-text {
    max-width: 320px;
  }
}
.empty-contain h2 button {
  font-size: 14px;
}
.empty-contain small {
  margin-top: 1.071rem;
  display: block;
  font-weight: 400;
}
.empty-contain a {
  color: #607588;
}
.empty-contain button {
  margin-top: 1.786em;
}

/* -- End Empty Contain -- */
/* -- Empty -- */
.empty img {
  max-height: 6.571rem;
  margin: 0 auto;
  margin-bottom: 1.071rem;
  -webkit-filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.32));
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.32));
  pointer-events: none;
}
.empty h2 {
  margin: 0;
  padding: 5em 2.857em;
  color: #43454e;
  word-break: break-word;
  z-index: 1;
  font-size: 1rem;
  font-weight: 400;
}
.empty h2.empty_long-text {
  margin: 0 auto;
  max-width: 435px;
}
@media (max-width: 599px) {
  .empty h2.empty_long-text {
    max-width: 320px;
  }
}
.empty h2 small {
  font-weight: 400;
}
.empty h2.lean {
  padding: 2.143em 2.857em;
}
.empty h2 i {
  padding-bottom: 0.429em;
}
.empty h2.pb-15 {
  padding-bottom: 1.071rem;
}
.empty h2 button {
  font-size: 14px;
}
@media (max-width: 570px) {
  .empty h2 {
    padding: 2.857em 1.786em;
  }
}
@media (max-width: 450px) {
  .empty button {
    margin-bottom: 1.786em;
  }
}

/* -- End Empty -- */
/* -- Empty Boxes / Cards -- */
.mat-card .empty {
  width: 100%;
  height: auto;
  background-color: transparent;
  text-align: center;
}

/* -- End Empty Boxes / Cards -- */
/* Jp-card */
.jp-card {
  min-width: 7.143em !important;
}
@media (max-width: 600px) {
  .jp-card .jp-card-front .jp-card-lower .jp-card-number {
    font-size: 1.429em;
    margin-bottom: 1.071rem;
  }
}

.card-wrapper-modal .jp-card {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

.card-wrapper .jp-card-container {
  margin: 0;
}
@media (max-width: 575px) {
  .card-wrapper .jp-card-container {
    margin: 0 auto;
  }
}

.card-wrapper .jp-card-container,
.card-wrapper-modal .jp-card-container {
  width: 100%;
  max-width: 26.786em;
}
@media (max-width: 600px) {
  .card-wrapper .jp-card-container,
.card-wrapper-modal .jp-card-container {
    height: 14.143em;
    max-width: 24.286em;
  }
}
@media (max-width: 365px) {
  .card-wrapper .jp-card-container,
.card-wrapper-modal .jp-card-container {
    height: 11.571rem;
    max-width: 20em;
  }
}
.card-wrapper .jp-card-container .jp-card,
.card-wrapper-modal .jp-card-container .jp-card {
  min-width: 7.143em !important;
}
@media (max-width: 600px) {
  .card-wrapper .jp-card-container .jp-card .jp-card-front .jp-card-lower .jp-card-number,
.card-wrapper-modal .jp-card-container .jp-card .jp-card-front .jp-card-lower .jp-card-number {
    font-size: 1.429em;
    margin-bottom: 2.143em;
  }
}
@media (max-width: 600px) {
  .card-wrapper .jp-card-container .jp-card .jp-card-front .jp-card-lower .jp-card-name,
.card-wrapper-modal .jp-card-container .jp-card .jp-card-front .jp-card-lower .jp-card-name {
    font-size: 1.143em;
  }
}
@media (max-width: 600px) {
  .card-wrapper .jp-card-container .jp-card .jp-card-front .jp-card-lower .jp-card-expiry,
.card-wrapper-modal .jp-card-container .jp-card .jp-card-front .jp-card-lower .jp-card-expiry {
    font-size: 1.143em;
  }
}

@media (max-width: 1500px) and (min-width: 1200px) {
  .jp-card .jp-card-front .jp-card-lower .jp-card-expiry {
    bottom: -1.071rem;
    position: relative;
  }

  .jp-card .jp-card-front .jp-card-lower .jp-card-number {
    margin-bottom: 1.429em !important;
    white-space: normal;
    word-wrap: break-word;
    font-size: 1.429em !important;
  }
}
.jp-card:hover,
.jp-card:active,
.jp-card:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

.interaction {
  position: absolute;
  left: 5%;
  top: 3%;
}

.interaction i {
  display: inline-block;
  padding: 0.357em;
  font-size: 2em;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.6);
}

.interaction i:hover,
.interaction i:focus,
.interaction i:active {
  color: white;
}

.jp-card-bank::before {
  font-family: "Material Icons";
  content: "";
  position: absolute;
  right: 5%;
  top: 8%;
  font-size: 3.429em;
  color: rgba(255, 255, 255, 0.6);
  z-index: 1;
}

.jp-card-logo.jp-card-jcb {
  font-family: Roboto, Arial, sans-serif;
  height: 2.857em;
  width: 7.143em;
  font-style: normal;
  color: #fff;
  letter-spacing: 0.071rem;
}

.jp-card-jcb::before {
  content: "JCB";
  position: absolute;
  right: 5%;
  font-size: 2em;
  top: 8%;
  font-weight: 600;
}

.jp-card-blank::before {
  font-family: "Material Icons";
  content: "";
  position: absolute;
  right: 5%;
  top: 8%;
  font-size: 3.429em;
}

.jp-card-logo.jp-card-dinersclub::before {
  content: "";
}

.jp-card-bank-name {
  position: absolute;
  top: -2.286em;
  right: -1rem;
  text-align: right;
  padding: 0 0.357em;
  font-size: 1.214em;
  color: white;
  font-weight: normal;
}
@media (max-width: 500px) {
  .jp-card-bank-name {
    top: -1.786em;
  }
}

.hamburger {
  padding: 0.357em 0.357em;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, -webkit-filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}
.hamburger:hover {
  opacity: 1;
}

.hamburger-box {
  width: 2.143em;
  height: 1.287em;
  display: inline-block;
  position: relative;
  top: -0.414em;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: 0.143em;
}
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 2.143em;
  height: 0.143em;
  background-color: #fff;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}
.hamburger-inner::before {
  top: -0.572em;
}
.hamburger-inner::after {
  bottom: -0.572em;
}

.hamburger-dark .hamburger-inner, .hamburger-dark .hamburger-inner::before, .hamburger-dark .hamburger-inner::after {
  background-color: #ffffff;
}

/*!
  * Hamburgers
  * @description Tasty CSS-animated hamburgers
  * @author Jonathan Suh @jonsuh
  * @site https://jonsuh.com/hamburgers
  * @link https://github.com/jonsuh/hamburgers
  */
/*
* Squeeze
*/
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--squeeze .hamburger-inner::before {
  transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}
.hamburger--squeeze .hamburger-inner::after {
  transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.sidebar-mobile-open .hamburger-inner:not(.hamburger_team-menu) {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.sidebar-mobile-open .hamburger-inner:not(.hamburger_team-menu)::before {
  top: 0;
  opacity: 0;
  transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}
.sidebar-mobile-open .hamburger-inner:not(.hamburger_team-menu)::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.print-only {
  display: none !important;
}

@media print {
  .print-only {
    display: block !important;
  }

  .no-print {
    display: none !important;
  }

  .page-fab,
.mat-button,
.mat-raised-button,
.help-button,
.breadcrumb {
    display: none !important;
  }

  a,
a:not([href]):not([tabindex]) {
    color: #000;
  }

  .mat-card {
    box-shadow: none !important;
  }
  .mat-card .cell-detail p {
    color: #000;
    font-weight: 500;
  }
}
.scroll-container {
  overflow-y: auto;
  max-height: 25em;
  padding: 0 0.357em 0 0;
  overflow-x: hidden;
  touch-action: manipulation;
}
@media (max-height: 812) {
  .scroll-container {
    max-height: 21.429em;
  }
}
@media (max-height: 500) {
  .scroll-container {
    max-height: 17.5em;
  }
}

highcharts-chart * {
  font-family: Roboto, Arial, sans-serif;
}

.highcharts-background {
  fill: transparent !important;
}

.highcharts-title {
  font-size: 16px;
  font-weight: 400;
  font-family: Roboto, Arial, sans-serif;
}
.highcharts-title tspan {
  font-size: 16px;
}

.highcharts-credits {
  display: none !important;
}

.highcharts-label text {
  fill: #43454e !important;
  color: #43454e !important;
}
.highcharts-label text tspan {
  stroke-width: 0 !important;
  font-weight: 400 !important;
}

.dashboard-chart .highcharts-legend-item.highcharts-series-1 rect {
  color: #499349 !important;
  fill: #499349 !important;
}

.text-thin {
  font-weight: 300;
}

.New {
  color: rgba(0, 0, 0, 0.64) !important;
}

.Rejected,
.Failed {
  color: #B5504A !important;
}
.Rejected h1,
.Rejected h2,
.Rejected h3,
.Rejected h4,
.Rejected h5,
.Rejected h6,
.Rejected p,
.Rejected span,
.Rejected small,
.Rejected mat-icon,
.Rejected mat-cell,
.Rejected mat-header-cell,
.Rejected mat-footer-cell,
.Failed h1,
.Failed h2,
.Failed h3,
.Failed h4,
.Failed h5,
.Failed h6,
.Failed p,
.Failed span,
.Failed small,
.Failed mat-icon,
.Failed mat-cell,
.Failed mat-header-cell,
.Failed mat-footer-cell {
  color: #B5504A !important;
}
.Rejected button .mat-button-wrapper,
.Rejected a .mat-button-wrapper,
.Failed button .mat-button-wrapper,
.Failed a .mat-button-wrapper {
  color: inherit !important;
}

.Disabled,
.OnHold,
.Canceled {
  color: rgba(0, 0, 0, 0.44) !important;
}
.Disabled h1,
.Disabled h2,
.Disabled h3,
.Disabled h4,
.Disabled h5,
.Disabled h6,
.Disabled p,
.Disabled span,
.Disabled small,
.Disabled mat-icon,
.Disabled mat-cell,
.Disabled mat-header-cell,
.Disabled mat-footer-cell,
.OnHold h1,
.OnHold h2,
.OnHold h3,
.OnHold h4,
.OnHold h5,
.OnHold h6,
.OnHold p,
.OnHold span,
.OnHold small,
.OnHold mat-icon,
.OnHold mat-cell,
.OnHold mat-header-cell,
.OnHold mat-footer-cell,
.Canceled h1,
.Canceled h2,
.Canceled h3,
.Canceled h4,
.Canceled h5,
.Canceled h6,
.Canceled p,
.Canceled span,
.Canceled small,
.Canceled mat-icon,
.Canceled mat-cell,
.Canceled mat-header-cell,
.Canceled mat-footer-cell {
  color: rgba(0, 0, 0, 0.52) !important;
}

.Completed {
  color: #228264 !important;
}
.Completed h1,
.Completed h2,
.Completed h3,
.Completed h4,
.Completed h5,
.Completed h6,
.Completed p,
.Completed span,
.Completed small,
.Completed mat-icon,
.Completed mat-cell,
.Completed mat-header-cell,
.Completed mat-footer-cell {
  color: #228264 !important;
}

.more-button {
  position: absolute !important;
  top: 0.571rem;
  right: 0.714em;
  z-index: 1;
}
.more-button.second {
  right: 3.571rem;
}
.more-button.third {
  right: 6.429em;
}
.more-button.fourth {
  right: 9.286em;
}
.more-button.fifth {
  right: 12.143em;
}
.more-button.sixth {
  right: 15em;
}

.mat-menu-panel .mat-menu-item .mat-icon-no-color.shine,
.shine {
  cursor: default;
}
.mat-menu-panel .mat-menu-item .mat-icon-no-color.shine.mat-gold, .mat-menu-panel .mat-menu-item .mat-icon-no-color.shine.gold,
.shine.mat-gold,
.shine.gold {
  text-decoration: none;
  transition: all 0.5s;
  color: #f4b400;
  -webkit-animation: 1s ease-in-out infinite alternate goldShine;
  animation: 1s ease-in-out infinite alternate goldShine;
}
.mat-menu-panel .mat-menu-item .mat-icon-no-color.shine.mat-purple, .mat-menu-panel .mat-menu-item .mat-icon-no-color.shine.purple,
.shine.mat-purple,
.shine.purple {
  text-decoration: none;
  transition: all 0.5s;
  color: #5456a2;
  -webkit-animation: 1s ease-in-out infinite alternate purpleShine;
  animation: 1s ease-in-out infinite alternate purpleShine;
}
.mat-menu-panel .mat-menu-item .mat-icon-no-color.shine.shine_limit-animation,
.shine.shine_limit-animation {
  -webkit-animation-iteration-count: 3;
  animation-iteration-count: 3;
}

@-webkit-keyframes goldShine {
  0% {
    text-shadow: 0 0 3.334px #fff, 0 0 6.667px #fff, 0 0 10px #fff, 0 0 6.667px rgba(244, 180, 0, 0.45), 0 0 11.667px rgba(244, 180, 0, 0.45), 0 0 13.3335px rgba(244, 180, 0, 0.45), 0 0 16.667px rgba(244, 180, 0, 0.45), 0 0 20px rgba(244, 180, 0, 0.45);
  }
  100% {
    text-shadow: 0 0 1.667px #fff, 0 0 3.334px #fff, 0 0 5px #fff, 0 0 3.334px rgba(244, 180, 0, 0.45), 0 0 5.8335px rgba(244, 180, 0, 0.45), 0 0 6.667px rgba(244, 180, 0, 0.45), 0 0 8.334px rgba(244, 180, 0, 0.45), 0 0 12.5px rgba(244, 180, 0, 0.45);
  }
}
@keyframes goldShine {
  0% {
    text-shadow: 0 0 3.334px #fff, 0 0 6.667px #fff, 0 0 10px #fff, 0 0 6.667px rgba(244, 180, 0, 0.45), 0 0 11.667px rgba(244, 180, 0, 0.45), 0 0 13.3335px rgba(244, 180, 0, 0.45), 0 0 16.667px rgba(244, 180, 0, 0.45), 0 0 20px rgba(244, 180, 0, 0.45);
  }
  100% {
    text-shadow: 0 0 1.667px #fff, 0 0 3.334px #fff, 0 0 5px #fff, 0 0 3.334px rgba(244, 180, 0, 0.45), 0 0 5.8335px rgba(244, 180, 0, 0.45), 0 0 6.667px rgba(244, 180, 0, 0.45), 0 0 8.334px rgba(244, 180, 0, 0.45), 0 0 12.5px rgba(244, 180, 0, 0.45);
  }
}
@-webkit-keyframes purpleShine {
  0% {
    text-shadow: 0 0 3.334px #fff, 0 0 6.667px #fff, 0 0 10px #fff, 0 0 6.667px rgba(100, 65, 164, 0.45), 0 0 11.667px rgba(100, 65, 164, 0.45), 0 0 13.3335px rgba(100, 65, 164, 0.45), 0 0 16.667px rgba(100, 65, 164, 0.45), 0 0 20px rgba(100, 65, 164, 0.45);
  }
  100% {
    text-shadow: 0 0 1.667px #fff, 0 0 3.334px #fff, 0 0 5px #fff, 0 0 3.334px rgba(100, 65, 164, 0.45), 0 0 5.8335px rgba(100, 65, 164, 0.45), 0 0 6.667px rgba(100, 65, 164, 0.45), 0 0 8.334px rgba(100, 65, 164, 0.45), 0 0 12.5px rgba(100, 65, 164, 0.45);
  }
}
@keyframes purpleShine {
  0% {
    text-shadow: 0 0 3.334px #fff, 0 0 6.667px #fff, 0 0 10px #fff, 0 0 6.667px rgba(100, 65, 164, 0.45), 0 0 11.667px rgba(100, 65, 164, 0.45), 0 0 13.3335px rgba(100, 65, 164, 0.45), 0 0 16.667px rgba(100, 65, 164, 0.45), 0 0 20px rgba(100, 65, 164, 0.45);
  }
  100% {
    text-shadow: 0 0 1.667px #fff, 0 0 3.334px #fff, 0 0 5px #fff, 0 0 3.334px rgba(100, 65, 164, 0.45), 0 0 5.8335px rgba(100, 65, 164, 0.45), 0 0 6.667px rgba(100, 65, 164, 0.45), 0 0 8.334px rgba(100, 65, 164, 0.45), 0 0 12.5px rgba(100, 65, 164, 0.45);
  }
}
.tableOfContentContainer {
  padding-top: 1rem;
  padding-left: 2em;
  margin-top: 4px;
  position: relative;
  width: 100%;
}
@media screen and (max-width: 959px) {
  .tableOfContentContainer {
    position: relative;
    padding-top: 0;
    padding-left: 0;
    margin-bottom: 1.071rem;
  }
}
.tableOfContentContainer .docs-toc-container {
  font-weight: 700;
  font-size: 0.9286em;
  border-radius: 8px;
}
.tableOfContentContainer .docs-toc-container .docs-toc-heading {
  padding: 1rem;
  font-weight: 500;
}
.tableOfContentContainer .docs-toc-container button:not(.mat-button-base),
.tableOfContentContainer .docs-toc-container a:not(.mat-button-base) {
  text-align: left;
  text-decoration: none;
  color: #43454e;
  font-weight: 400;
  padding: 0.5em 0.714em;
  display: block;
}
.tableOfContentContainer .docs-toc-container button:not(.mat-button-base):hover, .tableOfContentContainer .docs-toc-container button:not(.mat-button-base):focus,
.tableOfContentContainer .docs-toc-container a:not(.mat-button-base):hover,
.tableOfContentContainer .docs-toc-container a:not(.mat-button-base):focus {
  color: #43454e;
  font-weight: 500;
}
.tableOfContentContainer .docs-toc-container button:not(.mat-button-base).toc-disabled,
.tableOfContentContainer .docs-toc-container a:not(.mat-button-base).toc-disabled {
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.36);
}

.ccbrands {
  list-style: none;
  padding: 0;
  margin: 0;
  display: block;
  background: transparent;
}
.ccbrands li {
  height: 1.786em;
  width: 3.214em;
  margin: 1.071rem 0.357em 0 0;
  display: inline-block;
  overflow: hidden;
  text-indent: -35.714em;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
}
.ccbrands li:last-child {
  margin-right: 0;
}
.ccbrands li.ccbrands_icon {
  text-indent: 0;
}
.ccbrands .visa {
  margin: 1.071rem 0.714em 0 0;
  background-image: url(https://MortgagePartner.com/images/VISA.svg);
}
.ccbrands .masterCard {
  background-image: url(https://MortgagePartner.com/images/MasterCard.svg);
}
.ccbrands .amex {
  background-image: url(https://MortgagePartner.com/images/amex.svg);
}
.ccbrands .discover {
  background-image: url(https://MortgagePartner.com/images/Discover.svg);
}
.ccbrands .jcb {
  background-image: url(https://MortgagePartner.com/images/jcb.svg);
}
.ccbrands i {
  color: #AAB7C4;
}

.vert-button_break {
  height: 1.429em;
  width: 0.357em;
  margin-right: 0.214em;
  margin-left: 0.214em;
  margin-bottom: 0.357em;
  border-left: 0.071rem solid rgba(0, 0, 0, 0.36);
}

:not(.cdk-focused) mat-icon.animate-click {
  -webkit-animation: none;
  animation: none;
  transform: scale(1);
}

.cdk-focused mat-icon.animate-click {
  font-family: "Material Icons";
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation: 300ms ease-in-out animateClick;
  animation: 300ms ease-in-out animateClick;
  transform: scale(1);
}

@-webkit-keyframes animateClick {
  0% {
    transform: scale(0.75);
  }
  100% {
    font-family: "Material Icons";
    transform: scale(1);
  }
}
@keyframes animateClick {
  0% {
    transform: scale(0.75);
  }
  100% {
    font-family: "Material Icons";
    transform: scale(1);
  }
}
.user-profile {
  background: #dfe1e9;
  font-family: Roboto, Arial, sans-serif;
  margin-right: 0.625rem;
  color: #43454e;
  padding: 2px;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.user-profile.sm {
  display: inline-flex;
  font-size: 0.7rem;
  height: 1.625rem;
  width: 1.625rem;
}
.user-profile.lg {
  display: flex;
  font-size: 2.5rem;
  height: 5.625rem;
  width: 5.625rem;
}
@media screen and (max-width: 599px) {
  .user-profile.lg {
    position: relative;
    top: 3px;
    font-size: 1.8rem;
    height: 3.625rem;
    width: 3.625rem;
  }
}
.user-profile.user-profile_light {
  background: #fff;
  color: #43454e;
}
.user-profile.profile-Img {
  display: inline-flex;
  padding: 2px;
  background: #fff;
  height: 28px;
  width: 28px;
}

.personHeaderContainer {
  position: relative;
  background: #e8eaef;
  background: #ffffff;
  padding: 0.938rem;
  overflow: hidden;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
@media screen and (max-width: 599px) {
  .personHeaderContainer .personName {
    font-size: 2em;
  }
}
.personHeaderContainer .personType {
  position: absolute;
  right: 0;
  bottom: -2.8vw;
  font-size: 6vw;
  font-weight: 600;
  color: #d3d7e0;
}
@media screen and (max-width: 1024px) {
  .personHeaderContainer .personType {
    font-size: 8vw;
    bottom: -3.8vw;
  }
}
@media screen and (max-width: 676px) {
  .personHeaderContainer .personType {
    display: none;
  }
}

.btn-social {
  display: inline-flex;
}
.btn-social .mat-icon-button-social {
  height: 18px;
  color: #43454e;
  position: absolute;
  top: 11px;
  left: 11px;
}

.light-bg {
  background-color: #f5f7fa;
}

.hero {
  position: relative;
  width: 100%;
  padding: 150px 0;
  min-height: 35vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0% 60%;
}
@media screen and (max-width: 991px) {
  .hero {
    padding: 80px 0;
  }
}
@media screen and (max-width: 599px) {
  .hero {
    padding: 40px 0;
    background-position: 50% 50%;
  }
}
.hero h1,
.hero h2,
.hero h3,
.hero h4,
.hero h5,
.hero h6,
.hero p {
  color: #fff;
}
.hero h1 {
  font-weight: 600;
}
.hero h3 {
  font-weight: 400;
}
.hero .hero-text-backdrop {
  padding: 50px 15px;
}

.overlay-container {
  position: relative;
}

.overlay {
  background-color: rgba(255, 255, 255, 0.75);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: none;
}
.overlay.overlay_has-back-btn {
  padding: 0em 2.143em;
}
.overlay.white-90 {
  background-color: rgba(255, 255, 255, 0.9);
}
.overlay.white-90 h2 {
  text-align: center;
}
.overlay.white-65 {
  background-color: rgba(255, 255, 255, 0.65);
}
.overlay.white-65 h2 {
  text-align: center;
}
.overlay.white-95 {
  background-color: rgba(255, 255, 255, 0.95);
}
.overlay.white-95 h2 {
  text-align: center;
}
.overlay.dark {
  background-color: rgba(0, 0, 0, 0.4);
}
.overlay.show {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 501;
}
.overlay.show h2,
.overlay.show h3,
.overlay.show h4 {
  color: #43454e;
  font-weight: 500;
  font-size: 1.071em;
}
.overlay.show h2.header-text,
.overlay.show h3.header-text,
.overlay.show h4.header-text {
  font-size: 1.357em;
}
.overlay.show .overlay-icon {
  border-radius: 50%;
  border: 0.286em solid gray;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 5.714em;
  margin: 1.429em auto;
  padding: 0;
  position: relative;
  width: 5.714em;
}
.overlay.show .overlay-icon.overlay-success, .overlay.show .overlay-icon.overlay-error {
  border-color: #A5DC86;
}
.overlay.show .overlay-icon.overlay-success:after, .overlay.show .overlay-icon.overlay-success:before, .overlay.show .overlay-icon.overlay-error:after, .overlay.show .overlay-icon.overlay-error:before {
  background: #fff;
  content: "";
  height: 8.571em;
  position: absolute;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 4.286em;
}
.overlay.show .overlay-icon.overlay-success:before, .overlay.show .overlay-icon.overlay-error:before {
  border-radius: 8.571em 0 0 8.571em;
  left: -2.357em;
  top: -0.5em;
  -webkit-transform-origin: 4.286em 4.286em;
  transform-origin: 4.286em 4.286em;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.overlay.show .overlay-icon.overlay-success:after, .overlay.show .overlay-icon.overlay-error:after {
  border-radius: 0 8.571em 8.571em 0;
  left: 2.143em;
  top: -0.786em;
  -webkit-transform-origin: 0 4.286em;
  transform-origin: 0 4.286em;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.overlay.show .overlay-icon.overlay-success .overlay-placeholder, .overlay.show .overlay-icon.overlay-error .overlay-placeholder {
  border-radius: 50%;
  border: 0.286em solid rgba(165, 220, 134, 0.2);
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 5.714em;
  left: -0.286em;
  position: absolute;
  top: -0.286em;
  width: 5.714em;
  z-index: 2;
}
.overlay.show .overlay-icon.overlay-success .overlay-fix, .overlay.show .overlay-icon.overlay-error .overlay-fix {
  background: #fff;
  height: 6.429em;
  left: 2em;
  position: absolute;
  top: 0.571em;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  width: 0.357em;
  z-index: 1;
}
.overlay.show .overlay-icon.overlay-success .overlay-line, .overlay.show .overlay-icon.overlay-error .overlay-line {
  background-color: #A5DC86;
  border-radius: 0.143em;
  display: block;
  height: 0.357em;
  position: absolute;
  z-index: 2;
}
.overlay.show .overlay-icon.overlay-success .overlay-line.overlay-tip, .overlay.show .overlay-icon.overlay-error .overlay-line.overlay-tip {
  left: 1em;
  top: 3.286em;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 1.786em;
}
.overlay.show .overlay-icon.overlay-success .overlay-line.overlay-long, .overlay.show .overlay-icon.overlay-error .overlay-line.overlay-long {
  right: 0.571em;
  top: 2.714em;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  width: 3.357em;
}
.overlay.show .overlay-icon.overlay-error {
  border-color: #F27474;
}
.overlay.show .overlay-icon.overlay-error .overlay-placeholder {
  border: 0.286em solid rgba(200, 0, 0, 0.2);
}
.overlay.show .overlay-icon.overlay-error .overlay-line {
  background-color: #F27474;
  top: 2.643em;
  width: 3.357em;
}
.overlay.show .overlay-icon.overlay-error .overlay-line.overlay-left {
  left: 1.214em;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.overlay.show .overlay-icon.overlay-error .overlay-line.overlay-right {
  right: 1.143em;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.overlay.show .overlay-icon.overlay-warning {
  border-color: #F8BB86;
}
.overlay.show .overlay-icon.overlay-warning:before {
  -webkit-animation: pulseWarning 2s linear infinite;
  animation: pulseWarning 2s linear infinite;
  background-color: #fff;
  border-radius: 50%;
  content: "";
  display: inline-block;
  height: 100%;
  opacity: 0;
  position: absolute;
  width: 100%;
}
.overlay.show .overlay-icon.overlay-warning:after {
  background: transparent;
  border-radius: 50%;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;
}
.overlay.show .overlay-icon.overlay-warning .overlay-body {
  background-color: #F8BB86;
  border-radius: 0.143em;
  height: 3.357em;
  left: 50%;
  margin-left: -0.143em;
  position: absolute;
  top: 0.714em;
  width: 0.357em;
  z-index: 2;
}
.overlay.show .overlay-icon.overlay-warning .overlay-dot {
  background-color: #F8BB86;
  border-radius: 50%;
  bottom: 0.714em;
  height: 0.5em;
  left: 50%;
  margin-left: -0.214em;
  position: absolute;
  width: 0.5em;
  z-index: 2;
}
.overlay.show .overlay-icon + .overlay-icon {
  margin-top: 3.571em;
}
.overlay.show .overlay-icon .animateSuccessTip {
  -webkit-animation: animateSuccessTip 0.75s;
  animation: animateSuccessTip 0.75s;
}
.overlay.show .overlay-icon .animateSuccessLong {
  -webkit-animation: animateSuccessLong 0.75s;
  animation: animateSuccessLong 0.75s;
}
.overlay.show .overlay-icon.overlay-success.animate:after {
  -webkit-animation: rotatePlaceholder 4.25s ease-in;
  animation: rotatePlaceholder 4.25s ease-in;
}
.overlay.show .overlay-icon.overlay-error:after {
  -webkit-animation: rotatePlaceholder 4.25s ease-in;
  animation: rotatePlaceholder 4.25s ease-in;
}
.overlay.show .overlay-icon .animateErrorIcon {
  -webkit-animation: animateErrorIcon 0.5s;
  animation: animateErrorIcon 0.5s;
}
.overlay.show .overlay-icon .animateXLeft {
  -webkit-animation: animateXLeft 0.75s;
  animation: animateXLeft 0.75s;
}
.overlay.show .overlay-icon .animateXRight {
  -webkit-animation: animateXRight 0.75s;
  animation: animateXRight 0.75s;
}
.overlay.show .overlay-icon .scaleWarning {
  -webkit-animation: scaleWarning 0.75s infinite alternate;
  animation: scaleWarning 0.75s infinite alternate;
}
.overlay.show .overlay-icon .pulseWarningIns {
  -webkit-animation: pulseWarningIns 0.75s infinite alternate;
  animation: pulseWarningIns 0.75s infinite alternate;
}
@-webkit-keyframes animateSuccessTip {
  0%, 54% {
    width: 0;
    left: 0.071em;
    top: 1.357em;
  }
  70% {
    width: 3.571em;
    left: -0.571em;
    top: 2.643em;
  }
  84% {
    width: 1.214em;
    left: 1.5em;
    top: 3.429em;
  }
  100% {
    width: 1.786em;
    left: 1em;
    top: 3.214em;
  }
}
@keyframes animateSuccessTip {
  0%, 54% {
    width: 0;
    left: 0.071em;
    top: 1.357em;
  }
  70% {
    width: 3.571em;
    left: -0.571em;
    top: 2.643em;
  }
  84% {
    width: 1.214em;
    left: 1.5em;
    top: 3.429em;
  }
  100% {
    width: 1.786em;
    left: 1em;
    top: 3.214em;
  }
}
@-webkit-keyframes animateSuccessLong {
  0%, 65% {
    width: 0;
    right: 3.286em;
    top: 3.857em;
  }
  84% {
    width: 3.929em;
    right: 0;
    top: 2.5em;
  }
  100% {
    width: 3.357em;
    right: 0.571em;
    top: 2.714em;
  }
}
@keyframes animateSuccessLong {
  0%, 65% {
    width: 0;
    right: 3.286em;
    top: 3.857em;
  }
  84% {
    width: 3.929em;
    right: 0;
    top: 2.5em;
  }
  100% {
    width: 3.357em;
    right: 0.571em;
    top: 2.714em;
  }
}
@-webkit-keyframes rotatePlaceholder {
  0%, 5% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  100%, 12% {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
}
@keyframes rotatePlaceholder {
  0%, 5% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  100%, 12% {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
}
@-webkit-keyframes animateErrorIcon {
  0% {
    -webkit-transform: rotateX(100deg);
    transform: rotateX(100deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    opacity: 1;
  }
}
@keyframes animateErrorIcon {
  0% {
    -webkit-transform: rotateX(100deg);
    transform: rotateX(100deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    opacity: 1;
  }
}
@-webkit-keyframes animateXLeft {
  0%, 65% {
    left: 5.857em;
    top: 6.786em;
    width: 0;
  }
  84% {
    left: 1em;
    top: 2.357em;
    width: 3.357em;
  }
  100% {
    left: 1.214em;
    top: 2.643em;
    width: 3.357em;
  }
}
@keyframes animateXLeft {
  0%, 65% {
    left: 5.857em;
    top: 6.786em;
    width: 0;
  }
  84% {
    left: 1em;
    top: 2.357em;
    width: 3.357em;
  }
  100% {
    left: 1.214em;
    top: 2.643em;
    width: 3.357em;
  }
}
@-webkit-keyframes animateXRight {
  0%, 65% {
    right: 5.857em;
    top: 6.786em;
    width: 0;
  }
  84% {
    right: 1em;
    top: 2.357em;
    width: 3.357em;
  }
  100% {
    right: 1.143em;
    top: 2.643em;
    width: 3.357em;
  }
}
@keyframes animateXRight {
  0%, 65% {
    right: 5.857em;
    top: 6.786em;
    width: 0;
  }
  84% {
    right: 1em;
    top: 2.357em;
    width: 3.357em;
  }
  100% {
    right: 1.143em;
    top: 2.643em;
    width: 3.357em;
  }
}
@-webkit-keyframes scaleWarning {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  30% {
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scaleWarning {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  30% {
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes pulseWarning {
  0% {
    background-color: #fff;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.5;
  }
  30% {
    background-color: #fff;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    background-color: #F8BB86;
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
}
@keyframes pulseWarning {
  0% {
    background-color: #fff;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.5;
  }
  30% {
    background-color: #fff;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    background-color: #F8BB86;
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
}
@-webkit-keyframes pulseWarningIns {
  0% {
    background-color: #F8D486;
  }
  100% {
    background-color: #F8BB86;
  }
}
@keyframes pulseWarningIns {
  0% {
    background-color: #F8D486;
  }
  100% {
    background-color: #F8BB86;
  }
}
.overlay.overlay-coming-soon * {
  color: #727685;
}
.overlay.overlay-coming-soon h2 {
  font-size: 1.714em;
  color: #727685;
}

.markdown h2:first-of-type,
.markdown h4:first-of-type {
  font-weight: 400;
  text-align: center;
  margin-bottom: 10px;
  text-transform: inherit;
}
.markdown dl,
.markdown ol,
.markdown ul {
  margin-top: 1rem;
}
.markdown dl li,
.markdown ol li,
.markdown ul li {
  font-size: 0.938rem;
  list-style-type: disc;
  margin-bottom: 15px;
}

.article-body-image {
  border-radius: 8px;
  max-height: 525px;
  margin: 0 auto;
}

.mat-elevation-z0 {
  box-shadow: none !important;
}

.mat-elevation-z05 {
  box-shadow: 0 0px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 0px 0 rgba(0, 0, 0, 0.12);
}

.mat-bottom-sheet-container::-webkit-scrollbar-track,
.mat-drawer-inner-container::-webkit-scrollbar-track,
.mat-drawer-content::-webkit-scrollbar-track,
.mat-select-panel::-webkit-scrollbar-track,
.cdk-global-scrollblock::-webkit-scrollbar-track {
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;
}
.mat-bottom-sheet-container::-webkit-scrollbar-thumb,
.mat-drawer-inner-container::-webkit-scrollbar-thumb,
.mat-drawer-content::-webkit-scrollbar-thumb,
.mat-select-panel::-webkit-scrollbar-thumb,
.cdk-global-scrollblock::-webkit-scrollbar-thumb {
  border-radius: 1.429em;
  box-shadow: inset 0 0 0.429em rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.2);
}
.mat-bottom-sheet-container::-webkit-scrollbar,
.mat-drawer-inner-container::-webkit-scrollbar,
.mat-drawer-content::-webkit-scrollbar,
.mat-select-panel::-webkit-scrollbar,
.cdk-global-scrollblock::-webkit-scrollbar {
  height: 0.357em;
  width: 0.357em;
  background-color: transparent;
}

.mat-checkbox {
  background-color: transparent;
  font-family: Roboto, Arial, sans-serif;
}
.mat-checkbox .mat-checkbox-layout {
  word-break: break-word;
  white-space: normal;
}
.mat-checkbox .mat-checkbox-layout .mat-checkbox-label {
  line-height: 1.25em;
  font-weight: 400;
}
.mat-checkbox.checkbox-error {
  color: #c44a46;
}

.mat-form-field {
  font-weight: 400;
}
.mat-form-field .mat-form-field-prefix,
.mat-form-field .mat-form-field-suffix {
  padding-right: 0.357em;
}
.mat-form-field.mat-form-field_elevated-lg .mat-form-field-flex .mat-form-field-outline {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 0.357em;
}
.mat-form-field.mat-form-field_elevated-sm .mat-form-field-flex .mat-form-field-outline {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: 0.357em;
}
.mat-form-field.mat-hint_no_padding .mat-form-field-subscript-wrapper {
  padding: 0 !important;
}
.mat-form-field.mat-elevation-input-z2 .mat-form-field-outline {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  border-radius: 0.357em;
}
.mat-form-field.mat-form-field_no-space .mat-form-field-wrapper {
  margin: 0;
  padding-bottom: 0;
}

.mat-form-field .mat-form-field-suffix .tap-date-toggle {
  font-size: 1.142em;
  top: -2.143em;
}

.mat-form-field.success:not(.ng-invalid) .mat-form-field-outline-start,
.mat-form-field.success:not(.ng-invalid) .mat-form-field-outline-end, .mat-form-field.success:not(.ng-invalid).mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-outline-gap, .mat-form-field.success:not(.ng-invalid).mat-form-field-appearance-outline .mat-form-field-outline-gap, .mat-form-field.success:not(.ng-invalid).mat-form-field-outline-thick .mat-form-field-outline-gap {
  border-color: #228264;
  background: rgba(38, 145, 112, 0.045);
}
.mat-form-field.success:not(.ng-invalid).mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-outline-gap {
  border-top-color: transparent;
}
.mat-form-field.success:not(.ng-invalid).mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label, .mat-form-field.success:not(.ng-invalid).mat-focused.mat-primary .mat-select-arrow {
  color: #228264;
}
.mat-form-field.success:not(.ng-invalid).mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label .mat-form-field-required-marker, .mat-form-field.success:not(.ng-invalid).mat-focused.mat-primary .mat-select-arrow .mat-form-field-required-marker {
  color: #228264;
}
.mat-form-field.success:not(.ng-invalid).mat-form-field-disabled .mat-form-field-outline-start,
.mat-form-field.success:not(.ng-invalid).mat-form-field-disabled .mat-form-field-outline-end, .mat-form-field.success:not(.ng-invalid).mat-form-field-disabled.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-outline-gap, .mat-form-field.success:not(.ng-invalid).mat-form-field-disabled.mat-form-field-appearance-outline .mat-form-field-outline-gap, .mat-form-field.success:not(.ng-invalid).mat-form-field-disabled.mat-form-field-outline-thick .mat-form-field-outline-gap {
  border-color: #777777;
  background: rgba(77, 77, 77, 0.045);
}
.mat-form-field.success:not(.ng-invalid).mat-form-field-disabled.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-outline-gap {
  border-top-color: transparent;
}
.mat-form-field.success:not(.ng-invalid).mat-form-field-disabled.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label, .mat-form-field.success:not(.ng-invalid).mat-form-field-disabled.mat-focused.mat-primary .mat-select-arrow {
  color: #777777;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple {
  background-color: #c44a46;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #c44a46;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #c44a46;
}
.mat-form-field.mat-form-field-invalid .mat-error {
  color: #c44a46;
}
.mat-form-field.mat-form-field_sm.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label, .mat-form-field.mat-form-field_sm.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.49375em) scale(0.75);
}
.mat-form-field.mat-form-field_sm .mat-form-field-wrapper {
  padding-bottom: 1.14375em;
}
.mat-form-field.mat-form-field_sm.mat-form-field_no-space .mat-form-field-wrapper {
  margin: 0;
  padding-bottom: 0;
}
.mat-form-field.mat-form-field_sm .mat-form-field-infix {
  padding: 0.607em 0;
}
.mat-form-field.mat-form-field_sm .mat-form-field-prefix,
.mat-form-field.mat-form-field_sm .mat-form-field-suffix {
  top: 9px;
}
.mat-form-field.mat-form-field_sm .mat-form-field-label {
  top: 1.6em;
}
@media screen and (max-width: 599px) {
  .mat-form-field.mat-form-field_sm .mat-form-field-prefix {
    top: 0;
  }
}
.mat-form-field.mat-form-field_sm .mat-form-field-prefix .mat-icon {
  font-size: 120%;
  line-height: 1;
}
@media screen and (max-width: 599px) {
  .mat-form-field.mat-form-field_sm {
    top: 0;
  }
}
.mat-form-field.mat-form-field_sm .mat-select {
  position: relative;
  top: -0.54375em;
}
.mat-form-field.mat-form-field_sm .mat-select .mat-select-arrow-wrapper {
  transform: translateY(0%);
}
.mat-form-field.mat-form-field_sm.mat-form-field_sm-select .mat-form-field-infix {
  padding: 0.786em 0 0.429em 0;
}
.mat-form-field.mat-form-field_sm.mat-form-field_sm-select .mat-form-field-infix .mat-select {
  top: -0.14375em;
}
.mat-form-field.mat-form-field_sm .mat-standard-chip {
  margin: 0.143em 0.286em;
}

.mat-input-infix {
  font-size: 0.938rem;
}

.mat-focused .mat-input-infix {
  font-size: 0.938rem;
}

.mat-form-field {
  color: #43454e;
  font-size: 0.938rem;
}
.mat-form-field.no-prefix-padding .mat-form-field-prefix {
  padding: 0;
}
.mat-form-field.mat-form-field-appearance-outline .mat-form-field-prefix,
.mat-form-field.mat-form-field-appearance-outline .mat-form-field-suffix {
  top: 2px;
}
.mat-form-field.mat-form-field-appearance-outline .mat-form-field-prefix .mat-datepicker-toggle,
.mat-form-field.mat-form-field-appearance-outline .mat-form-field-prefix .mat-datepicker-content .mat-calendar-next-button,
.mat-form-field.mat-form-field-appearance-outline .mat-form-field-prefix .mat-datepicker-content .mat-calendar-previous-button,
.mat-form-field.mat-form-field-appearance-outline .mat-form-field-suffix .mat-datepicker-toggle,
.mat-form-field.mat-form-field-appearance-outline .mat-form-field-suffix .mat-datepicker-content .mat-calendar-next-button,
.mat-form-field.mat-form-field-appearance-outline .mat-form-field-suffix .mat-datepicker-content .mat-calendar-previous-button {
  color: #43454e;
}
.mat-form-field .mat-input-element,
.mat-form-field .mat-select-value-text,
.mat-form-field .mat-select-placeholder,
.mat-form-field .mat-form-field-label,
.mat-form-field .mat-form-field-infix {
  font-size: 0.938rem;
  font-weight: 400;
  line-height: 1.286em;
  caret-color: currentColor;
  color: currentColor;
}
@media screen and (max-width: 599px) {
  .mat-form-field .mat-input-element,
.mat-form-field .mat-select-value-text {
    font-size: 0.938rem;
    font-weight: 400;
  }
}
@media screen and (max-width: 991px) {
  .mat-form-field .mat-input-element:disabled {
    color: rgba(0, 0, 0, 0.68);
    /* required on iOS */
    -webkit-text-fill-color: #43454e;
    opacity: 1;
  }
}

.mat-autocomplete-panel .mat-option {
  font-size: 0.938rem;
}

.mat-form-field.searchbar {
  width: 100%;
}
.mat-form-field.searchbar .mat-form-field-wrapper {
  padding-bottom: 0 !important;
}

.mat-progress-bar {
  border-radius: 8px;
  min-height: 0.375rem;
}
.mat-progress-bar .mat-progress-bar .mat-progress-bar-element {
  height: 10px;
  border-radius: 8px;
}
.mat-progress-bar svg.mat-progress-bar-background {
  background-repeat: repeat-x !important;
  background-size: 0.714em 0.286em !important;
  -webkit-animation: none !important;
  animation: none !important;
}
.mat-progress-bar svg.mat-progress-bar-background defs,
.mat-progress-bar svg.mat-progress-bar-background pattern {
  display: none !important;
}
.mat-progress-bar.loading-bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  min-height: 0.286em;
  z-index: 10;
}
.mat-progress-bar.mat-progress-bar_colored {
  border-radius: 0.571rem;
}
.mat-progress-bar.mat-progress-bar_colored .mat-progress-bar-buffer {
  background-color: transparent;
  border: 0.071rem solid rgba(0, 0, 0, 0.24);
  border-radius: 0.571rem;
}
.mat-progress-bar.mat-progress-bar_colored:nth-of-type(1) .mat-progress-bar-fill::after {
  background: linear-gradient(to right, #aa99ff, #e09cff);
  background: linear-gradient(to left, #00706c, #99c5c4);
}
.mat-progress-bar.mat-progress-bar_colored:nth-of-type(2) .mat-progress-bar-fill::after {
  background: linear-gradient(to right, #aa99ff, #e09cff);
  background: linear-gradient(to right, #b452ff, #6426e0);
}
.mat-progress-bar.mat-success .mat-progress-bar-fill::after {
  background-color: #4e9b83 !important;
}

.mat-card .mat-progress-bar.loading-bar {
  border-radius: 4px 4px 0 0;
}

.stepper {
  position: relative;
}
.stepper .mat-horizontal-content-container .mat-horizontal-stepper-content {
  transition-duration: 0.25s;
}
.stepper .mat-horizontal-stepper-header-container {
  background: #ffffff;
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  z-index: 500;
  display: flex;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 0;
  margin-left: 0;
  list-style: none;
}
.stepper .mat-horizontal-stepper-header-container .mat-horizontal-stepper-header {
  height: 1.143em;
  padding: 0;
}
.stepper .mat-horizontal-stepper-header-container .mat-horizontal-stepper-header .mat-step-icon,
.stepper .mat-horizontal-stepper-header-container .mat-horizontal-stepper-header .mat-step-icon-not-touched {
  display: none;
}
.stepper .mat-horizontal-stepper-header-container .mat-horizontal-stepper-header .mat-step-label {
  width: 2.143em;
  height: 0.214em;
  margin-right: 0.214em;
  margin-left: 0.214em;
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0.46);
}
.stepper .mat-horizontal-stepper-header-container .mat-horizontal-stepper-header .mat-step-label.mat-step-label-selected {
  background-color: rgba(0, 0, 0, 0.72);
}
.stepper .mat-horizontal-stepper-header-container .mat-stepper-horizontal-line {
  display: none;
}
.stepper .mat-horizontal-content-container {
  overflow: hidden;
  padding: 0;
}
@media screen and (max-width: 599px) {
  .stepper.no-mobile-indicators .mat-horizontal-stepper-header-container {
    margin-top: 0;
  }
  .stepper.no-mobile-indicators .mat-horizontal-stepper-header-container .mat-horizontal-stepper-header {
    display: none;
  }
  .stepper.no-mobile-indicators .modal-body {
    padding-top: 1.071rem;
  }
}
.stepper .mat-stepper-horizontal.mat-dialog_color-black {
  background-color: #333C44 !important;
}
.stepper .mat-stepper-horizontal.mat-dialog_color-black h2,
.stepper .mat-stepper-horizontal.mat-dialog_color-black h4,
.stepper .mat-stepper-horizontal.mat-dialog_color-black p,
.stepper .mat-stepper-horizontal.mat-dialog_color-black .mat-button {
  color: #e5e5e5;
}
.stepper .mat-stepper-horizontal.mat-dialog_color-black .mat-raised-button {
  color: #333C44;
}
.stepper .mat-stepper-horizontal.mat-dialog_color-black .mat-horizontal-stepper-header-container,
.stepper .mat-stepper-horizontal.mat-dialog_color-black .mat-dialog-content,
.stepper .mat-stepper-horizontal.mat-dialog_color-black .mat-dialog-actions {
  background: transparent !important;
}
.stepper .mat-stepper-horizontal.mat-dialog_color-0 {
  background: #eebd2a !important;
}
.stepper .mat-stepper-horizontal.mat-dialog_color-0 * {
  color: #56481c;
}
.stepper .mat-stepper-horizontal.mat-dialog_color-0 .mat-horizontal-stepper-header-container,
.stepper .mat-stepper-horizontal.mat-dialog_color-0 .mat-dialog-content,
.stepper .mat-stepper-horizontal.mat-dialog_color-0 .mat-dialog-actions {
  background: transparent !important;
}
.stepper .mat-stepper-horizontal.mat-dialog_color-1 {
  background: #5456a2 !important;
}
.stepper .mat-stepper-horizontal.mat-dialog_color-1 h4,
.stepper .mat-stepper-horizontal.mat-dialog_color-1 p,
.stepper .mat-stepper-horizontal.mat-dialog_color-1 .mat-button {
  color: #ebecff;
}
.stepper .mat-stepper-horizontal.mat-dialog_color-1 .mat-raised-button {
  color: #5456a2;
}
.stepper .mat-stepper-horizontal.mat-dialog_color-1 .mat-horizontal-stepper-header-container,
.stepper .mat-stepper-horizontal.mat-dialog_color-1 .mat-dialog-content,
.stepper .mat-stepper-horizontal.mat-dialog_color-1 .mat-dialog-actions {
  background: transparent !important;
}
.stepper .mat-stepper-horizontal.mat-dialog_color-2 {
  background: #b3d0ff !important;
}
.stepper .mat-stepper-horizontal.mat-dialog_color-2 * {
  color: #2c4c81;
}
.stepper .mat-stepper-horizontal.mat-dialog_color-2 .mat-horizontal-stepper-header-container,
.stepper .mat-stepper-horizontal.mat-dialog_color-2 .mat-dialog-content,
.stepper .mat-stepper-horizontal.mat-dialog_color-2 .mat-dialog-actions {
  background: transparent !important;
}
.stepper .mat-stepper-horizontal.mat-dialog_color-3 {
  background: #b3d0ff !important;
}
.stepper .mat-stepper-horizontal.mat-dialog_color-3 * {
  color: #2c4c81;
}
.stepper .mat-stepper-horizontal.mat-dialog_color-3 .mat-horizontal-stepper-header-container,
.stepper .mat-stepper-horizontal.mat-dialog_color-3 .mat-dialog-content,
.stepper .mat-stepper-horizontal.mat-dialog_color-3 .mat-dialog-actions {
  background: transparent !important;
}

.no-indicators .mat-horizontal-stepper-header-container {
  margin-top: 0;
}
.no-indicators .mat-horizontal-stepper-header-container .mat-horizontal-stepper-header {
  display: none;
}

@media screen and (max-width: 599px) {
  .mat-horizontal-content-container_no_padding_sm .mat-horizontal-content-container {
    padding: 0;
  }
}

.account-selection .mat-stepper-horizontal-line {
  border-top-width: 0;
}
.account-selection .mat-horizontal-content-container {
  padding-bottom: 0;
}

.mat-stepper-horizontal,
.mat-stepper-vertical {
  background: transparent !important;
}

.account-progress_body .mat-vertical-content {
  padding: 0 0em 1.714em 1.714em;
}

.cdk-overlay-container {
  z-index: 2147483640;
}
.cdk-overlay-container .cdk-global-overlay-wrapper .cdk-overlay-pane {
  /* smartphones, touchscreens */
}
@media (max-width: 575px) {
  .cdk-overlay-container .cdk-global-overlay-wrapper .cdk-overlay-pane {
    min-width: 95vw;
  }
}
@media (hover: none) and (pointer: coarse) {
  .cdk-overlay-container .cdk-global-overlay-wrapper .cdk-overlay-pane .mat-bottom-sheet-container {
    min-height: -webkit-fill-available;
  }
}
@media screen and (max-width: 1023px) {
  .cdk-overlay-container .cdk-global-overlay-wrapper .cdk-overlay-pane .mat-bottom-sheet-container {
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;
  }
}
@media (max-width: 575px) {
  .cdk-overlay-container .cdk-global-overlay-wrapper .cdk-overlay-pane .mat-bottom-sheet-container {
    min-width: 95vw;
    min-height: -webkit-fill-available;
  }
}
.cdk-overlay-container .mat-drawer-container,
.cdk-overlay-container .mat-dialog-container {
  padding: 0;
  background-color: #ffffff;
}
.cdk-overlay-container .mat-drawer-container .mat-dialog-header,
.cdk-overlay-container .mat-dialog-container .mat-dialog-header {
  padding-left: 1.071rem;
  background-color: #ffffff;
}
.cdk-overlay-container .mat-drawer-container .mat-dialog-header h4,
.cdk-overlay-container .mat-dialog-container .mat-dialog-header h4 {
  margin-bottom: 0;
}
.cdk-overlay-container .mat-drawer-container .mat-drawer-content,
.cdk-overlay-container .mat-drawer-container .mat-dialog-content,
.cdk-overlay-container .mat-dialog-container .mat-drawer-content,
.cdk-overlay-container .mat-dialog-container .mat-dialog-content {
  background-color: #ffffff;
  touch-action: manipulation;
}
.cdk-overlay-container .mat-drawer-container .mat-drawer-content::-webkit-scrollbar-track,
.cdk-overlay-container .mat-drawer-container .mat-dialog-content::-webkit-scrollbar-track,
.cdk-overlay-container .mat-dialog-container .mat-drawer-content::-webkit-scrollbar-track,
.cdk-overlay-container .mat-dialog-container .mat-dialog-content::-webkit-scrollbar-track {
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;
}
.cdk-overlay-container .mat-drawer-container .mat-drawer-content::-webkit-scrollbar-thumb,
.cdk-overlay-container .mat-drawer-container .mat-dialog-content::-webkit-scrollbar-thumb,
.cdk-overlay-container .mat-dialog-container .mat-drawer-content::-webkit-scrollbar-thumb,
.cdk-overlay-container .mat-dialog-container .mat-dialog-content::-webkit-scrollbar-thumb {
  border-radius: 1.429em;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.2);
}
.cdk-overlay-container .mat-drawer-container .mat-drawer-content::-webkit-scrollbar,
.cdk-overlay-container .mat-drawer-container .mat-dialog-content::-webkit-scrollbar,
.cdk-overlay-container .mat-dialog-container .mat-drawer-content::-webkit-scrollbar,
.cdk-overlay-container .mat-dialog-container .mat-dialog-content::-webkit-scrollbar {
  height: 0.357em;
  width: 0.357em;
  background-color: transparent;
}
.cdk-overlay-container .mat-drawer-container .mat-dialog-actions,
.cdk-overlay-container .mat-dialog-container .mat-dialog-actions {
  padding: 0.571rem 1.071rem;
  margin-bottom: 0;
  background-color: #ffffff;
}
.cdk-overlay-container .mat-drawer-container .mat-button-toggle-group,
.cdk-overlay-container .mat-dialog-container .mat-button-toggle-group {
  font-size: 90%;
  font-weight: 500;
}
.cdk-overlay-container .grid-container_modal .drawer-list h2 {
  top: -1.714em !important;
  z-index: 1;
}

.modal-header_title .mat-form-field,
.invoice-header_title .mat-form-field {
  font-size: 1rem;
}
.modal-header_title .mat-form-field:not(.mat-form-field-hide-placeholder) .mat-input-element::-webkit-input-placeholder,
.invoice-header_title .mat-form-field:not(.mat-form-field-hide-placeholder) .mat-input-element::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.76) !important;
}
.modal-header_title .mat-form-field:not(.mat-form-field-hide-placeholder) .mat-input-element:-ms-input-placeholder,
.invoice-header_title .mat-form-field:not(.mat-form-field-hide-placeholder) .mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.76) !important;
}
.modal-header_title .mat-form-field:not(.mat-form-field-hide-placeholder) .mat-input-element::-ms-input-placeholder,
.invoice-header_title .mat-form-field:not(.mat-form-field-hide-placeholder) .mat-input-element::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.76) !important;
}
.modal-header_title .mat-form-field:not(.mat-form-field-hide-placeholder) .mat-input-element::-moz-placeholder,
.invoice-header_title .mat-form-field:not(.mat-form-field-hide-placeholder) .mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.76) !important;
}
.modal-header_title .mat-form-field:not(.mat-form-field-hide-placeholder) .mat-input-element::placeholder,
.invoice-header_title .mat-form-field:not(.mat-form-field-hide-placeholder) .mat-input-element::placeholder {
  color: rgba(255, 255, 255, 0.76) !important;
}
.modal-header_title .mat-form-field:not(.mat-form-field-hide-placeholder) .mat-input-element:-ms-input-placeholder,
.invoice-header_title .mat-form-field:not(.mat-form-field-hide-placeholder) .mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.76) !important;
}
.modal-header_title .mat-form-field:not(.mat-form-field-hide-placeholder) .mat-input-element::-moz-placeholder,
.invoice-header_title .mat-form-field:not(.mat-form-field-hide-placeholder) .mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.76) !important;
}
.modal-header_title .mat-form-field:not(.mat-form-field-hide-placeholder) .mat-input-element:-moz-placeholder,
.invoice-header_title .mat-form-field:not(.mat-form-field-hide-placeholder) .mat-input-element:-moz-placeholder {
  color: rgba(255, 255, 255, 0.76) !important;
}
.modal-header_title .mat-form-field:not(.mat-form-field-hide-placeholder) .mat-input-element::-webkit-input-placeholder,
.invoice-header_title .mat-form-field:not(.mat-form-field-hide-placeholder) .mat-input-element::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.76) !important;
}
.modal-header_title .mat-form-field .mat-input-element,
.modal-header_title .mat-form-field .mat-select-element,
.invoice-header_title .mat-form-field .mat-input-element,
.invoice-header_title .mat-form-field .mat-select-element {
  caret-color: #fff;
}
.modal-header_title .mat-form-field .mat-form-field-underline,
.invoice-header_title .mat-form-field .mat-form-field-underline {
  background-color: #fff;
}
.modal-header_title .mat-form-field.mat-focused .mat-form-field-label .mat-form-field-required-marker,
.invoice-header_title .mat-form-field.mat-focused .mat-form-field-label .mat-form-field-required-marker {
  color: #fff;
}
.modal-header_title .mat-form-field .mat-form-field-label,
.modal-header_title .mat-form-field .mat-select-subscript-wrapper,
.invoice-header_title .mat-form-field .mat-form-field-label,
.invoice-header_title .mat-form-field .mat-select-subscript-wrapper {
  color: #fff;
  font-size: 1.071rem;
}
@media screen and (max-width: 599px) {
  .modal-header_title .mat-form-field .mat-form-field-label,
.modal-header_title .mat-form-field .mat-select-subscript-wrapper,
.invoice-header_title .mat-form-field .mat-form-field-label,
.invoice-header_title .mat-form-field .mat-select-subscript-wrapper {
    font-size: 1rem;
  }
}
.modal-header_title .mat-form-field .mat-select-trigger .mat-select-arrow,
.modal-header_title .mat-form-field .mat-select-trigger .mat-select-value,
.modal-header_title .mat-form-field .mat-form-field.mat-focused.mat-primary .mat-select-arrow,
.modal-header_title .mat-form-field .mat-form-field.mat-focused.mat-primary .mat-select-value,
.invoice-header_title .mat-form-field .mat-select-trigger .mat-select-arrow,
.invoice-header_title .mat-form-field .mat-select-trigger .mat-select-value,
.invoice-header_title .mat-form-field .mat-form-field.mat-focused.mat-primary .mat-select-arrow,
.invoice-header_title .mat-form-field .mat-form-field.mat-focused.mat-primary .mat-select-value {
  color: #fff;
}
.modal-header_title .mat-form-field .mat-select-trigger .mat-select-ripple,
.modal-header_title .mat-form-field .mat-form-field.mat-focused.mat-primary .mat-select-ripple,
.invoice-header_title .mat-form-field .mat-select-trigger .mat-select-ripple,
.invoice-header_title .mat-form-field .mat-form-field.mat-focused.mat-primary .mat-select-ripple {
  background-color: #fff;
}
.modal-header_title .mat-form-field.mat-form-field-appearance-outline .mat-form-field-outline,
.invoice-header_title .mat-form-field.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.48);
}
.modal-header_title .mat-form-field.mat-form-field-appearance-outline .mat-form-field-outline-thick,
.invoice-header_title .mat-form-field.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: #fff;
}
.modal-header_title .mat-form-field.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick,
.invoice-header_title .mat-form-field.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #681310;
}
.modal-header_title .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.invoice-header_title .mat-form-field.mat-form-field-invalid .mat-form-field-ripple {
  background-color: #681310;
}
.modal-header_title .mat-form-field.mat-form-field-invalid .mat-form-field-label,
.invoice-header_title .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #681310;
}
.modal-header_title .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker,
.invoice-header_title .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #681310;
}
.modal-header_title .mat-form-field.mat-form-field-invalid .mat-error,
.invoice-header_title .mat-form-field.mat-form-field-invalid .mat-error {
  background: #fff;
  color: #681310;
  padding: 0.143em 0.214em;
  font-size: 1.3em;
  border-radius: 4px;
}
.modal-header_title .mat-form-field.mat-focused .mat-form-field-label,
.modal-header_title .mat-form-field.mat-focused .mat-select-arrow,
.modal-header_title .mat-form-field.mat-focused .mat-select-value,
.invoice-header_title .mat-form-field.mat-focused .mat-form-field-label,
.invoice-header_title .mat-form-field.mat-focused .mat-select-arrow,
.invoice-header_title .mat-form-field.mat-focused .mat-select-value {
  color: #fff;
}
.modal-header_title .mat-form-field.mat-focused .mat-form-field-ripple,
.modal-header_title .mat-form-field.mat-focused .mat-select-ripple,
.invoice-header_title .mat-form-field.mat-focused .mat-form-field-ripple,
.invoice-header_title .mat-form-field.mat-focused .mat-select-ripple {
  background-color: #fff;
}
.modal-header_title .mat-form-field .mat-input-element:disabled,
.invoice-header_title .mat-form-field .mat-input-element:disabled {
  color: rgba(255, 255, 255, 0.78);
}

.checkout-radio .mat-radio-label {
  width: 100%;
}
.checkout-radio .mat-radio-label .mat-radio-label-content {
  width: 100%;
}
.checkout-radio .mat-radio-label .mat-radio-label-content h4.payAmount {
  text-align: right;
  white-space: normal;
}
.checkout-radio .mat-radio-label .mat-radio-label-content h4.payAmount small {
  width: 100%;
  text-align: right;
}
.checkout-radio .mat-radio-label .mat-radio-label-content h4 .payAmount {
  text-align: right;
  white-space: normal;
}
.checkout-radio .mat-radio-label .mat-radio-label-content p {
  white-space: normal;
}
.checkout-radio .mat-card-radio {
  margin-bottom: 0.714em;
}
.checkout-radio .mat-card-radio .mat-radio-label {
  padding: 1.143em;
  border-radius: 4px;
  background: white;
  box-shadow: none;
}
.checkout-radio .mat-card-radio .mat-radio-label:hover, .checkout-radio .mat-card-radio .mat-radio-label:focus {
  background-color: rgba(0, 0, 0, 0.02);
}
.checkout-radio .mat-card-radio.mat-radio-checked .mat-radio-label {
  border: 1px solid rgba(0, 0, 0, 0.18);
  border-radius: 8px;
}
.checkout-radio .mat-card-radio.err .mat-radio-label {
  border: 0.143em solid #dfb2b0;
}
.checkout-radio .mat-card-radio.mat-card-radio_hide-circle .mat-radio-container {
  display: none;
}

.mat-radio_wrap_label .mat-radio-label-content {
  white-space: normal;
}

.mat-radio-group_vertical .mat-radio-label {
  flex-direction: column;
  width: 100%;
}
.mat-radio-group_vertical .mat-radio-label .mat-radio-container {
  margin-bottom: 0.5em;
}
.mat-radio-group_vertical .mat-radio-label-content {
  padding-left: 0;
  width: calc(100% - 1.071rem);
}

.material-icons.mat-warn,
.material-icons-outlined.mat-warn,
.material-icons-two-tone.mat-warn,
.material-icons-round.mat-warn,
.material-icons-sharp.mat-warn {
  color: #BF2D29;
}
.material-icons.mat-success,
.material-icons-outlined.mat-success,
.material-icons-two-tone.mat-success,
.material-icons-round.mat-success,
.material-icons-sharp.mat-success {
  color: #228264;
}
.material-icons.Started,
.material-icons-outlined.Started,
.material-icons-two-tone.Started,
.material-icons-round.Started,
.material-icons-sharp.Started {
  color: #FFB38B;
}
.material-icons.In.Underwriting,
.material-icons-outlined.In.Underwriting,
.material-icons-two-tone.In.Underwriting,
.material-icons-round.In.Underwriting,
.material-icons-sharp.In.Underwriting {
  color: #DAD8A7;
}
.material-icons.Items.Needed, .material-iconsNeeded,
.material-icons-outlined.Items.Needed,
.material-icons-outlinedNeeded,
.material-icons-two-tone.Items.Needed,
.material-icons-two-toneNeeded,
.material-icons-round.Items.Needed,
.material-icons-roundNeeded,
.material-icons-sharp.Items.Needed,
.material-icons-sharpNeeded {
  color: #FF5B4A;
}
.material-icons.Ready.To.Close,
.material-icons-outlined.Ready.To.Close,
.material-icons-two-tone.Ready.To.Close,
.material-icons-round.Ready.To.Close,
.material-icons-sharp.Ready.To.Close {
  color: #7FC7AF;
}
.material-icons.Disabled, .material-icons.Archived,
.material-icons-outlined.Disabled,
.material-icons-outlined.Archived,
.material-icons-two-tone.Disabled,
.material-icons-two-tone.Archived,
.material-icons-round.Disabled,
.material-icons-round.Archived,
.material-icons-sharp.Disabled,
.material-icons-sharp.Archived {
  color: #a1a2a6;
}
.material-icons.Funded,
.material-icons-outlined.Funded,
.material-icons-two-tone.Funded,
.material-icons-round.Funded,
.material-icons-sharp.Funded {
  color: #3FB8AF;
}
.material-icons.mat-single-align,
.material-icons-outlined.mat-single-align,
.material-icons-two-tone.mat-single-align,
.material-icons-round.mat-single-align,
.material-icons-sharp.mat-single-align {
  position: relative;
  top: 0.357em;
}
.material-icons.mat-1rem,
.material-icons-outlined.mat-1rem,
.material-icons-two-tone.mat-1rem,
.material-icons-round.mat-1rem,
.material-icons-sharp.mat-1rem {
  font-size: 1rem !important;
  height: 1rem !important;
  width: 1rem !important;
}
.material-icons.mat-14,
.material-icons-outlined.mat-14,
.material-icons-two-tone.mat-14,
.material-icons-round.mat-14,
.material-icons-sharp.mat-14 {
  font-size: 14px !important;
  height: 14px !important;
  width: 14px !important;
}
.material-icons.mat-16,
.material-icons-outlined.mat-16,
.material-icons-two-tone.mat-16,
.material-icons-round.mat-16,
.material-icons-sharp.mat-16 {
  font-size: 16px !important;
  height: 16px !important;
  width: 16px !important;
}
.material-icons.mat-18,
.material-icons-outlined.mat-18,
.material-icons-two-tone.mat-18,
.material-icons-round.mat-18,
.material-icons-sharp.mat-18 {
  font-size: 18px !important;
  height: 18px !important;
  width: 18px !important;
}
.material-icons.mat-24,
.material-icons-outlined.mat-24,
.material-icons-two-tone.mat-24,
.material-icons-round.mat-24,
.material-icons-sharp.mat-24 {
  font-size: 24px !important;
  height: 24px !important;
  width: 24px !important;
}
.material-icons.mat-30,
.material-icons-outlined.mat-30,
.material-icons-two-tone.mat-30,
.material-icons-round.mat-30,
.material-icons-sharp.mat-30 {
  font-size: 30px !important;
  height: 30px !important;
  width: 30px !important;
}
.material-icons.mat-36,
.material-icons-outlined.mat-36,
.material-icons-two-tone.mat-36,
.material-icons-round.mat-36,
.material-icons-sharp.mat-36 {
  font-size: 36px !important;
  height: 36px !important;
  width: 36px !important;
}
.material-icons.mat-48,
.material-icons-outlined.mat-48,
.material-icons-two-tone.mat-48,
.material-icons-round.mat-48,
.material-icons-sharp.mat-48 {
  font-size: 48px !important;
  height: 48px !important;
  width: 48px !important;
}
.material-icons.mat-64,
.material-icons-outlined.mat-64,
.material-icons-two-tone.mat-64,
.material-icons-round.mat-64,
.material-icons-sharp.mat-64 {
  font-size: 64px !important;
  width: 64px !important;
  height: 64px !important;
}
.material-icons.tooltip-helper,
.material-icons-outlined.tooltip-helper,
.material-icons-two-tone.tooltip-helper,
.material-icons-round.tooltip-helper,
.material-icons-sharp.tooltip-helper {
  font-size: 22px;
  width: 22px;
  height: 22px;
  position: relative;
  top: 0.286em;
}
.material-icons.tooltip-helper.mat-18,
.material-icons-outlined.tooltip-helper.mat-18,
.material-icons-two-tone.tooltip-helper.mat-18,
.material-icons-round.tooltip-helper.mat-18,
.material-icons-sharp.tooltip-helper.mat-18 {
  font-size: 1.286em;
  width: 1.286em;
}
.material-icons.lock,
.material-icons-outlined.lock,
.material-icons-two-tone.lock,
.material-icons-round.lock,
.material-icons-sharp.lock {
  position: relative;
  top: 0.286em;
  margin-right: 0.357em;
}
.material-icons.mat-card-floating-icon,
.material-icons-outlined.mat-card-floating-icon,
.material-icons-two-tone.mat-card-floating-icon,
.material-icons-round.mat-card-floating-icon,
.material-icons-sharp.mat-card-floating-icon {
  position: absolute;
  font-size: 34px;
  height: 66px;
  width: 66px;
  top: -24px;
  border-radius: 36px;
  padding: 16px;
  background: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  color: #43454e;
}
.mat-expansion-panel .mat-expansion-panel-content,
.mat-expansion-panel .mat-expansion-panel-body {
  width: 100%;
}

@media (max-width: 450px) {
  mat-expansion-panel .mat-expansion-panel-header {
    padding: 0 1.071rem 0 1.071rem;
  }

  mat-expansion-panel .mat-expansion-panel-body {
    padding: 0 0.714em 1.143em;
  }
}
.no-padding-expansion .mat-expansion-panel-body {
  padding: 0;
}

.mat-expansion-panel-header {
  font-size: 1rem;
  align-items: center;
}
.mat-expansion-panel-header .mat-expansion-panel-header-description,
.mat-expansion-panel-header .mat-expansion-panel-header-title,
.mat-expansion-panel-header .mat-expansion-indicator::after {
  font-size: 1rem;
  color: #43454e;
}

.nav-expansion-accordion {
  z-index: 2;
}
.nav-expansion-accordion .mat-expansion-panel {
  border-radius: 0 !important;
  box-shadow: none;
}
.nav-expansion-accordion .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header {
  max-height: 2.557em;
}
@media screen and (min-width: 1921px) {
  .nav-expansion-accordion .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header {
    max-height: 2.818em;
  }
}
@media screen and (max-width: 599px) {
  .nav-expansion-accordion .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header {
    max-height: 2.839em;
  }
}
.nav-expansion-accordion .mat-expansion-panel.mat-expanded .mat-expansion-panel-header {
  max-height: 2.788em;
  border-right: none;
  font-weight: 600;
  margin-right: 7px;
  border-radius: 0 18px 18px 0;
}
.nav-expansion-accordion .mat-expansion-panel .mat-expansion-panel-header {
  padding: 0 1.071rem 0 0;
}
.nav-expansion-accordion .mat-expansion-panel .mat-expansion-panel-header .mat-button-focus-overlay {
  background: transparent;
}
.nav-expansion-accordion .mat-expansion-panel .mat-expansion-panel-header .mat-expansion-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-expansion-accordion .mat-expansion-panel .mat-expansion-panel-header:hover a, .nav-expansion-accordion .mat-expansion-panel .mat-expansion-panel-header:focus a {
  color: #43454e;
}
.nav-expansion-accordion .mat-expansion-panel .mat-expansion-panel-content .mat-expansion-panel-body {
  padding: 0.357em 0;
}
.nav-expansion-accordion .mat-expansion-panel .mat-expansion-panel-content .mat-expansion-panel-body li {
  min-height: 2.429em;
}
.nav-expansion-accordion .mat-expansion-panel .mat-expansion-panel-content .mat-expansion-panel-body li a {
  padding: 0 1.143em;
  padding-left: 1.786em;
}

.nav-expansion-accordion .mat-expansion-panel {
  background: #ffffff;
}
.nav-expansion-accordion.last .mat-expansion-panel {
  border-radius: 0.571rem !important;
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
  padding-bottom: 0.143em;
  box-shadow: none;
}

.mat-accordion_no-padding .mat-expansion-panel-body {
  padding: 1.143em 0;
}

.mat-accordion_no-padding-header .mat-expansion-panel-header {
  padding: 0px 5px;
}

.mat-tooltip {
  font-size: 0.929em;
  padding-top: 0.429em;
  padding-bottom: 0.429em;
  background: #43454e !important;
  opacity: 1;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-menu-panel.mat-menu-panel_lg {
  min-width: 220px;
  max-width: 400px;
}
@media screen and (max-width: 420px) {
  .mat-menu-panel.mat-menu-panel_lg {
    max-width: 95vw;
  }
}
.mat-menu-panel .mat-menu-content a:hover {
  text-decoration: none;
}
.mat-menu-panel .mat-menu-content .mat-menu-item {
  font-size: 0.938rem;
  line-height: 3.429em;
  height: 3.429em;
}
.mat-menu-panel .mat-menu-content .mat-menu-item span {
  color: #43454e;
}
.mat-menu-panel .mat-menu-content .mat-menu-item .mat-icon-no-color,
.mat-menu-panel .mat-menu-content .mat-menu-item-submenu-trigger::after {
  color: rgba(0, 0, 0, 0.87);
}

.mat-snack-bar-container {
  height: auto;
  background: #6D9E60 !important;
  background: linear-gradient(to right, #228264, #4E9B83) !important;
  color: #fff !important;
  font-size: 14px;
  line-height: 20px;
}
.mat-snack-bar-container .mat-simple-snackbar-action {
  height: auto;
  color: #fff !important;
}
.mat-snack-bar-container.info {
  background: #19212b !important;
  color: #fafafa;
}
.mat-snack-bar-container.info button {
  color: #8ab4f8;
}
.mat-snack-bar-container.info button:hover {
  background: rgba(138, 180, 248, 0.12) !important;
}
.mat-snack-bar-container.warning {
  background: #f37032 !important;
}
.mat-snack-bar-container.error {
  background: #BF2D29 !important;
}
.mat-snack-bar-container .mat-progress-bar-background {
  top: 0.375em;
  -webkit-animation-play-state: paused !important;
  animation-play-state: paused !important;
}

.mat-stroked-button:not(.mat-button-disabled).mat-primary {
  border-color: #92344f;
}

.mat-button,
.mat-raised-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button,
.mat-fab,
.mat-mini-fab {
  font-size: 1rem;
  font-size: 16px;
}
.mat-button.mat-gradient,
.mat-raised-button.mat-gradient,
.mat-icon-button.mat-gradient,
.mat-stroked-button.mat-gradient,
.mat-flat-button.mat-gradient,
.mat-fab.mat-gradient,
.mat-mini-fab.mat-gradient {
  color: #ffffff;
  background: #396ea5;
  background: linear-gradient(to bottom right, #396ea5 0%, #7890c7);
}
.mat-button.mat-gradient:hover, .mat-button.mat-gradient:focus,
.mat-raised-button.mat-gradient:hover,
.mat-raised-button.mat-gradient:focus,
.mat-icon-button.mat-gradient:hover,
.mat-icon-button.mat-gradient:focus,
.mat-stroked-button.mat-gradient:hover,
.mat-stroked-button.mat-gradient:focus,
.mat-flat-button.mat-gradient:hover,
.mat-flat-button.mat-gradient:focus,
.mat-fab.mat-gradient:hover,
.mat-fab.mat-gradient:focus,
.mat-mini-fab.mat-gradient:hover,
.mat-mini-fab.mat-gradient:focus {
  background: linear-gradient(to bottom right, #326192, #407bb8);
}
.mat-button.mat-gradient .mat-icon,
.mat-raised-button.mat-gradient .mat-icon,
.mat-icon-button.mat-gradient .mat-icon,
.mat-stroked-button.mat-gradient .mat-icon,
.mat-flat-button.mat-gradient .mat-icon,
.mat-fab.mat-gradient .mat-icon,
.mat-mini-fab.mat-gradient .mat-icon {
  position: relative;
  top: 0.143em;
}
.mat-button.mat-gradient:disabled,
.mat-raised-button.mat-gradient:disabled,
.mat-icon-button.mat-gradient:disabled,
.mat-stroked-button.mat-gradient:disabled,
.mat-flat-button.mat-gradient:disabled,
.mat-fab.mat-gradient:disabled,
.mat-mini-fab.mat-gradient:disabled {
  background: rgba(0, 0, 0, 0.12);
}
.mat-button.mat-purple-gradient,
.mat-raised-button.mat-purple-gradient,
.mat-icon-button.mat-purple-gradient,
.mat-stroked-button.mat-purple-gradient,
.mat-flat-button.mat-purple-gradient,
.mat-fab.mat-purple-gradient,
.mat-mini-fab.mat-purple-gradient {
  color: #ffffff;
  background: #5456a2;
  background: linear-gradient(58deg, #5456a2 0, #7890c7 100%);
}
.mat-button.mat-purple-gradient:hover, .mat-button.mat-purple-gradient:focus,
.mat-raised-button.mat-purple-gradient:hover,
.mat-raised-button.mat-purple-gradient:focus,
.mat-icon-button.mat-purple-gradient:hover,
.mat-icon-button.mat-purple-gradient:focus,
.mat-stroked-button.mat-purple-gradient:hover,
.mat-stroked-button.mat-purple-gradient:focus,
.mat-flat-button.mat-purple-gradient:hover,
.mat-flat-button.mat-purple-gradient:focus,
.mat-fab.mat-purple-gradient:hover,
.mat-fab.mat-purple-gradient:focus,
.mat-mini-fab.mat-purple-gradient:hover,
.mat-mini-fab.mat-purple-gradient:focus {
  background: linear-gradient(58deg, #5456a2 0, #6566ab 100%);
}
.mat-button.mat-purple-gradient .mat-icon,
.mat-raised-button.mat-purple-gradient .mat-icon,
.mat-icon-button.mat-purple-gradient .mat-icon,
.mat-stroked-button.mat-purple-gradient .mat-icon,
.mat-flat-button.mat-purple-gradient .mat-icon,
.mat-fab.mat-purple-gradient .mat-icon,
.mat-mini-fab.mat-purple-gradient .mat-icon {
  position: relative;
  top: 0.143em;
}
.mat-button.mat-purple-gradient:disabled,
.mat-raised-button.mat-purple-gradient:disabled,
.mat-icon-button.mat-purple-gradient:disabled,
.mat-stroked-button.mat-purple-gradient:disabled,
.mat-flat-button.mat-purple-gradient:disabled,
.mat-fab.mat-purple-gradient:disabled,
.mat-mini-fab.mat-purple-gradient:disabled {
  background: rgba(0, 0, 0, 0.12);
}
.mat-button.mat-white,
.mat-raised-button.mat-white,
.mat-icon-button.mat-white,
.mat-stroked-button.mat-white,
.mat-flat-button.mat-white,
.mat-fab.mat-white,
.mat-mini-fab.mat-white {
  color: #fff;
  line-height: 32px !important;
}
.mat-button.mat-white:hover, .mat-button.mat-white:focus,
.mat-raised-button.mat-white:hover,
.mat-raised-button.mat-white:focus,
.mat-icon-button.mat-white:hover,
.mat-icon-button.mat-white:focus,
.mat-stroked-button.mat-white:hover,
.mat-stroked-button.mat-white:focus,
.mat-flat-button.mat-white:hover,
.mat-flat-button.mat-white:focus,
.mat-fab.mat-white:hover,
.mat-fab.mat-white:focus,
.mat-mini-fab.mat-white:hover,
.mat-mini-fab.mat-white:focus {
  background: #fff;
  color: #43454e;
  transition: all 0.25s ease-in-out;
}
.mat-button.mat-white.with-border,
.mat-raised-button.mat-white.with-border,
.mat-icon-button.mat-white.with-border,
.mat-stroked-button.mat-white.with-border,
.mat-flat-button.mat-white.with-border,
.mat-fab.mat-white.with-border,
.mat-mini-fab.mat-white.with-border {
  border: 2px solid #fff;
}
.mat-button.mat-black,
.mat-raised-button.mat-black,
.mat-icon-button.mat-black,
.mat-stroked-button.mat-black,
.mat-flat-button.mat-black,
.mat-fab.mat-black,
.mat-mini-fab.mat-black {
  background: #fff;
  color: #43454e;
  transition: all 0.25s ease-in-out;
  line-height: 38px !important;
}
.mat-button.mat-black:hover, .mat-button.mat-black:focus,
.mat-raised-button.mat-black:hover,
.mat-raised-button.mat-black:focus,
.mat-icon-button.mat-black:hover,
.mat-icon-button.mat-black:focus,
.mat-stroked-button.mat-black:hover,
.mat-stroked-button.mat-black:focus,
.mat-flat-button.mat-black:hover,
.mat-flat-button.mat-black:focus,
.mat-fab.mat-black:hover,
.mat-fab.mat-black:focus,
.mat-mini-fab.mat-black:hover,
.mat-mini-fab.mat-black:focus {
  background: #43454e;
  color: #fff;
  transition: all 0.25s ease-in-out;
}
.mat-button.mat-black.with-border,
.mat-raised-button.mat-black.with-border,
.mat-icon-button.mat-black.with-border,
.mat-stroked-button.mat-black.with-border,
.mat-flat-button.mat-black.with-border,
.mat-fab.mat-black.with-border,
.mat-mini-fab.mat-black.with-border {
  border: 2px solid #43454e;
}
.mat-button .extended-button,
.mat-raised-button .extended-button,
.mat-icon-button .extended-button,
.mat-stroked-button .extended-button,
.mat-flat-button .extended-button,
.mat-fab .extended-button,
.mat-mini-fab .extended-button {
  width: unset;
  padding: 0 26px;
  border-radius: 29px;
}
.mat-button .extended-button .mat-icon,
.mat-raised-button .extended-button .mat-icon,
.mat-icon-button .extended-button .mat-icon,
.mat-stroked-button .extended-button .mat-icon,
.mat-flat-button .extended-button .mat-icon,
.mat-fab .extended-button .mat-icon,
.mat-mini-fab .extended-button .mat-icon {
  margin-right: 10px;
}
.mat-button .extended-button .extended-button,
.mat-raised-button .extended-button .extended-button,
.mat-icon-button .extended-button .extended-button,
.mat-stroked-button .extended-button .extended-button,
.mat-flat-button .extended-button .extended-button,
.mat-fab .extended-button .extended-button,
.mat-mini-fab .extended-button .extended-button {
  font-weight: 300;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
}
.mat-button.drawer-btn, .mat-button.drawer-close,
.mat-raised-button.drawer-btn,
.mat-raised-button.drawer-close,
.mat-icon-button.drawer-btn,
.mat-icon-button.drawer-close,
.mat-stroked-button.drawer-btn,
.mat-stroked-button.drawer-close,
.mat-flat-button.drawer-btn,
.mat-flat-button.drawer-close,
.mat-fab.drawer-btn,
.mat-fab.drawer-close,
.mat-mini-fab.drawer-btn,
.mat-mini-fab.drawer-close {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.mat-button.mat-button_sm,
.mat-raised-button.mat-button_sm,
.mat-icon-button.mat-button_sm,
.mat-stroked-button.mat-button_sm,
.mat-flat-button.mat-button_sm,
.mat-fab.mat-button_sm,
.mat-mini-fab.mat-button_sm {
  padding: 5px;
}

.mat-raised-button:hover, .mat-raised-button:focus {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  transition: all 0.25s ease-in-out;
}

.mat-icon-button {
  min-width: 40px;
  min-height: 40px;
}
.mat-icon-button:hover, .mat-icon-button:focus {
  background: rgba(0, 0, 0, 0.06);
  transition: all 0.25s ease-in-out;
}

.mat-icon-button-grow {
  display: flex;
  min-width: 40px;
  align-items: center;
}
.mat-icon-button-grow .icon-text {
  max-width: 0;
  display: inline-flex;
  border: 2px solid transparent;
  border-left: 0;
  margin-left: -15px;
  height: 31px;
  line-height: 27px;
  border-radius: 0 8px 8px 0;
  white-space: nowrap;
  overflow: hidden;
  opacity: 0;
  font-size: 14px;
  transition: max-width 0.25s ease-in-out, opacity 0.15s ease-in-out;
}
@media only screen and (max-width: 599px) {
  .mat-icon-button-grow .icon-text {
    font-size: 13px;
  }
}
.mat-icon-button-grow .cdk-focused .mat-icon-button, .mat-icon-button-grow:hover .mat-icon-button, .mat-icon-button-grow:focus .mat-icon-button {
  z-index: 1;
  background: #fff;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  transition: all 0.2s ease-in-out;
}
.mat-icon-button-grow .cdk-focused .icon-text, .mat-icon-button-grow:hover .icon-text, .mat-icon-button-grow:focus .icon-text {
  max-width: 100%;
  opacity: 1;
  padding: 0 10px 0 20px;
  border: 2px solid #333;
}

.page-fab.mat-fab {
  position: fixed;
  z-index: 502;
  right: 6em;
  bottom: 0.5em;
  z-index: 10;
}
@media (max-width: 991px) {
  .page-fab.mat-fab {
    right: 6em;
    bottom: 0.5em;
  }
}

.mat-button,
.mat-raised-button,
.mat-stroked-button {
  min-width: 4.571rem !important;
  line-height: 2.25rem !important;
  padding: 0.125rem 1.143em !important;
}
.mat-button:hover,
.mat-raised-button:hover,
.mat-stroked-button:hover {
  text-decoration: none;
}
.mat-button mat-spinner,
.mat-raised-button mat-spinner,
.mat-stroked-button mat-spinner {
  margin: 0 auto;
  position: absolute;
  display: inline-block;
  top: 0.5em;
  left: 0;
  right: 0;
}
.mat-button mat-spinner circle,
.mat-raised-button mat-spinner circle,
.mat-stroked-button mat-spinner circle {
  stroke: rgba(0, 0, 0, 0.5);
}

.mat-button.mat-twitter,
.mat-icon-button.mat-twitter,
.mat-raised-button.mat-twitter,
.mat-mini-fab.mat-twitter,
.mat-fab.mat-twitter {
  color: #fff;
  background-color: #00c7f7;
  border-color: #00c7f7;
}
.mat-button.mat-twitter:hover, .mat-button.mat-twitter:focus, .mat-button.mat-twitter:active,
.mat-icon-button.mat-twitter:hover,
.mat-icon-button.mat-twitter:focus,
.mat-icon-button.mat-twitter:active,
.mat-raised-button.mat-twitter:hover,
.mat-raised-button.mat-twitter:focus,
.mat-raised-button.mat-twitter:active,
.mat-mini-fab.mat-twitter:hover,
.mat-mini-fab.mat-twitter:focus,
.mat-mini-fab.mat-twitter:active,
.mat-fab.mat-twitter:hover,
.mat-fab.mat-twitter:focus,
.mat-fab.mat-twitter:active {
  color: #fff;
  text-decoration: none;
  background-color: #00a6ce;
  border-color: #0096ba;
}
.mat-button.mat-twitter .material-icons,
.mat-button.mat-twitter .fa,
.mat-icon-button.mat-twitter .material-icons,
.mat-icon-button.mat-twitter .fa,
.mat-raised-button.mat-twitter .material-icons,
.mat-raised-button.mat-twitter .fa,
.mat-mini-fab.mat-twitter .material-icons,
.mat-mini-fab.mat-twitter .fa,
.mat-fab.mat-twitter .material-icons,
.mat-fab.mat-twitter .fa {
  color: #fff;
}
.mat-button.mat-facebook,
.mat-icon-button.mat-facebook,
.mat-raised-button.mat-facebook,
.mat-mini-fab.mat-facebook,
.mat-fab.mat-facebook {
  color: #fff;
  background-color: #335397;
  border-color: #335397;
}
.mat-button.mat-facebook:hover, .mat-button.mat-facebook:focus, .mat-button.mat-facebook:active,
.mat-icon-button.mat-facebook:hover,
.mat-icon-button.mat-facebook:focus,
.mat-icon-button.mat-facebook:active,
.mat-raised-button.mat-facebook:hover,
.mat-raised-button.mat-facebook:focus,
.mat-raised-button.mat-facebook:active,
.mat-mini-fab.mat-facebook:hover,
.mat-mini-fab.mat-facebook:focus,
.mat-mini-fab.mat-facebook:active,
.mat-fab.mat-facebook:hover,
.mat-fab.mat-facebook:focus,
.mat-fab.mat-facebook:active {
  color: #fff;
  text-decoration: none;
  background-color: #294279;
  border-color: #243a69;
}
.mat-button.mat-facebook .material-icons,
.mat-button.mat-facebook .fa,
.mat-icon-button.mat-facebook .material-icons,
.mat-icon-button.mat-facebook .fa,
.mat-raised-button.mat-facebook .material-icons,
.mat-raised-button.mat-facebook .fa,
.mat-mini-fab.mat-facebook .material-icons,
.mat-mini-fab.mat-facebook .fa,
.mat-fab.mat-facebook .material-icons,
.mat-fab.mat-facebook .fa {
  color: #fff;
}
.mat-button.mat-youtube,
.mat-icon-button.mat-youtube,
.mat-raised-button.mat-youtube,
.mat-mini-fab.mat-youtube,
.mat-fab.mat-youtube {
  color: #fff;
  background-color: #CC181E;
  border-color: #CC181E;
}
.mat-button.mat-youtube:hover, .mat-button.mat-youtube:focus, .mat-button.mat-youtube:active,
.mat-icon-button.mat-youtube:hover,
.mat-icon-button.mat-youtube:focus,
.mat-icon-button.mat-youtube:active,
.mat-raised-button.mat-youtube:hover,
.mat-raised-button.mat-youtube:focus,
.mat-raised-button.mat-youtube:active,
.mat-mini-fab.mat-youtube:hover,
.mat-mini-fab.mat-youtube:focus,
.mat-mini-fab.mat-youtube:active,
.mat-fab.mat-youtube:hover,
.mat-fab.mat-youtube:focus,
.mat-fab.mat-youtube:active {
  color: #fff;
  text-decoration: none;
  background-color: #a71419;
  border-color: #951216;
}
.mat-button.mat-youtube .material-icons,
.mat-button.mat-youtube .fa,
.mat-icon-button.mat-youtube .material-icons,
.mat-icon-button.mat-youtube .fa,
.mat-raised-button.mat-youtube .material-icons,
.mat-raised-button.mat-youtube .fa,
.mat-mini-fab.mat-youtube .material-icons,
.mat-mini-fab.mat-youtube .fa,
.mat-fab.mat-youtube .material-icons,
.mat-fab.mat-youtube .fa {
  color: #fff;
}

.mat-icon-button.negative_button {
  min-width: auto;
  margin-right: 0.714em;
}

.mat-drawer-container.elite-drawer-container {
  display: flex;
  flex: 1 1 100%;
}
.mat-drawer-container.elite-drawer-container .mat-drawer-content {
  height: 0px;
  max-height: 0px;
}
.mat-drawer-container.elite-drawer-container .mat-drawer {
  background-color: #ffffff;
  width: 28.571rem;
  height: 100%;
  display: flex;
}
.mat-drawer-container.elite-drawer-container .mat-drawer.mat-drawer_sm {
  width: 19.643em;
}
.mat-drawer-container.elite-drawer-container .mat-drawer .mat-drawer-inner-container {
  padding: 1.071rem;
}
@media screen and (max-width: 1023px) {
  .mat-drawer-container.elite-drawer-container .mat-drawer .mat-drawer-inner-container {
    padding-bottom: 1.071rem;
  }
}
.mat-drawer-container.elite-drawer-container .mat-drawer .mat-button-toggle-group {
  font-size: 90%;
  font-weight: 500;
}
.mat-drawer-container.elite-drawer-container.has-toolbar {
  min-height: calc(100vh - 8.857em);
  /* smartphones, touchscreens */
}
@media (hover: none) and (pointer: coarse) {
  .mat-drawer-container.elite-drawer-container.has-toolbar {
    min-height: -webkit-fill-available;
  }
}
@media screen and (max-width: 1023px) {
  .mat-drawer-container.elite-drawer-container.has-toolbar {
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;
  }
}
.mat-drawer-container.elite-drawer-container.has-toolbar.drawer-isOpen {
  /* smartphones, touchscreens */
}
@media (hover: none) and (pointer: coarse) {
  .mat-drawer-container.elite-drawer-container.has-toolbar.drawer-isOpen {
    height: 100%;
    max-height: calc(100vh - 16.071rem);
    min-height: calc(100vh - 16.071rem);
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;
  }
}
@media screen and (max-width: 1023px) {
  .mat-drawer-container.elite-drawer-container.has-toolbar.drawer-isOpen {
    height: 100%;
    max-height: calc(100vh - 16.071rem);
    min-height: calc(100vh - 16.071rem);
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;
  }
}
.mat-drawer-container.elite-drawer-container.drawer-isOpen:not(.has-toolbar) {
  /* smartphones, touchscreens */
}
@media (hover: none) and (pointer: coarse) {
  .mat-drawer-container.elite-drawer-container.drawer-isOpen:not(.has-toolbar) {
    height: 100%;
    max-height: calc(100vh - 16.071rem);
    min-height: calc(100vh - 16.071rem);
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;
  }
}
@media screen and (max-width: 1023px) {
  .mat-drawer-container.elite-drawer-container.drawer-isOpen:not(.has-toolbar) {
    height: 100%;
    max-height: calc(100vh - 12.625em);
    min-height: calc(100vh - 12.625em);
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;
  }
}

.mat-drawer-container .mat-drawer-content {
  height: auto;
}
.mat-drawer-container.drawer-isOpen .mat-drawer-content {
  margin: 0 !important;
}
.mat-drawer-container.drawer-isOpen .mat-drawer {
  /* smartphones, touchscreens */
}
.mat-drawer-container.drawer-isOpen .mat-drawer.navigation-drawer {
  position: fixed;
  top: 0;
}
@media (hover: none) and (pointer: coarse) {
  .mat-drawer-container.drawer-isOpen .mat-drawer {
    width: 97vw;
    height: 100%;
    min-height: -webkit-fill-available;
  }
}
@media screen and (max-width: 1023px) {
  .mat-drawer-container.drawer-isOpen .mat-drawer {
    width: 97vw;
    height: 100%;
    min-height: -webkit-fill-available;
  }
}

mat-drawer-container.mat-drawer-container {
  background-color: #ffffff;
}

.mat-drawer-content {
  background: #ffffff;
  width: 100%;
}

.calendar-drawer-container .mat-drawer-content {
  min-height: calc(100vh - 8.857em);
  max-height: calc(100vh - 8.857em);
}
@media (max-width: 1023px) {
  .calendar-drawer-container .mat-drawer-content {
    min-width: 100%;
    min-height: 100%;
    max-height: 100%;
  }
}

mat-spinner circle {
  stroke: #43454e;
}

.busy-spinner mat-spinner,
.busy-spinner .mat-spinner {
  margin: 0 auto;
  position: absolute;
  display: inline-block;
  top: 0.5em;
  left: 0;
  right: 0;
  z-index: 101;
}
.busy-spinner mat-spinner circle,
.busy-spinner .mat-spinner circle {
  stroke: rgba(0, 0, 0, 0.5);
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-self: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  width: 100%;
  z-index: 101;
}
.loading-spinner .mat-spinner {
  position: relative;
  display: flex;
  justify-content: center;
  align-self: center;
  z-index: 101;
}

.mat-progress-spinner circle {
  stroke-linecap: round;
}
.mat-progress-spinner.mat-progress-spinner_colored {
  border-radius: 0.571rem;
}
.mat-progress-spinner.mat-progress-spinner_colored circle {
  -webkit-animation: dash 1s ease-in-out forwards;
  animation: dash 1s ease-in-out forwards;
}
.mat-progress-spinner.mat-progress-spinner_colored:nth-of-type(1) circle {
  stroke: linear-gradient(to right, #aa99ff, #e09cff);
  stroke: linear-gradient(to left, #00706c, #99c5c4);
}
.mat-progress-spinner.mat-progress-spinner_colored:nth-of-type(2) circle {
  stroke: linear-gradient(to right, #aa99ff, #e09cff);
  stroke: linear-gradient(to right, #b452ff, #6426e0);
}

@-webkit-keyframes dash {
  from {
    stroke-dashoffset: 0.071rem;
    stroke-dasharray: 0.071rem;
  }
}
@keyframes dash {
  from {
    stroke-dashoffset: 0.071rem;
    stroke-dasharray: 0.071rem;
  }
}
.mat-progress-spinner_percentage {
  position: absolute;
}

.progress-circle-container .mat-progress-spinner circle {
  stroke: #ffffff;
}

.loading {
  padding: 7.143em 0;
}

.mat-select .mat-select-value {
  color: #43454e;
}
.mat-select.mat-select-trigger-custom {
  position: relative;
  top: -3px;
}
.mat-select.mat-select-trigger-custom .payIcon.mat-icon {
  height: 30px;
}
.mat-select.mat-select-trigger-custom .mat-select-arrow-wrapper {
  transform: translateY(0%);
}

.standAlone.mat-select,
.payWith.mat-select {
  border-radius: 4px;
  font-size: 1rem;
}
.standAlone.mat-select .mat-select-trigger,
.payWith.mat-select .mat-select-trigger {
  padding: 0.857em 0.714em;
}
.standAlone.mat-select .mat-select-value-text,
.standAlone.mat-select .mat-select-placeholder,
.payWith.mat-select .mat-select-value-text,
.payWith.mat-select .mat-select-placeholder {
  font-weight: 500;
  font-size: 1rem;
}
.standAlone.mat-select .mat-select-value-text,
.payWith.mat-select .mat-select-value-text {
  font-weight: 500;
  font-size: 1rem;
}
@media (max-width: 991px) {
  .standAlone.mat-select .mat-select-value-text,
.payWith.mat-select .mat-select-value-text {
    font-size: 1.143em;
  }
}
.standAlone.mat-select.err,
.payWith.mat-select.err {
  border: 0.143em solid #dfb2b0;
}

.mat-option-pseudo-checkbox.mat-pseudo-checkbox-checked::after {
  width: 0.714em;
  height: 0.357em;
}

.mat-dialog-container .mat-card.warn {
  color: #fff;
  background-color: rgba(191, 45, 41, 0.9);
  border-color: #BF2D29;
  position: relative;
  padding: 0.75rem 1.25rem !important;
  margin-bottom: 1rem;
  max-width: 42.786em;
  box-shadow: none;
  border-radius: 4px !important;
  margin-bottom: 1.071rem !important;
}
.mat-dialog-container .mat-card.warn strong {
  font-weight: 500;
}

.mat-card {
  color: #43454e;
}
@media screen and (max-width: 599px) {
  .mat-card .mat-card {
    border-radius: 8px !important;
  }
}
.mat-card.Canceled, .mat-card.disabled {
  background: rgba(0, 0, 0, 0.04);
}
.mat-card.Canceled *:not(button, a), .mat-card.disabled *:not(button, a) {
  color: rgba(0, 0, 0, 0.84);
}
.mat-card.Canceled .mat-table, .mat-card.disabled .mat-table {
  background-color: transparent !important;
}
.mat-card.Canceled .mat-table .mat-header-cell, .mat-card.disabled .mat-table .mat-header-cell {
  background-color: transparent !important;
}
.mat-card.Canceled .mat-paginator, .mat-card.disabled .mat-paginator {
  background-color: transparent !important;
}
.mat-card.Canceled .mat-card-header a,
.mat-card.Canceled .mat-card-header button, .mat-card.disabled .mat-card-header a,
.mat-card.disabled .mat-card-header button {
  cursor: not-allowed;
  pointer-events: none;
}
.mat-card.warn {
  color: #fff;
  background-color: rgba(191, 45, 41, 0.9);
  border-color: #BF2D29;
  position: relative;
  padding: 0.75rem 1.25rem !important;
  margin-bottom: 1rem;
  max-width: 42.786em;
  border-radius: 0 !important;
}
.mat-card.warn strong {
  font-weight: 500;
}
.mat-card.flat {
  box-shadow: none !important;
  border: 0.071rem solid #dadce0;
}
.mat-card.sm-card {
  height: auto;
  min-height: 35.714em;
  overflow-y: auto;
  padding: 3.429em 1.143em 2.571rem;
}
@media screen and (max-width: 599px) {
  .mat-card.sm-card {
    min-height: 100vh;
  }
}
.mat-card .mat-card-title {
  font-size: 1.125rem;
  font-weight: 400;
}
.mat-card .mat-card-title h3 {
  font-size: inherit;
}
.mat-card .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.64);
}
.mat-card .mat-card-divider {
  position: relative !important;
  margin-bottom: 1.429em !important;
}
.mat-card .mat-card-divider.mb-0 {
  margin-bottom: 0 !important;
}
.mat-card.service-card .mat-card-header .mat-card-header-text {
  width: 100%;
}
.mat-card.service-card .mat-card-header .mat-card-title {
  font-size: 1.286em;
}
.mat-card.mat-card-dashboard_header {
  border-radius: 0 0 0.571rem 0.571rem !important;
}
.mat-card .mat-card-floating-avatar {
  position: absolute;
  font-size: 34px;
  height: 66px;
  max-height: 88px;
  width: 66px;
  max-width: 88px;
  top: -41px;
  border-radius: 50%;
  background: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-subheader {
  height: 3.429em;
  line-height: 1.143em;
  margin: 0;
  font-size: 1rem;
  font-weight: 500;
}

mat-action-list p {
  font-size: 1rem;
  color: #43454e;
}

.mat-list,
.mat-list.mat-list-base {
  padding-top: 0;
}
.mat-list .mat-list-item,
.mat-list.mat-list-base .mat-list-item {
  color: #43454e;
  height: 3.429em;
  font-size: 14px;
}
@media only screen and (max-width: 599px) {
  .mat-list .mat-list-item,
.mat-list.mat-list-base .mat-list-item {
    font-size: 13px;
  }
}
.mat-list .mat-list-item .mat-line:nth-child(n+1),
.mat-list.mat-list-base .mat-list-item .mat-line:nth-child(n+1) {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0;
}
.mat-list .mat-list-item .mat-line:nth-child(n+2),
.mat-list.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 0.929em;
  margin-top: 0.143em;
  font-weight: 400;
  white-space: normal;
}
.mat-list .mat-list-item .mat-list-item-content,
.mat-list.mat-list-base .mat-list-item .mat-list-item-content {
  padding: 0 1.143em;
}
.mat-list .mat-list-item.mat-2-line, .mat-list .mat-list-item.mat-3-line,
.mat-list.mat-list-base .mat-list-item.mat-2-line,
.mat-list.mat-list-base .mat-list-item.mat-3-line {
  height: 72px;
}
@media (max-width: 1279px) {
  .mat-list .mat-list-item.mat-2-line, .mat-list .mat-list-item.mat-3-line,
.mat-list.mat-list-base .mat-list-item.mat-2-line,
.mat-list.mat-list-base .mat-list-item.mat-3-line {
    height: auto;
    min-height: 72px;
  }
}
.mat-list .mat-list-item.nav-list.active,
.mat-list.mat-list-base .mat-list-item.nav-list.active {
  background: #77263c;
}
.mat-list .mat-list-item.nav-list.active *,
.mat-list.mat-list-base .mat-list-item.nav-list.active * {
  color: #fff;
}
.mat-list.list-items,
.mat-list.mat-list-base.list-items {
  margin-top: 1.071rem;
}
.mat-list.list-items .mat-list-item.mat-2-line, .mat-list.list-items .mat-list-item.mat-3-line,
.mat-list.list-items .mat-line-item.mat-list-item-with-avatar.mat-2-line,
.mat-list.list-items .mat-line-item.mat-list-item-with-avatar.mat-3-line,
.mat-list.mat-list-base.list-items .mat-list-item.mat-2-line,
.mat-list.mat-list-base.list-items .mat-list-item.mat-3-line,
.mat-list.mat-list-base.list-items .mat-line-item.mat-list-item-with-avatar.mat-2-line,
.mat-list.mat-list-base.list-items .mat-line-item.mat-list-item-with-avatar.mat-3-line {
  min-height: 5.143em;
  height: 72px;
  display: flex;
}
@media (max-width: 1279px) {
  .mat-list.list-items .mat-list-item.mat-2-line, .mat-list.list-items .mat-list-item.mat-3-line,
.mat-list.list-items .mat-line-item.mat-list-item-with-avatar.mat-2-line,
.mat-list.list-items .mat-line-item.mat-list-item-with-avatar.mat-3-line,
.mat-list.mat-list-base.list-items .mat-list-item.mat-2-line,
.mat-list.mat-list-base.list-items .mat-list-item.mat-3-line,
.mat-list.mat-list-base.list-items .mat-line-item.mat-list-item-with-avatar.mat-2-line,
.mat-list.mat-list-base.list-items .mat-line-item.mat-list-item-with-avatar.mat-3-line {
    height: auto;
    margin-bottom: 10px;
  }
}
.mat-list.list-items .mat-list-item h4,
.mat-list.list-items .mat-line-item.mat-list-item-with-avatar h4,
.mat-list.mat-list-base.list-items .mat-list-item h4,
.mat-list.mat-list-base.list-items .mat-line-item.mat-list-item-with-avatar h4 {
  white-space: normal;
  word-break: break-word;
  font-size: 1.071rem;
}
.mat-list.list-items .mat-list-item h4.mat-line-scroller,
.mat-list.list-items .mat-line-item.mat-list-item-with-avatar h4.mat-line-scroller,
.mat-list.mat-list-base.list-items .mat-list-item h4.mat-line-scroller,
.mat-list.mat-list-base.list-items .mat-line-item.mat-list-item-with-avatar h4.mat-line-scroller {
  max-height: 10.714em;
  overflow-y: auto;
}
.mat-list h4.mat-line-scroller,
.mat-list.mat-list-base h4.mat-line-scroller {
  max-height: 10.714em;
  overflow-y: auto;
}
.mat-list h4.mat-line-scroller .mat-chip-list-wrapper,
.mat-list.mat-list-base h4.mat-line-scroller .mat-chip-list-wrapper {
  margin: 0em;
}
.mat-list .mat-list-item_textarea,
.mat-list.mat-list-base .mat-list-item_textarea {
  height: auto !important;
  min-height: 4.429em !important;
}
.mat-list .mat-line_break,
.mat-list.mat-list-base .mat-line_break {
  white-space: normal;
  text-overflow: initial;
}

.grid-container {
  margin: 0 auto;
  padding: 1.714em;
  overflow-x: hidden;
}
@media (max-width: 599px) {
  .grid-container {
    padding-left: 0.625em;
    padding-right: 0.625em;
  }
}
@media (min-width: 2100px) {
  .grid-container {
    width: 102.857em;
  }
}
@media (min-width: 1300px) {
  .grid-container {
    width: 73.143em;
  }
  .grid-container.grid-container_md {
    width: 60em;
  }
  .grid-container.grid-container_small {
    width: 42.786em;
  }
  .grid-container.grid-container_xs {
    width: 38.5em;
  }
  .grid-container.grid-container_max {
    position: relative;
    margin: 0;
    padding: 0;
    width: 100%;
  }
}
@media (min-width: 599px) and (max-width: 1299px) {
  .grid-container {
    margin: 0 1.714em;
    width: auto;
  }
}
@media (min-width: 1300px) {
  .grid-container.grid-container_full {
    position: relative;
    margin: 0;
    padding: 1.714em;
    width: 100%;
  }
}
@media (max-width: 599px) {
  .grid-container.grid-container_full {
    width: 100%;
    padding: 1.714em 0;
    margin: 0;
  }
}
@media (min-width: 1500px) {
  .grid-container.grid-container_lg {
    width: 91.429em;
  }
}
.grid-container.grid-container_modal {
  position: relative;
  margin: 0;
  padding: 1.714em;
  width: 100%;
}
@media (max-width: 599px) {
  .grid-container.grid-container_modal {
    padding: 0.857em;
  }
}

.mat-drawer-container {
  background-color: #ffffff;
  color: #43454e;
}
.mat-drawer-container .grid-container {
  margin: 0 auto;
  padding-top: 1.714em;
  padding-bottom: 1.714em;
}
@media (min-width: 599px) and (max-width: 1672px) {
  .mat-drawer-container .grid-container {
    margin: 0 1.714em;
    width: auto;
  }
}
.mat-drawer-container .grid-container.grid-container_modal {
  margin: 0;
}

.mat-chip-list.mat-chip-list-limit-height {
  max-height: 125px;
  min-height: 46px;
  overflow-y: auto;
}

.mat-chip.mat-standard-chip {
  padding: 1rem 0.714em;
  min-height: 2em;
  background-color: #f9fafc !important;
  border: 2px solid #f9fafc;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  padding: 0 0.857em;
  line-height: 2.286em !important;
}

.mat-toolbar.mat-toolbar-row, .mat-toolbar.mat-toolbar-single-row {
  min-height: 64px;
  height: 4.571rem;
}
@media screen and (max-width: 599px) {
  .mat-toolbar.mat-toolbar-row, .mat-toolbar.mat-toolbar-single-row {
    min-height: 56px;
    height: 4em;
  }
}

.mat-toolbar_header {
  position: relative;
  display: block !important;
  background: #ffffff !important;
  font-size: 90%;
  z-index: 11;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: none;
}
@media screen and (max-width: 599px) {
  .mat-toolbar_header {
    font-size: 100%;
  }
}
.mat-toolbar_header .mat-toolbar-row_grid {
  display: block;
}

.mat-toolbar_sm {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  min-height: 2.429em !important;
  padding: 0.357em 0;
  z-index: 1;
  font-size: 80%;
  background: #fff !important;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}
.mat-toolbar_sm .mat-toolbar-row,
.mat-toolbar_sm .mat-toolbar-single-row {
  height: auto;
}

.mat-table .mat-header-cell.center,
.mat-table .mat-footer-cell.center,
.mat-table .mat-cell.center {
  justify-content: center;
  align-items: center;
}

.table-container {
  overflow: auto;
  max-width: calc(100vw - (1.714em * 2));
  touch-action: manipulation;
}
@media screen and (max-width: 599px) {
  .table-container {
    max-width: 100vw;
  }
}
.table-container .mat-table {
  overflow: auto;
  max-width: calc(100vw - (1.714em * 2));
  touch-action: manipulation;
  /*unnecessary to make table header fixed*/
}
@media screen and (max-width: 1024px) {
  .table-container .mat-table {
    max-width: 100vw;
  }
}
.table-container .mat-table .mat-header-cell,
.table-container .mat-table .mat-footer-cell,
.table-container .mat-table .mat-cell {
  color: rgba(0, 0, 0, 0.72);
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 0.3125rem;
  padding-right: 0.3125rem;
}
.table-container .mat-table .mat-cell,
.table-container .mat-table .mat-header-cell,
.table-container .mat-table .mat-footer-cell {
  min-width: 15%;
  max-width: 15%;
  font-size: 0.875rem;
}
@media screen and (max-width: 599) {
  .table-container .mat-table .mat-cell,
.table-container .mat-table .mat-header-cell,
.table-container .mat-table .mat-footer-cell {
    max-width: 175px;
    min-width: 175px;
  }
}
.table-container .mat-table .mat-cell.cell_75,
.table-container .mat-table .mat-header-cell.cell_75,
.table-container .mat-table .mat-footer-cell.cell_75 {
  max-width: 75%;
  min-width: 75%;
}
@media screen and (max-width: 1024px) {
  .table-container .mat-table .mat-cell.cell_75,
.table-container .mat-table .mat-header-cell.cell_75,
.table-container .mat-table .mat-footer-cell.cell_75 {
    max-width: 300px;
    min-width: 300px;
  }
}
.table-container .mat-table .mat-cell.cell_70,
.table-container .mat-table .mat-header-cell.cell_70,
.table-container .mat-table .mat-footer-cell.cell_70 {
  max-width: 70%;
  min-width: 70%;
}
@media screen and (max-width: 1024px) {
  .table-container .mat-table .mat-cell.cell_70,
.table-container .mat-table .mat-header-cell.cell_70,
.table-container .mat-table .mat-footer-cell.cell_70 {
    max-width: 300px;
    min-width: 300px;
  }
}
.table-container .mat-table .mat-cell.cell_60,
.table-container .mat-table .mat-header-cell.cell_60,
.table-container .mat-table .mat-footer-cell.cell_60 {
  max-width: 60%;
  min-width: 60%;
}
@media screen and (max-width: 1024px) {
  .table-container .mat-table .mat-cell.cell_60,
.table-container .mat-table .mat-header-cell.cell_60,
.table-container .mat-table .mat-footer-cell.cell_60 {
    max-width: 300px;
    min-width: 300px;
  }
}
.table-container .mat-table .mat-cell.cell_50,
.table-container .mat-table .mat-header-cell.cell_50,
.table-container .mat-table .mat-footer-cell.cell_50 {
  max-width: 50%;
  min-width: 50%;
}
@media screen and (max-width: 1024px) {
  .table-container .mat-table .mat-cell.cell_50,
.table-container .mat-table .mat-header-cell.cell_50,
.table-container .mat-table .mat-footer-cell.cell_50 {
    max-width: 300px;
    min-width: 300px;
  }
}
.table-container .mat-table .mat-cell.cell_40,
.table-container .mat-table .mat-header-cell.cell_40,
.table-container .mat-table .mat-footer-cell.cell_40 {
  max-width: 40%;
  min-width: 40%;
}
@media screen and (max-width: 1024px) {
  .table-container .mat-table .mat-cell.cell_40,
.table-container .mat-table .mat-header-cell.cell_40,
.table-container .mat-table .mat-footer-cell.cell_40 {
    max-width: 300px;
    min-width: 300px;
  }
}
.table-container .mat-table .mat-cell.cell_30,
.table-container .mat-table .mat-header-cell.cell_30,
.table-container .mat-table .mat-footer-cell.cell_30 {
  max-width: 30%;
  min-width: 30%;
}
@media screen and (max-width: 1024px) {
  .table-container .mat-table .mat-cell.cell_30,
.table-container .mat-table .mat-header-cell.cell_30,
.table-container .mat-table .mat-footer-cell.cell_30 {
    max-width: 300px;
    min-width: 300px;
  }
}
.table-container .mat-table .mat-cell.cell_25,
.table-container .mat-table .mat-header-cell.cell_25,
.table-container .mat-table .mat-footer-cell.cell_25 {
  max-width: 25%;
  min-width: 25%;
}
@media screen and (max-width: 1024px) {
  .table-container .mat-table .mat-cell.cell_25,
.table-container .mat-table .mat-header-cell.cell_25,
.table-container .mat-table .mat-footer-cell.cell_25 {
    max-width: 300px;
    min-width: 300px;
  }
}
.table-container .mat-table .mat-cell.cell_20,
.table-container .mat-table .mat-header-cell.cell_20,
.table-container .mat-table .mat-footer-cell.cell_20 {
  max-width: 20%;
  min-width: 20%;
}
@media screen and (max-width: 1024px) {
  .table-container .mat-table .mat-cell.cell_20,
.table-container .mat-table .mat-header-cell.cell_20,
.table-container .mat-table .mat-footer-cell.cell_20 {
    max-width: 225px;
    min-width: 225px;
  }
}
.table-container .mat-table .mat-cell.cell_15,
.table-container .mat-table .mat-header-cell.cell_15,
.table-container .mat-table .mat-footer-cell.cell_15 {
  max-width: 15%;
  min-width: 15%;
}
@media screen and (max-width: 1024px) {
  .table-container .mat-table .mat-cell.cell_15,
.table-container .mat-table .mat-header-cell.cell_15,
.table-container .mat-table .mat-footer-cell.cell_15 {
    max-width: 200px;
    min-width: 200px;
  }
}
.table-container .mat-table .mat-cell.cell_10,
.table-container .mat-table .mat-header-cell.cell_10,
.table-container .mat-table .mat-footer-cell.cell_10 {
  max-width: 10%;
  min-width: 10%;
}
@media screen and (max-width: 1024px) {
  .table-container .mat-table .mat-cell.cell_10,
.table-container .mat-table .mat-header-cell.cell_10,
.table-container .mat-table .mat-footer-cell.cell_10 {
    max-width: 150px;
    min-width: 150px;
  }
}
.table-container .mat-table .mat-cell.mat-table-cell-icon,
.table-container .mat-table .mat-header-cell.mat-table-cell-icon,
.table-container .mat-table .mat-footer-cell.mat-table-cell-icon {
  max-width: 10%;
  min-width: 10%;
}
@media screen and (max-width: 1024px) {
  .table-container .mat-table .mat-cell.mat-table-cell-icon,
.table-container .mat-table .mat-header-cell.mat-table-cell-icon,
.table-container .mat-table .mat-footer-cell.mat-table-cell-icon {
    max-width: 100px;
    min-width: 100px;
  }
}
.table-container .mat-table .mat-cell .text-default,
.table-container .mat-table .mat-header-cell .text-default,
.table-container .mat-table .mat-footer-cell .text-default {
  color: inherit;
}
.table-container .mat-table .mat-header-cell {
  top: 0em;
  position: -webkit-sticky;
  position: sticky;
  z-index: 100;
  background: #ffffff;
}
.table-container .mat-table mat-header-row,
.table-container .mat-table mat-row,
.table-container .mat-table mat-footer-row {
  text-align: left;
  border-width: 0;
  border-bottom-width: 0.071rem;
  border-style: solid;
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
@media screen and (max-width: 1260px) {
  .table-container .mat-table mat-header-row,
.table-container .mat-table mat-row,
.table-container .mat-table mat-footer-row {
    min-width: 1200px;
    width: fit-content;
  }
}
.table-container .mat-table mat-header-row:last-of-type,
.table-container .mat-table mat-row:last-of-type {
  border-bottom-color: rgba(0, 0, 0, 0);
}
.table-container .mat-table .mat-header-row {
  top: 0;
  position: sticky;
  z-index: 1;
  background-color: inherit;
  text-align: center;
}
.table-container .mat-table mat-row:hover, .table-container .mat-table mat-row:focus {
  background: rgba(0, 0, 0, 0.04);
}
.table-container .mat-table mat-row.Disabled, .table-container .mat-table mat-row.OnHold, .table-container .mat-table mat-row.Canceled {
  color: rgba(0, 0, 0, 0.5);
}
.table-container .mat-table mat-row.Disabled *, .table-container .mat-table mat-row.OnHold *, .table-container .mat-table mat-row.Canceled * {
  color: rgba(0, 0, 0, 0.5);
}
.table-container .mat-table mat-row.Disabled .color1, .table-container .mat-table mat-row.OnHold .color1, .table-container .mat-table mat-row.Canceled .color1 {
  color: transparent;
  display: block;
  margin-top: -37px;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-size: 0.857em;
}

.mat-paginator {
  background-color: transparent !important;
}

.mat-tab-group .mat-tab-header .mat-tab-label-container .mat-tab-label,
.mat-tab-group .mat-tab-header .mat-tab-label-container .mat-tab-link {
  color: #43454e;
  opacity: 0.7;
}
.mat-tab-group .mat-tab-header .mat-tab-label-container .mat-tab-label.mat-tab-label-active,
.mat-tab-group .mat-tab-header .mat-tab-label-container .mat-tab-link.mat-tab-label-active {
  opacity: 1;
}

.mat-controls {
  background: #fff;
  padding: 5px;
  border-radius: 0.571rem;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.mat-controls.mat-purple-gradient {
  color: #ffffff;
  background: #5456a2;
  background: linear-gradient(58deg, #5456a2 0, #7890c7 100%);
}
.mat-controls.mat-purple-gradient:hover, .mat-controls.mat-purple-gradient:focus {
  background: linear-gradient(58deg, #5456a2 0, #6566ab 100%);
}
.mat-controls.mat-purple-gradient:disabled {
  background: rgba(0, 0, 0, 0.12);
}
.mat-controls.mat-purple-gradient .vert-button_break {
  border-left: 0.071rem solid rgba(255, 255, 255, 0.42);
}
.mat-controls.mat-purple-gradient .mat-form-field,
.mat-controls.mat-purple-gradient .mat-datepicker-toggle {
  color: rgba(255, 255, 255, 0.85);
}
.mat-controls.mat-purple-gradient .mat-form-field-appearance-outline .mat-form-field-outline,
.mat-controls.mat-purple-gradient .mat-form-field-appearance-outline .mat-form-field-outline-thick,
.mat-controls.mat-purple-gradient .mat-form-field-appearance-outline .mat-form-field-label {
  color: rgba(255, 255, 255, 0.85);
}

.cdk-overlay-container.cdk-overlay-pane_full-overlay .cdk-global-overlay-wrapper .cdk-overlay-pane {
  max-height: 100vh !important;
}
.cdk-overlay-container .mat-bottom-sheet-container.mat-bottom-sheet-container_full-overlay {
  min-width: 100vw;
  max-width: 100vw;
  width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
  height: 100vh;
  background: #fff;
}
@media (max-width: 575px) {
  .cdk-overlay-container .mat-bottom-sheet-container.mat-bottom-sheet-container_full-overlay {
    padding-bottom: 100px;
  }
}
.cdk-overlay-container .mat-bottom-sheet-container.show-overflow {
  overflow: visible;
}

mat-tree-node .mat-tree-node,
.mat-nested-tree-node .mat-tree-node {
  font-size: 1rem !important;
}

.mat-badge.mat-badge-medium .mat-badge-content {
  background: #92344f;
  color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3), 0 1px 3px 1px rgba(0, 0, 0, 0.15);
}
.mat-badge.mat-badge-medium.mat-badge_danger .mat-badge-content {
  background: #d4504c;
}
.mat-badge.mat-badge-medium.mat-badge-flat .mat-badge-content {
  box-shadow: none;
}

.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  background: #92344f;
  color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3), 0 1px 3px 1px rgba(0, 0, 0, 0.15);
}

@media (min-width: 2300px) {
  body {
    font-size: 0.938rem;
  }

  .mat-button,
.mat-raised-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button,
.mat-fab,
.mat-mini-fab {
    font-size: 0.938rem;
  }

  .empty h2 button,
.empty-contain h2 button,
.empty-full-width h2 button,
.empty-full h2 button {
    font-size: 0.938rem;
  }

  .mat-form-field .mat-input-element,
.mat-form-field .mat-select-value-text,
.mat-form-field .mat-select-placeholder,
.mat-form-field .mat-form-field-label,
.mat-form-field .mat-form-field-infix {
    font-size: 14px;
  }

  .mat-card-subtitle,
.mat-card-content,
.mat-cell,
.mat-footer-cell,
.mat-list .mat-list-item,
.mat-list.mat-list-base .mat-list-item {
    font-size: 0.875rem;
  }

  .mat-list.list-items .mat-list-item.mat-2-line,
.mat-list.list-items .mat-list-item.mat-3-line,
.mat-list.list-items .mat-line-item.mat-list-item-with-avatar.mat-2-line,
.mat-list.list-items .mat-line-item.mat-list-item-with-avatar.mat-3-line,
.mat-list.mat-list-base.list-items .mat-list-item.mat-2-line,
.mat-list.mat-list-base.list-items .mat-list-item.mat-3-line,
.mat-list.mat-list-base.list-items .mat-line-item.mat-list-item-with-avatar.mat-2-line,
.mat-list.mat-list-base.list-items .mat-line-item.mat-list-item-with-avatar.mat-3-line {
    height: 82px;
  }

  .sidebar-header .brand {
    height: 3.8em !important;
  }

  .quick-invoice_buttons {
    height: 30px;
  }
}
@media (min-width: 3000px) {
  body {
    font-size: 1rem;
  }

  .mat-button,
.mat-raised-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button,
.mat-fab,
.mat-mini-fab {
    font-size: 1rem;
  }

  .empty h2 button,
.empty-contain h2 button,
.empty-full-width h2 button,
.empty-full h2 button {
    font-size: 1rem;
  }

  .mat-form-field .mat-input-element,
.mat-form-field .mat-select-value-text,
.mat-form-field .mat-select-placeholder,
.mat-form-field .mat-form-field-label,
.mat-form-field .mat-form-field-infix {
    font-size: 1rem;
  }

  .mat-list .mat-list-item,
.mat-list.mat-list-base .mat-list-item {
    font-size: 1rem;
  }

  .mat-card-subtitle,
.mat-card-content,
.mat-cell,
.mat-footer-cell,
.mat-list .mat-list-item,
.mat-list.mat-list-base .mat-list-item {
    font-size: 1rem;
  }
}
.elite_modal {
  position: relative;
}
.elite_modal .mat-dialog-header h4 {
  font-size: 1em;
}
.elite_modal .mat-dialog-header button {
  z-index: 1;
}
.elite_modal img {
  max-height: 200px;
  width: auto;
}
@media screen and (max-width: 599px) {
  .elite_modal img {
    max-height: 100px;
  }
}
.elite_modal img.shadow {
  -webkit-filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.72));
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.72));
}
.elite_modal .ui-fileupload .ui-button-icon-left,
.elite_modal .ui-fileupload .ui-button-text {
  display: none;
}
.elite_modal .social-row .fa {
  color: #43454e;
}
.elite_modal::-webkit-scrollbar-track {
  border-radius: 0;
  -webkit-box-shadow: none;
  background-color: transparent;
}
.elite_modal::-webkit-scrollbar-thumb {
  border-radius: 20px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.1);
}
.elite_modal::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}
.elite_modal .mat-button,
.elite_modal .mat-raised-button {
  min-width: auto;
}