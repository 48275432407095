@-webkit-keyframes slideInUp {
    from {
      transform: translate3d(0, 100%, 0);
      visibility: visible;
    }
  
    to {
      transform: translate3d(0, 0, 0);
    }
  }
  
  
  @keyframes slideInUp {
    from {
      transform: translate3d(0, 100%, 0);
      visibility: visible;
    }
  
    to {
      transform: translate3d(0, 0, 0);
    }
  }
  
  
  // bounceIn
  
  @-webkit-keyframes bounceIn {
  
    from,
    20%,
    40%,
    60%,
    80%,
    to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
              animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  
    0% {
      opacity: 0;
      transform: scale3d(0.3, 0.3, 0.3);
    }
  
    20% {
      transform: scale3d(1.1, 1.1, 1.1);
    }
  
    40% {
      transform: scale3d(0.9, 0.9, 0.9);
    }
  
    60% {
      opacity: 1;
      transform: scale3d(1.03, 1.03, 1.03);
    }
  
    80% {
      transform: scale3d(0.97, 0.97, 0.97);
    }
  
    to {
      opacity: 1;
      transform: scale3d(1, 1, 1);
    }
  }
  
  @keyframes bounceIn {
  
    from,
    20%,
    40%,
    60%,
    80%,
    to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
              animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  
    0% {
      opacity: 0;
      transform: scale3d(0.3, 0.3, 0.3);
    }
  
    20% {
      transform: scale3d(1.1, 1.1, 1.1);
    }
  
    40% {
      transform: scale3d(0.9, 0.9, 0.9);
    }
  
    60% {
      opacity: 1;
      transform: scale3d(1.03, 1.03, 1.03);
    }
  
    80% {
      transform: scale3d(0.97, 0.97, 0.97);
    }
  
    to {
      opacity: 1;
      transform: scale3d(1, 1, 1);
    }
  }
  
  
  // fadeIn
  
  @-webkit-keyframes fadeIn {
      from {
        opacity: 0;
      }
    
      to {
        opacity: 1;
      }
    }
  
  @keyframes fadeIn {
      from {
        opacity: 0;
      }
    
      to {
        opacity: 1;
      }
    }
  
  // logo animation
  .animate-logo {
  
      &:hover,
      &:focus {
  
          svg #_1 {
              -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
              perspective: 1000;
              transform-origin: bottom right;
              transform-box: fill-box;
              -webkit-animation: hiddenToVisible .4s linear;
              animation: hiddenToVisible .4s linear;
              -webkit-animation-delay: 0;
              animation-delay: 0;
          }
  
          svg #_2 {
              -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
              perspective: 1000;
              transform-origin: bottom right;
              transform-box: fill-box;
              -webkit-animation: hiddenToVisible .4s linear;
              animation: hiddenToVisible .4s linear;
              -webkit-animation-delay: 50ms;
              animation-delay: 50ms;
          }
  
          svg #_3 {
              -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
              perspective: 1000;
              transform-origin: bottom right;
              transform-box: fill-box;
              -webkit-animation: hiddenToVisible .4s linear;
              animation: hiddenToVisible .4s linear;
              -webkit-animation-delay: 100ms;
              animation-delay: 100ms;
          }
  
          svg #_4 {
              -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
              perspective: 1000;
              transform-origin: bottom right;
              transform-box: fill-box;
              -webkit-animation: hiddenToVisible .4s linear;
              animation: hiddenToVisible .4s linear;
              -webkit-animation-delay: 150ms;
              animation-delay: 150ms;
          }
  
          svg #_5 {
              -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
              perspective: 1000;
              transform-origin: bottom right;
              transform-box: fill-box;
              -webkit-animation: hiddenToVisible .4s linear;
              animation: hiddenToVisible .4s linear;
              -webkit-animation-delay: 200ms;
              animation-delay: 200ms;
          }
  
          svg #_6 {
              -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
              perspective: 1000;
              transform-origin: bottom right;
              transform-box: fill-box;
              -webkit-animation: hiddenToVisible .4s linear;
              animation: hiddenToVisible .4s linear;
              -webkit-animation-delay: 250ms;
              animation-delay: 250ms;
          }
  
          svg #_7 {
              -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
              perspective: 1000;
              transform-origin: bottom right;
              transform-box: fill-box;
              -webkit-animation: hiddenToVisible .4s linear;
              animation: hiddenToVisible .4s linear;
              -webkit-animation-delay: 300ms;
              animation-delay: 300ms;
          }
  
          svg #_8 {
              -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
              perspective: 1000;
              transform-origin: bottom right;
              transform-box: fill-box;
              -webkit-animation: hiddenToVisible .4s linear;
              animation: hiddenToVisible .4s linear;
              -webkit-animation-delay: 350ms;
              animation-delay: 350ms;
          }
  
          svg #_9 {
              -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
              perspective: 1000;
              transform-origin: bottom right;
              transform-box: fill-box;
              -webkit-animation: hiddenToVisible .4s linear;
              animation: hiddenToVisible .4s linear;
              -webkit-animation-delay: 400ms;
              animation-delay: 400ms;
          }
      }
  
  }
  
  @-webkit-keyframes hiddenToVisible {
      from {
          transform: scale(0);
      }
  
      to {
          transform: scale(1);
      }
  }
  
  @keyframes hiddenToVisible {
      from {
          transform: scale(0);
      }
  
      to {
          transform: scale(1);
      }
  }

  