.elite_modal {
  position: relative;

  .mat-dialog-header {
    h4 {
      font-size: 1em;
    }

    button {
      z-index: 1;
    }
  }

  img {
    max-height: 200px;
    width: auto;

    @media screen and (max-width: 599px) {
      max-height: 100px;
    }

    &.shadow {
      -webkit-filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.72));
      filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.72));
    }
  }

  .ui-fileupload .ui-button-icon-left,
  .ui-fileupload .ui-button-text {
    display: none;
  }

  .social-row {
    .fa {
      color: $text-color;
    }
  }

  &::-webkit-scrollbar-track {
    border-radius: 0;
    -webkit-box-shadow: none;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.10);
  }

  &::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
  }


  .mat-button,
  .mat-raised-button {
    min-width: auto;
  }
}
