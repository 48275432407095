@media (min-width: 2300px) {
  body {
    font-size: 0.938rem;
  }

  // Needed to custom scale
  .mat-button,
  .mat-raised-button,
  .mat-icon-button,
  .mat-stroked-button,
  .mat-flat-button,
  .mat-fab,
  .mat-mini-fab {
    font-size: 0.938rem;
  }

  // Needed to custom scale
  .empty,
  .empty-contain,
  .empty-full-width,
  .empty-full {
    h2 {
      button {
        font-size: 0.938rem;
      }
    }
  }

  .mat-form-field .mat-input-element,
  .mat-form-field .mat-select-value-text,
  .mat-form-field .mat-select-placeholder,
  .mat-form-field .mat-form-field-label,
  .mat-form-field .mat-form-field-infix {
    font-size: 14px;
  }

  .mat-card-subtitle,
  .mat-card-content,
  .mat-cell,
  .mat-footer-cell,
  .mat-list .mat-list-item,
  .mat-list.mat-list-base .mat-list-item {
    font-size: 0.875rem;
  }

  .mat-list.list-items .mat-list-item.mat-2-line,
  .mat-list.list-items .mat-list-item.mat-3-line,
  .mat-list.list-items .mat-line-item.mat-list-item-with-avatar.mat-2-line,
  .mat-list.list-items .mat-line-item.mat-list-item-with-avatar.mat-3-line,
  .mat-list.mat-list-base.list-items .mat-list-item.mat-2-line,
  .mat-list.mat-list-base.list-items .mat-list-item.mat-3-line,
  .mat-list.mat-list-base.list-items .mat-line-item.mat-list-item-with-avatar.mat-2-line,
  .mat-list.mat-list-base.list-items .mat-line-item.mat-list-item-with-avatar.mat-3-line {
    height: 82px;
  }

  .sidebar-header .brand {
    height: 3.8em !important;
  }

  .quick-invoice_buttons {
    height: 30px;
  }
}

// 4k desktop
@media (min-width: 3000px) {
  body {
    font-size: 1rem;
  }

  // Needed to custom scale
  .mat-button,
  .mat-raised-button,
  .mat-icon-button,
  .mat-stroked-button,
  .mat-flat-button,
  .mat-fab,
  .mat-mini-fab {
    font-size: 1rem;
  }

  // Needed to custom scale
  .empty,
  .empty-contain,
  .empty-full-width,
  .empty-full {
    h2 {
      button {
        font-size: 1rem;
      }
    }
  }

  .mat-form-field .mat-input-element,
  .mat-form-field .mat-select-value-text,
  .mat-form-field .mat-select-placeholder,
  .mat-form-field .mat-form-field-label,
  .mat-form-field .mat-form-field-infix {
    font-size: 1rem;
  }

  .mat-list,
  .mat-list.mat-list-base {

    .mat-list-item {
      font-size: 1rem; // Used to set px for em scaling
    }
  }

  .mat-card-subtitle,
  .mat-card-content,
  .mat-cell,
  .mat-footer-cell,
  .mat-list .mat-list-item,
  .mat-list.mat-list-base .mat-list-item {
    font-size: 1rem;
  }
}
