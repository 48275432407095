html {
  height: 100%
}

::selection {
  background: rgba(146, 52, 79, 0.42);
  /* WebKit/Blink Browsers */
}

::-moz-selection {
  background: rgba(146, 52, 79, 0.42);
  /* Gecko Browsers */
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: #3a3a40;
  // color: #1D3245;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: #43454e;
  line-height: 1.75;
}


h1,
.mat-typography h1 {
  font-size: 2.875rem;
  font-weight: 600;
  text-transform: uppercase;

  @media screen and (max-width: 599px) {
    // font-size: 1.75rem;
    font-size: 2rem;
    
  }
}

h2,
.mat-typography h2 {
  font-weight: 500;
  font-size: 2.125rem;
  margin-bottom: 1.071em;
  text-transform: uppercase;

  @media screen and (max-width: 599px) {
    font-size: 1.625rem;
  }
}

h3,
.mat-typography h3 {
  font-weight: 400;
  font-size: 1.625rem;

  @media screen and (max-width: 599px) {
    font-size: 1.375rem;
  }
}

h4,
.mat-typography h4 {
  font-weight: 500;
  // font-size: 1.357em;
  font-size: 1.250rem;

  @media screen and (max-width: 599px) {
    font-size: 1.125rem;
  }
}

h5,
.mat-typography h5 {
  font-size: 1rem;
  // text-transform: uppercase;
  font-weight: 500;

  @media screen and (max-width: 599px) {
    font-size: 1.125rem;
  }
}

h6,
.mat-typography h6 {
  font-size: 0.929em;
  font-weight: 700;

  @media screen and (max-width: 599px) {
    font-size: 0.929em;
  }
}

// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//   small {
//     font-size: 0.75em;
//   }
// }

p {
  margin-bottom: 1.071em
}

label {
  margin-bottom: auto;
}

small,
.small {
  font-size: 85%;
  font-weight: 500;
  color: inherit;
  word-break: break-word;
  letter-spacing: .0.071em;
}

a:not([href]):not([tabindex]),
a {
  cursor: pointer;
  color: #3262ff;
  transition: all .2s ease-in-out
}

a:not([href]):not([tabindex]):hover,
a:hover {
  color: #3262ff
}

a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):active,
a:focus,
a:active {
  outline: none;
  color: #3262ff
}

img {
  display: block;
  max-width: 100%;
  height: auto
}

/* Lists---------------------------------- */

ul {
  list-style: square
}

/* 2 Layout----------------------------------------------------------------------------- */

/* Miscellaneous---------------------------------- */

.text-primary {
  color: #43454e;
}

.d-block {
  display: block !important;
}

.text-thin {
  font-weight: 300;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.no-padding {
  padding: 0 !important;
}

.font-weight-300 {
  font-weight: 300 !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.pr {
  position: relative;
}

.prt--01 {
  position: relative;
  top: -1px !important;
}

.prt--02 {
  position: relative;
  top: -2px !important;
}

.prt--03 {
  position: relative;
  top: -3px !important;
}

.prt--04 {
  position: relative;
  top: -4px !important;
}

.prt--05 {
  position: relative;
  top: -5px !important;
}

.prt--06 {
  position: relative;
  top: -6px !important;
}

.prt--07 {
  position: relative;
  top: -7px !important;
}

.prt-01 {
  position: relative;
  top: 1px !important;
}

.prt-02 {
  position: relative;
  top: 2px !important;
}

.prt-03 {
  position: relative;
  top: 3px !important;
}

.prt-04 {
  position: relative;
  top: 4px !important;
}

.prt-05 {
  position: relative;
  top: 5px !important;
}

.prt-06 {
  position: relative;
  top: 6px !important;
}

.prt-07 {
  position: relative;
  top: 7px !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-5 {
  padding-left: 0.3125rem !important;
}

.pl-10 {
  padding-left: 0.625rem !important;
}

.pl-15 {
  padding-left: 0.9375rem !important;
}

.pl-20 {
  padding-left: 1.25rem !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pr-5 {
  padding-right: 0.3125rem !important;
}

.pr-10 {
  padding-right: 0.625rem !important;
}

.pr-15 {
  padding-right: 0.9375rem !important;
}

.pr-20 {
  padding-right: 1.25rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-10 {
  padding: 0.625rem !important;
}

.p-20 {
  padding: 1.25rem !important;
}

.p-30 {
  padding: 1.875rem !important;
}

.p-40 {
  padding: 2.5rem !important;
}

.ph-05 {
  padding-left: 0.3125rem !important;
  padding-right: 0.3125rem !important;
}

.ph-15 {
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}

.ph-20 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.ph-30 {
  padding-left: 1.875rem;
  padding-right: 1.875rem;
}

.ph-40 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.ph-10-sm {
  @media screen and (max-width: 1025px) {
    padding-left: 0.625rem !important;
    padding-right: 0.625rem !important;
  }
}

.ph-05-sm {
  @media screen and (max-width: 1025px) {
    padding-left: 0.3125rem !important;
    padding-right: 0.3125rem !important;
  }
}

.pv-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.pv-05 {
  padding-top: 0.3125rem !important;
  padding-bottom: 0.3125rem !important;
}

.pv-10 {
  padding-top: 0.625rem !important;
  padding-bottom: 0.625rem !important;
}

.pv-15 {
  padding-top: 0.938rem !important;
  padding-bottom: 0.938rem !important;
}

.pv-20 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.pv-30 {
  padding-top: 1.875rem !important;
  padding-bottom: 1.875rem !important;
}

.pv-40 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-05 {
  margin-top: 0.3125rem !important;
}

@media (max-width: 599px) {
  .mt-05-sm {
    margin-top: 0.3125rem !important;
  }
}

.mb-05 {
  margin-bottom: 0.3125rem !important;
}

.mt-10 {
  margin-top: 0.625rem !important;
}

.mb-10 {
  margin-bottom: 0.625rem !important;
}

.mt-20 {
  margin-top: 1.25rem !important;
}

.mb-20 {
  margin-bottom: 1.25rem !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-5 {
  margin-top: 0.3125rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-15 {
  margin-bottom: 0.9375rem !important;
}

.mt-15 {
  margin-top: 0.9375rem !important;
}

.mb-30 {
  margin-bottom: 1.875rem !important;
}

.mt-30 {
  margin-top: 1.875rem !important;
}

.mb-40 {
  margin-bottom: 2.5rem !important;
}

.mt-40 {
  margin-top: 2.5rem !important;
}

.mb-50 {
  margin-bottom: 3.125rem !important;
}

.mt-50 {
  margin-top: 3.125rem !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-05 {
  margin-left: 0.3125rem !important;
}

.ml-15 {
  margin-left: 0.9375rem !important;
}

.ml-30 {
  margin-left: 1.875rem !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-05 {
  margin-right: 0.3125rem !important;
}

.mr-10 {
  margin-right: 0.625rem !important;
}

.mr-15 {
  margin-right: 0.9375rem !important;
}

.mr-30 {
  margin-right: 1.875rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-05 {
  padding-top: 5px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-15 {
  padding-top: 0.9375rem !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-05 {
  padding-bottom: 5px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-15 {
  padding-bottom: 0.9375rem !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.mw-305 {
  min-width: 19.0625rem;
}

.maxw-315 {
  max-width: 19.6875rem;
}

.maxw-450 {
  max-width: 28.125rem;
}

.margin-clear {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.circle {
  border-radius: 100% !important;
}

/* Tabs---------------------------------- */

@media (min-width:481px) and (max-width:767px) {
  .nav-tabs>li>a {
    padding: 0.857em 0.714em;
    font-size: 0.786em;
    margin-right: 0;
  }
}

@media (max-width:480px) {
  .nav-tabs {
    background-color: #eaeaea;
    border-bottom: none !important;
  }

  .nav-tabs>li {
    float: none;
  }

  .nav-tabs>li a {
    color: #fff;
    margin-right: 0;
    border-radius: 0 !important;
  }

  .nav-tabs>li a:after,
  .nav-tabs>li a:before {
    border-color: transparent transparent transparent transparent !important;
  }
}

/* Forms---------------------------------- */

textarea {
  resize: vertical;
}

/*Images and Overlays---------------------------------- */
