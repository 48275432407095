.mat-elevation-z0 {
  box-shadow: none !important;
}

.mat-elevation-z05 {
  box-shadow: 0 0px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 0px 0 rgba(0, 0, 0, 0.12);
}

// --------------------------------------------
// --------------------------------------------
// --------------------------------------------
// The Mortgage Partner custom Angular Material stylesheet
// Copyright © The Mortgage Partner 2019
// --------------------------------------------
// --------------------------------------------
// --------------------------------------------
// variables are located in styles/_variables.scss

// Table of contents
// 1. Elevations
// 2. Scrollbar
// 3. Mat-Checkbox
// 4. Mat-Form-Field
// 5. Inputs
// 6. Mat-Progress-Bar
// 7. Help Stepper
// 8. Modals
// 9. Mat-Radio-Button
// 10. Material-Icons
// 11. Expansion-Panel
// 12. Mat-Tooltip
// 13. Mat-Menu
// 14. Mat-Snack-Bar
// 15. Mat-Buttons (all types)
// 16. Mat-Drawer
// 17. Mat-Spinner
// 18. Mat-Select
// 19. Mat-Card
// 20. Mat-List
// 21. Mat-Grid
// 22. Mat-Chip
// 23. Mat-Button-Toggle
// 24. Mat-Toolbar
// 25. Mat-Table
// 26. Mat-Tabs
// 27. Mat-Controls (custom)
// 28. Mat-Bottom-Sheet
// 29. Mat-Tree
// 30. Mat-Badge

// =============================================
// ===================   1   ===================
//                  Elevations
// =============================================

// =============================================
// ===================   2   ===================
//                  Scrollbar
// =============================================

.mat-bottom-sheet-container,
.mat-drawer-inner-container,
.mat-drawer-content,
.mat-select-panel,
.cdk-global-scrollblock {

  &::-webkit-scrollbar-track {
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 1.429em;
    box-shadow: inset 0 0 0.429em rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.2);
  }

  &::-webkit-scrollbar {
    height: 0.357em;
    width: 0.357em;
    background-color: transparent;
  }
}

// =============================================
// ===================   3   ===================
//                  Mat-Checkbox
// =============================================

.mat-checkbox {
  background-color: transparent;
  font-family: Roboto, Arial, sans-serif;

  .mat-checkbox-layout {
    word-break: break-word;
    white-space: normal;

    .mat-checkbox-label {
      line-height: 1.250em;
      font-weight: 400;
    }
  }

  &.checkbox-error {
    color: #c44a46;
  }
}

// =============================================
// ===================   4   ===================
//                  Mat-Form-Field
// =============================================

.mat-form-field {
  font-weight: 400;

  .mat-form-field-prefix,
  .mat-form-field-suffix {
    padding-right: 0.357em;
  }

  &.mat-form-field_elevated-lg {
    .mat-form-field-flex {
      .mat-form-field-outline {
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
        border-radius: 0.357em;
      }
    }
  }

  &.mat-form-field_elevated-sm {
    .mat-form-field-flex {
      .mat-form-field-outline {
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
        border-radius: 0.357em;
      }
    }
  }

  &.mat-hint_no_padding {
    .mat-form-field-subscript-wrapper {
      padding: 0 !important;
    }
  }

  &.mat-elevation-input-z2 {
    .mat-form-field-outline {
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
      border-radius: 0.357em;
    }
  }

  &.mat-form-field_no-space {
    .mat-form-field-wrapper {
      margin: 0;
      padding-bottom: 0;
    }
  }
}


// =============================================
// ===================   5   ===================
//                  Inputs
// =============================================

// ===============
// ===== 5.1 =====
// Datepicker
// ===============

.mat-form-field {
  .mat-form-field-suffix {
    .tap-date-toggle {
      font-size: 1.142em;
      top: -2.143em;
    }
  }
}

.mat-form-field {
  &.success:not(.ng-invalid) {

    .mat-form-field-outline-start,
    .mat-form-field-outline-end,
    &.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-outline-gap,
    &.mat-form-field-appearance-outline .mat-form-field-outline-gap,
    &.mat-form-field-outline-thick .mat-form-field-outline-gap {
      border-color: #228264;
      background: rgba(38, 145, 112, .045);
    }

    &.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-outline-gap {
      border-top-color: transparent;
    }

    &.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
    &.mat-focused.mat-primary .mat-select-arrow {
      color: #228264;

      .mat-form-field-required-marker {
        color: #228264;
      }
    }

    &.mat-form-field-disabled {

      .mat-form-field-outline-start,
      .mat-form-field-outline-end,
      &.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-outline-gap,
      &.mat-form-field-appearance-outline .mat-form-field-outline-gap,
      &.mat-form-field-outline-thick .mat-form-field-outline-gap {
        border-color: #777777;
        background: rgba(77, 77, 77, .045);
      }

      &.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-outline-gap {
        border-top-color: transparent;
      }

      &.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
      &.mat-focused.mat-primary .mat-select-arrow {
        color: #777777;
      }
    }
  }

  &.mat-form-field-invalid {

    .mat-form-field-ripple {
      background-color: #c44a46;
    }

    .mat-form-field-label {
      color: #c44a46;

      .mat-form-field-required-marker {
        color: #c44a46;
      }
    }

    .mat-error {
      color: #c44a46;
    }
  }

  &.mat-form-field_sm {

    &.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
    &.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label {
      transform: translateY(-1.49375em) scale(0.75);
    }

    .mat-form-field-wrapper {
      padding-bottom: 1.14375em;
    }

    &.mat-form-field_no-space {
      .mat-form-field-wrapper {
        margin: 0;
        padding-bottom: 0;
      }
    }

    // .mat-form-field-flex {
    //   max-height: 46px;
    // }

    .mat-form-field-infix {
      // padding: 0.714em 0;
      padding: 0.607em 0;
    }

    .mat-form-field-prefix,
    .mat-form-field-suffix {
      top: 9px;
    }

    .mat-form-field-label {
      top: 1.6em;
    }

    .mat-form-field-prefix {

      @media screen and (max-width: 599px) {
        top: 0;
      }

      .mat-icon {
        font-size: 120%;
        line-height: 1;
      }
    }

    @media screen and (max-width: 599px) {
      top: 0;
    }

    .mat-select {
      position: relative;
      top: -0.54375em;

      .mat-select-arrow-wrapper {
        transform: translateY(0%);
      }

      // .payIcon {
      //   height: 1.786em;
      //   width: 1.786em;
      // }
    }

    &.mat-form-field_sm-select {
      .mat-form-field-infix {
        padding: 0.786em 0 0.429em 0;

        .mat-select {
          top: -0.14375em;

          // .mat-select-trigger {
          //   position: relative;
          //   top: 10px;
          // }
        }
      }
    }

    .mat-standard-chip {
      margin: 0.143em 0.286em;
      // min-height: 1.75em;
    }
  }

}

.mat-input-infix {
  font-size: 0.938rem;

  // USED TO MAKE iOS NOT ZOOM IN ON INPUT WHEN TOUCHED
  // @media (max-width: 991px) {
  //   font-size: 1.143em;
  // }
}

.mat-focused .mat-input-infix {
  font-size: 0.938rem;

  // USED TO MAKE iOS NOT ZOOM IN ON INPUT WHEN TOUCHED
  // @media (max-width: 991px) {
  //   font-size: 1.143em;
  // }
}

.mat-form-field {
  color: #43454e;
  font-size: 0.938rem;

  &.no-prefix-padding {
    .mat-form-field-prefix {
      padding: 0;
    }
  }

  &.mat-form-field-appearance-outline {

    .mat-form-field-prefix,
    .mat-form-field-suffix {

      top: 2px;

      .mat-datepicker-toggle,
      .mat-datepicker-content .mat-calendar-next-button,
      .mat-datepicker-content .mat-calendar-previous-button {
        color: #43454e;
      }
    }
  }

  .mat-input-element,
  .mat-select-value-text,
  .mat-select-placeholder,
  .mat-form-field-label,
  .mat-form-field-infix {
    // font-size: 1rem;    
    font-size: 0.938rem;
    font-weight: 400;
    line-height: 1.286em;
    caret-color: currentColor;
    color: currentColor;
  }

  .mat-input-element,
  .mat-select-value-text {
    @media screen and (max-width: 599px) {
      // font-size: 1.143em;
      font-size: 0.938rem;
      font-weight: 400;
    }
  }

  .mat-input-element:disabled {
    @media screen and (max-width: 991px) {
      color: rgba(0, 0, 0, 0.68);
      /* required on iOS */
      -webkit-text-fill-color: #43454e;
      opacity: 1;
    }
  }
}

// ===============
// ===== 5.2 =====
// mat-autocomplete
// ===============

.mat-autocomplete-panel {
  .mat-option {
    font-size: 0.938rem;
  }
}


// ===============
// ===== 5.2 =====
// searchbar
// ===============

.mat-form-field {
  &.searchbar {
    width: 100%;

    .mat-form-field-wrapper {
      padding-bottom: 0 !important;
    }
  }
}



// =============================================
// ===================   6   ===================
//                  progress-bar
// =============================================

.mat-progress-bar {
  border-radius: 8px;
  min-height: 0.375rem;

  .mat-progress-bar .mat-progress-bar-element {
    height: 10px;
    border-radius: 8px;
  }

  svg {
    &.mat-progress-bar-background {
      background-repeat: repeat-x !important;
      background-size: 0.714em 0.286em !important;
      -webkit-animation: none !important;
      animation: none !important;

      defs,
      pattern {
        display: none !important;
      }
    }
  }

  &.loading-bar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    min-height: 0.286em;
    z-index: 10;
  }

  &.mat-progress-bar_colored {
    border-radius: 0.571rem;


    .mat-progress-bar-buffer {
      background-color: transparent;
      border: 0.071rem solid rgba(0, 0, 0, .24);
      border-radius: 0.571rem;
    }

    &:nth-of-type(1) {
      .mat-progress-bar-fill::after {
        background: linear-gradient(to right, #aa99ff, #e09cff);
        background: linear-gradient(to left, #00706c, #99c5c4);
      }
    }

    &:nth-of-type(2) {
      .mat-progress-bar-fill::after {
        background: linear-gradient(to right, #aa99ff, #e09cff);
        background: linear-gradient(to right, #b452ff, #6426e0);
      }
    }

  }

  &.mat-success {
    .mat-progress-bar-fill::after {
      background-color: #4e9b83 !important;
    }
  }
}

.mat-card {
  .mat-progress-bar {

    &.loading-bar {
      border-radius: 4px 4px 0 0;
    }
  }
}


// =============================================
// ===================   7   ===================
//                  Help Stepper
// =============================================


.stepper {
  position: relative;

  .mat-horizontal-content-container {
    .mat-horizontal-stepper-content {
      transition-duration: .25s;
    }
  }

  .mat-horizontal-stepper-header-container {
    background: #ffffff;
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    z-index: 500;
    display: flex;
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin-right: 0;
    margin-left: 0;
    list-style: none;

    .mat-horizontal-stepper-header {
      height: 1.143em;
      padding: 0;

      .mat-step-icon,
      .mat-step-icon-not-touched {
        display: none;
      }

      .mat-step-label {
        width: 2.143em;
        height: 0.214em;
        margin-right: 0.214em;
        margin-left: 0.214em;
        border-radius: 0;
        background-color: rgba(0, 0, 0, .46);

        &.mat-step-label-selected {
          background-color: rgba(0, 0, 0, .72);
        }
      }
    }

    .mat-stepper-horizontal-line {
      display: none;
    }
  }

  .mat-horizontal-content-container {
    overflow: hidden;
    padding: 0;
  }

  &.no-mobile-indicators {
    @media screen and (max-width: 599px) {
      .mat-horizontal-stepper-header-container {
        margin-top: 0;

        .mat-horizontal-stepper-header {
          display: none;
        }
      }

      .modal-body {
        padding-top: 1.071rem;
      }
    }
  }

  .mat-stepper-horizontal {

    &.mat-dialog_color-black {
      background-color: #333C44 !important;

      h2,
      h4,
      p,
      .mat-button {
        color: #e5e5e5;
      }

      .mat-raised-button {
        color: #333C44;
      }

      .mat-horizontal-stepper-header-container,
      .mat-dialog-content,
      .mat-dialog-actions {
        background: transparent !important;
      }
    }

    &.mat-dialog_color-0 {
      background: #eebd2a !important;

      * {
        color: #56481c;
      }

      .mat-horizontal-stepper-header-container,
      .mat-dialog-content,
      .mat-dialog-actions {
        background: transparent !important;
      }
    }

    &.mat-dialog_color-1 {
      background: #5456a2 !important;

      h4,
      p,
      .mat-button {
        color: #ebecff;
      }

      .mat-raised-button {
        color: #5456a2;
      }

      .mat-horizontal-stepper-header-container,
      .mat-dialog-content,
      .mat-dialog-actions {
        background: transparent !important;
      }
    }

    &.mat-dialog_color-2 {
      background: #b3d0ff !important;

      * {
        color: #2c4c81;
      }

      .mat-horizontal-stepper-header-container,
      .mat-dialog-content,
      .mat-dialog-actions {
        background: transparent !important;
      }
    }

    &.mat-dialog_color-3 {
      background: #b3d0ff !important;

      * {
        color: #2c4c81;
      }

      .mat-horizontal-stepper-header-container,
      .mat-dialog-content,
      .mat-dialog-actions {
        background: transparent !important;
      }
    }
  }
}

.no-indicators {
  .mat-horizontal-stepper-header-container {
    margin-top: 0;

    .mat-horizontal-stepper-header {
      display: none;
    }
  }
}

.mat-horizontal-content-container_no_padding_sm {
  .mat-horizontal-content-container {
    @media screen and (max-width: 599px) {
      padding: 0
    }
  }
}

.account-selection {
  .mat-stepper-horizontal-line {
    border-top-width: 0;
  }

  .mat-horizontal-content-container {
    padding-bottom: 0;
  }
}

.mat-stepper-horizontal,
.mat-stepper-vertical {
  background: transparent !important;
}

// ===============
// ===== 13.1 =====
// Account Progress
// ===============

.account-progress_body {
  .mat-vertical-content {
    padding: 0 0em 1.714em 1.714em;
  }
}

// =============================================
// ===================   8   ===================
//                  Modals
// =============================================


.cdk-overlay-container {
  z-index: 2147483640; // To override LiveChat
  // background: rgba(33,33,33,.4); // BACKDROP HERE

  .cdk-global-overlay-wrapper {
    .cdk-overlay-pane {

      // max-width: 97vw !important;
      /* smartphones, touchscreens */
      @media (max-width: 575px) {
        min-width: 95vw;
      }

      .mat-bottom-sheet-container {

        @media (hover: none) and (pointer: coarse) {
          min-height: -webkit-fill-available;
        }

        @media screen and (max-width: 1023px) {
          /* mobile viewport bug fix */
          min-height: -webkit-fill-available;
        }

        @media (max-width: 575px) {
          min-width: 95vw;
          min-height: -webkit-fill-available;
        }

      }
    }
  }

  .mat-drawer-container,
  .mat-dialog-container {
    padding: 0;
    background-color: #ffffff;

    .mat-dialog-header {
      padding-left: 1.071rem;
      background-color: #ffffff;

      h4 {
        margin-bottom: 0;
      }
    }

    .mat-drawer-content,
    .mat-dialog-content {
      background-color: #ffffff;
      touch-action: manipulation;

      &::-webkit-scrollbar-track {
        border-radius: 0;
        box-shadow: none;
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 1.429em;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        background-color: rgba(0, 0, 0, 0.2);
      }

      &::-webkit-scrollbar {
        height: 0.357em;
        width: 0.357em;
        background-color: transparent;
      }
    }

    .mat-dialog-actions {
      padding: 0.571rem 1.071rem;
      margin-bottom: 0;
      background-color: #ffffff;
    }

    .mat-button-toggle-group {
      font-size: 90%;
      font-weight: 500;
    }
  }

  .grid-container_modal {
    .drawer-list {
      h2 {
        top: -1.714em !important;
        z-index: 1;
      }
    }
  }
}


.modal-header_title,
.invoice-header_title {

  .mat-form-field {
    font-size: 1rem;

    &:not(.mat-form-field-hide-placeholder) {

      .mat-input-element::-webkit-input-placeholder {
        color: rgba(255, 255, 255, 0.76) !important;
      }

      .mat-input-element:-ms-input-placeholder {
        color: rgba(255, 255, 255, 0.76) !important;
      }

      .mat-input-element::-ms-input-placeholder {
        color: rgba(255, 255, 255, 0.76) !important;
      }

      .mat-input-element::-moz-placeholder {
        color: rgba(255, 255, 255, 0.76) !important;
      }

      .mat-input-element::placeholder {
        color: rgba(255, 255, 255, 0.76) !important;
      }

      .mat-input-element:-ms-input-placeholder {
        color: rgba(255, 255, 255, 0.76) !important;
      }

      .mat-input-element::-moz-placeholder {
        color: rgba(255, 255, 255, 0.76) !important;
      }

      .mat-input-element:-moz-placeholder {
        color: rgba(255, 255, 255, 0.76) !important;
      }

      .mat-input-element::-webkit-input-placeholder {
        color: rgba(255, 255, 255, 0.76) !important;
      }
    }

    .mat-input-element,
    .mat-select-element {
      caret-color: $white;
    }

    .mat-form-field-underline {
      background-color: $white;
    }

    &.mat-focused .mat-form-field-label .mat-form-field-required-marker {
      color: $white;
    }

    .mat-form-field-label,
    .mat-select-subscript-wrapper {
      color: $white;
      font-size: 1.071rem;

      @media screen and (max-width: 599px) {
        font-size: 1rem;
      }
    }

    .mat-select-trigger,
    .mat-form-field.mat-focused.mat-primary {

      .mat-select-arrow,
      .mat-select-value {
        color: $white;
      }

      .mat-select-ripple {
        background-color: $white;
      }
    }


    &.mat-form-field-appearance-outline {
      .mat-form-field-outline {
        color: rgba(255, 255, 255, 0.48);
      }

      .mat-form-field-outline-thick {
        color: $white;
      }

      &.mat-form-field-invalid.mat-form-field-invalid {
        .mat-form-field-outline-thick {
          color: #681310;
        }
      }

    }

    &.mat-form-field-invalid {

      .mat-form-field-ripple {
        background-color: #681310;
      }

      .mat-form-field-label {
        color: #681310;

        .mat-form-field-required-marker {
          color: #681310;
        }
      }

      .mat-error {
        background: #fff;
        color: #681310;
        padding: 0.143em 0.214em;
        font-size: 1.3em;
        border-radius: 4px;
      }
    }

    &.mat-focused {

      .mat-form-field-label,
      .mat-select-arrow,
      .mat-select-value {
        color: $white;
      }


      .mat-form-field-ripple,
      .mat-select-ripple {
        background-color: $white;
      }
    }

    .mat-input-element:disabled {
      color: rgba(255, 255, 255, 0.78);
    }
  }
}

// =============================================
// ===================   9   ===================
//                  Mat-Radio-Button
// =============================================

// Checkout-radio

.checkout-radio {

  .mat-radio-label {
    width: 100%;

    .mat-radio-label-content {
      width: 100%;

      h4 {
        &.payAmount {
          text-align: right;
          white-space: normal;

          small {
            width: 100%;
            text-align: right;
          }
        }

        .payAmount {
          text-align: right;
          white-space: normal;
        }
      }

      p {
        white-space: normal;
      }
    }
  }

  .mat-card-radio {
    margin-bottom: 0.714em;

    .mat-radio-label {
      padding: 1.143em;
      border-radius: 4px;
      background: white;
      box-shadow: none;

      &:hover,
      &:focus {
        background-color: rgba(0, 0, 0, 0.02);
      }
    }

    &.mat-radio-checked {
      .mat-radio-label {
        // box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
        border: 1px solid rgba(0, 0, 0, 0.18);
        border-radius: 8px;
      }
    }

    &.err {
      .mat-radio-label {
        border: 0.143em solid $Rejected;
      }
    }

    &.mat-card-radio_hide-circle {
      .mat-radio-container {
        display: none;
      }
    }
  }
}

.mat-radio_wrap_label {
  .mat-radio-label-content {
    white-space: normal;
  }
}

.mat-radio-group_vertical {
  .mat-radio-label {
    flex-direction: column;
    width: 100%;

    .mat-radio-container {
      margin-bottom: 0.5em;
    }
  }

  .mat-radio-label-content {
    padding-left: 0;
    width: calc(100% - 1.071rem);
  }

}

// =============================================
// ===================   10   ===================
//                  Material-Icons
// =============================================

.material-icons,
.material-icons-outlined,
.material-icons-two-tone,
.material-icons-round,
.material-icons-sharp {
  &.mat-warn {
    color: $Elite-Danger;
  }

  &.mat-success {
    color: #228264;
  }

  &.Started {
    color: #FFB38B;
  }

  &.In.Underwriting {
    color: #DAD8A7;
  }

  &.Items.Needed,
  &Needed {
    color: #FF5B4A;
  }

  &.Ready.To.Close {
    color: #7FC7AF;
  }

  &.Disabled,
  &.Archived {
    color: #a1a2a6;
  }

  &.Funded {
    color: #3FB8AF;
  }




  // Material-Icons Single line align fix
  &.mat-single-align {
    position: relative;
    top: 0.357em;
  }

  &.mat-1rem {
    font-size: 1rem !important;
    height: 1rem !important;
    width: 1rem !important;
  }

  &.mat-14 {
    font-size: 14px !important;
    height: 14px !important;
    width: 14px !important;
  }

  &.mat-16 {
    font-size: 16px !important;
    height: 16px !important;
    width: 16px !important;
  }

  &.mat-18 {
    font-size: 18px !important;
    height: 18px !important;
    width: 18px !important;
  }

  &.mat-24 {
    font-size: 24px !important;
    height: 24px !important;
    width: 24px !important;
  }

  &.mat-30 {
    font-size: 30px !important;
    height: 30px !important;
    width: 30px !important;
  }

  &.mat-36 {
    font-size: 36px !important;
    height: 36px !important;
    width: 36px !important;
  }

  &.mat-48 {
    font-size: 48px !important;
    height: 48px !important;
    width: 48px !important;
  }

  &.mat-64 {
    font-size: 64px !important;
    width: 64px !important;
    height: 64px !important;
  }

  &.tooltip-helper {
    font-size: 22px;
    width: 22px;
    height: 22px;
    position: relative;
    top: 0.286em;

    &.mat-18 {
      font-size: 1.286em;
      width: 1.286em;
    }
  }

  &.lock {
    position: relative;
    top: 0.286em;
    margin-right: 0.357em;
  }

  &.mat-card-floating-icon {
    position: absolute;
    font-size: 34px;
    height: 66px;
    width: 66px;
    top: -24px;
    border-radius: 36px;
    padding: 16px;
    background: #fff;
    border-top: 1px solid rgba(0, 0, 0, .12);
    // box-shadow: 0px -8px 6px -7px rgba(0, 0, 0, .1);
  }
}

// =============================================
// ===================   11   ===================
//                  Expansion-Panel
// =============================================

.mat-expansion-panel {
  color: #43454e;

  .mat-expansion-panel-content,
  .mat-expansion-panel-body {
    width: 100%;
  }
}

@media (max-width: 450px) {
  mat-expansion-panel .mat-expansion-panel-header {
    padding: 0 1.071rem 0 1.071rem;
  }

  mat-expansion-panel .mat-expansion-panel-body {
    padding: 0 0.714em 1.143em;
  }
}

.no-padding-expansion {
  .mat-expansion-panel-body {
    padding: 0;
  }
}

.mat-expansion-panel-header {
  font-size: 1rem;
  align-items: center;

  .mat-expansion-panel-header-description,
  .mat-expansion-panel-header-title,
  .mat-expansion-indicator::after {
    font-size: 1rem;
    color: #43454e;
  }
}

// Side nav

.nav-expansion-accordion {
  z-index: 2;

  .mat-expansion-panel {
    border-radius: 0 !important;
    box-shadow: none;

    &:not(.mat-expanded) {
      .mat-expansion-panel-header {
        max-height: 2.557em;

        @media screen and (min-width:1921px) {
          max-height: 2.818em;
        }

        @media screen and (max-width:599px) {
          max-height: 2.839em;
        }
      }
    }

    &.mat-expanded {
      .mat-expansion-panel-header {
        max-height: 2.788em;
        // color: #5456a2;
        // background: #d5d6e8;
        border-right: none;
        font-weight: 600;
        // background: linear-gradient(58deg, #5456a2 0, #7890c7 100%);
        margin-right: 7px;
        border-radius: 0 18px 18px 0;
        // box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

        // .mat-expansion-indicator:after,
        // * {
        //   color: #5456a2;
        // }
      }
    }

    .mat-expansion-panel-header {
      padding: 0 1.071rem 0 0;

      .mat-button-focus-overlay {
        background: transparent;
      }

      .mat-expansion-indicator {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &:hover,
      &:focus {
        a {
          color: #43454e;
        }
      }
    }

    .mat-expansion-panel-content {
      .mat-expansion-panel-body {
        padding: 0.357em 0;

        li {
          min-height: 2.429em;

          a {
            padding: 0 1.143em;
            padding-left: 1.786em;
          }
        }
      }
    }
  }
}

.nav-expansion-accordion {
  .mat-expansion-panel {
    background: #ffffff;
  }

  &.last {
    .mat-expansion-panel {
      border-radius: 0.571rem !important;
      border-top-right-radius: 0 !important;
      border-top-left-radius: 0 !important;
      padding-bottom: 0.143em;
      // box-shadow: -1px 2px 3px -1px rgba(0, 0, 0, 0.29), 1px 5px 5px -4px rgba(0, 0, 0, 0.29);
      box-shadow: none;
    }
  }
}

.mat-accordion_no-padding {
  .mat-expansion-panel-body {
    padding: 1.143em 0;
  }
}

.mat-accordion_no-padding-header {
  .mat-expansion-panel-header {
    padding: 0px 5px;
  }
}


// =============================================
// ===================   12   ===================
//                  Mat-Tooltip
// =============================================

.mat-tooltip {
  font-size: 0.929em;
  padding-top: 0.429em;
  padding-bottom: 0.429em;
  background: #43454e !important;
  opacity: 1;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
}


// =============================================
// ===================   13   ===================
//                  Mat-Menu
// =============================================

// ===============
// ===== 13.1 =====
// Menu Dropdown
// ===============

.mat-menu-panel {
  &.mat-menu-panel_lg {
    min-width: 220px;
    max-width: 400px;

    @media screen and (max-width: 420px) {
      max-width: 95vw;
    }
  }

  .mat-menu-content {
    a {
      &:hover {
        text-decoration: none;
      }
    }

    .mat-menu-item {
      font-size: 0.938rem;
      line-height: 3.429em;
      height: 3.429em;

      span {
        color: $text-color;
      }
    }

    .mat-menu-item .mat-icon-no-color,
    .mat-menu-item-submenu-trigger::after {
      color: rgba(0, 0, 0, 0.87);
    }
  }
}

// =============================================
// ===================   14   ===================
//                  Mat-Snack-Bar
// =============================================


.mat-snack-bar-container {
  height: auto;
  background: #6D9E60 !important;
  background: linear-gradient(to right, #228264, #4E9B83) !important;
  color: $white !important;
  font-size: 14px;
  line-height: 20px;

  .mat-simple-snackbar-action {
    height: auto;
    color: $white !important;
  }

  &.info {
    background: #19212b !important;
    color: #fafafa;

    button {
      color: #8ab4f8;

      &:hover {
        background: rgba(138, 180, 248, .12) !important;
      }
    }
  }

  &.warning {
    background: #f37032 !important;
  }

  &.error {
    background: $Elite-Danger !important;
  }

  .mat-progress-bar-background {
    top: 0.375em;
    -webkit-animation-play-state: paused !important;
    animation-play-state: paused !important;
  }
}

// =============================================
// ===================   15   ===================
//                  Mat-Button
// =============================================
.mat-stroked-button {
  &:not(.mat-button-disabled) {
    &.mat-primary {
      border-color: #92344f;
    }
  }
}

.mat-button,
.mat-raised-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button,
.mat-fab,
.mat-mini-fab {
  font-size: 1rem;
  font-size: 16px;



  &.mat-gradient {
    color: #ffffff;
    background: #396ea5;
    // background: -webkit-gradient(linear, left top, right top, from(#3794af), to(#37af8f));
    // background: linear-gradient(to right, #3794af, #37af8f);
    background: linear-gradient(to bottom right, #396ea5 0%, #7890c7);

    &:hover,
    &:focus {
      // background: -webkit-gradient(linear, left top, right top, from(#056d8c), to(#3794af));
      // background: linear-gradient(to right, #056d8c, #3794af);
      background: linear-gradient(to bottom right, darken(#396ea5, 5%), lighten(#396ea5, 5%));
    }

    .mat-icon {
      position: relative;
      top: 0.143em;
    }

    &:disabled {
      background: rgba(0, 0, 0, 0.12);
    }
  }

  &.mat-purple-gradient {
    color: #ffffff;
    background: #5456a2;
    background: linear-gradient(58deg, #5456a2 0, #7890c7 100%);

    &:hover,
    &:focus {
      background: linear-gradient(58deg, #5456a2 0, #6566ab 100%);
    }

    .mat-icon {
      position: relative;
      top: 0.143em;
    }

    &:disabled {
      background: rgba(0, 0, 0, 0.12);
    }
  }

  &.mat-white {
    color: $white;
    line-height: 32px !important;

    &:hover,
    &:focus {
      background: $white;
      color: $text-color;
      transition: all .25s ease-in-out;
    }

    &.with-border {
      border: 2px solid $white;
    }
  }

  &.mat-black {
    background: $white;
    color: $text-color;
    transition: all .25s ease-in-out;
    line-height: 38px !important;

    &:hover,
    &:focus {
      background: $text-color;
      color: $white;
      transition: all .25s ease-in-out;
    }

    &.with-border {
      border: 2px solid $text-color;
    }
  }

  .extended-button {
    width: unset;
    padding: 0 26px;
    border-radius: 29px;

    .mat-icon {
      margin-right: 10px;
    }

    .extended-button {
      font-weight: 300;
      letter-spacing: .1rem;
      text-transform: uppercase;
    }
  }

  &.drawer-btn,
  &.drawer-close {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }

  &.mat-button_sm {
    padding: 5px;
  }
}

.mat-raised-button {

  &:hover,
  &:focus {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    transition: all .25s ease-in-out;
  }
}


.mat-icon-button {
  min-width: 40px;
  min-height: 40px;

  &:hover,
  &:focus {
    background: rgba(0, 0, 0, .06);
    transition: all .25s ease-in-out;
  }
}


// .mat-raised-button,
// .mat-flat-button,
// .mat-fab,
// .mat-mini-fab {
//   &.mat-primary {
//     color: #ffffff;
//     background: #5456a2;
//     background: linear-gradient(58deg, #5456a2 0, #7890c7 100%);

//     &:hover,
//     &:focus {
//       background: linear-gradient(58deg, #5456a2 0, #6566ab 100%);

//       &:disabled {
//         color: rgba(0, 0, 0, 0.26);
//         background: rgba(0, 0, 0, 0.12);
//       }
//     }

//     &:disabled {
//       color: rgba(0, 0, 0, 0.26);
//       background: rgba(0, 0, 0, 0.12);
//     }
//   }

// }

.mat-icon-button-grow {
  display: flex;
  min-width: 40px;
  align-items: center;


  .icon-text {
    max-width: 0;
    display: inline-flex;
    border: 2px solid transparent;
    border-left: 0;
    margin-left: -15px;
    height: 31px;
    line-height: 27px;
    border-radius: 0 8px 8px 0;
    white-space: nowrap;
    overflow: hidden;
    opacity: 0;
    font-size: 14px;
    transition: max-width .25s ease-in-out, opacity .15s ease-in-out;

    @media only screen and (max-width: 599px) {
      font-size: 13px;
    }
  }

  .cdk-focused,
  &:hover,
  &:focus {
    .mat-icon-button {
      z-index: 1;
      background: #fff;
      box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
      transition: all .2s ease-in-out;
    }

    .icon-text {
      max-width: 100%;
      opacity: 1;
      padding: 0 10px 0 20px;
      border: 2px solid #333;

      // &:hover,
      // &:focus {
      //   max-width: 0;
      //   padding: 0;
      //   border: 2px solid transparent;
      // }
    }
  }
}


// ===============
// ===== 15.1 =====
// Page Fab
// ===============

.page-fab {
  &.mat-fab {
    position: fixed;
    z-index: 502;
    right: 6em;
    bottom: .5em;
    z-index: 10;

    @media (max-width: 991px) {
      right: 6em;
      bottom: .5em;
    }
  }
}

.mat-button,
.mat-raised-button,
.mat-stroked-button {
  min-width: 4.571rem !important;
  line-height: 2.25rem !important;
  padding: 0.125rem 1.143em !important;

  &:hover {
    text-decoration: none;
  }

  mat-spinner {
    margin: 0 auto;
    position: absolute;
    display: inline-block;
    top: 0.5em;
    left: 0;
    right: 0;

    circle {
      stroke: rgba(0, 0, 0, .5);
    }
  }
}

// social buttons
@mixin social-button-variant($background,
  $border) {
  color: $white;
  background-color: $background;
  border-color: $border;

  &:hover,
  &:focus,
  &:active {
    color: $white;
    text-decoration: none;
    background-color: darken($background, 8%);
    border-color: darken($border, 12%)
  }

  .material-icons,
  .fa {
    color: $white;
  }
}

.mat-button,
.mat-icon-button,
.mat-raised-button,
.mat-mini-fab,
.mat-fab {
  &.mat-twitter {
    @include social-button-variant(#00c7f7,
      #00c7f7);
  }

  &.mat-facebook {
    @include social-button-variant(#335397,
      #335397);
  }

  &.mat-youtube {
    @include social-button-variant(#CC181E,
      #CC181E);
  }
}

// negative buttons

.mat-icon-button {
  &.negative_button {
    min-width: auto;
    margin-right: 0.714em;
  }
}

// =============================================
// ===================   16   ===================
//                  Mat-Drawer
// =============================================

.mat-drawer-container.elite-drawer-container {
  // min-height: calc(100vh - 4.286em); // "height:calc(100vh - 60px);" will break it, only use min-height subtracting mat-toolbar height & header

  display: flex;
  flex: 1 1 100%;
  // height: 100%;

  // @media (max-width: 1023px) {
  //   /* mobile viewport bug fix */
  //   min-height: -webkit-fill-available;
  // }

  // @media (max-width: 1279px) {
  //   height: 100%;
  // }
  .mat-drawer-content {
    height: 0px;
    max-height: 0px;
  }

  .mat-drawer {
    background-color: #ffffff;
    width: 28.571rem;
    height: 100%;
    display: flex;
    // border-right: 1px solid rgba(0, 0, 0, .08);

    &.mat-drawer_sm {
      width: 19.643em;
    }

    // @media (max-width: 349px) {
    //   padding-bottom: 1.071rem;
    // }
    .mat-drawer-inner-container {
      padding: 1.071rem; // Doing this for the scroll bar

      @media screen and (max-width: 1023px) {
        padding-bottom: 1.071rem;
      }
    }

    .mat-button-toggle-group {
      font-size: 90%;
      font-weight: 500;
    }
  }

  &.has-toolbar {
    min-height: calc(100vh - 8.857em); // "height:calc(100vh - 124px);" will break it, only use min-height subtracting mat-toolbar height & header

    /* smartphones, touchscreens */
    @media (hover: none) and (pointer: coarse) {
      min-height: -webkit-fill-available;
    }

    @media screen and (max-width: 1023px) {
      /* mobile viewport bug fix */
      min-height: -webkit-fill-available;
    }

    &.drawer-isOpen {

      /* smartphones, touchscreens */
      @media (hover: none) and (pointer: coarse) {
        height: 100%;
        max-height: calc(100vh - 16.071rem);
        min-height: calc(100vh - 16.071rem);
        /* mobile viewport bug fix */
        min-height: -webkit-fill-available;
      }

      @media screen and (max-width: 1023px) {
        height: 100%;
        max-height: calc(100vh - 16.071rem);
        min-height: calc(100vh - 16.071rem);
        /* mobile viewport bug fix */
        min-height: -webkit-fill-available;
      }
    }
  }

  &.drawer-isOpen:not(.has-toolbar) {

    /* smartphones, touchscreens */
    @media (hover: none) and (pointer: coarse) {
      height: 100%;
      max-height: calc(100vh - 16.071rem);
      min-height: calc(100vh - 16.071rem);
      /* mobile viewport bug fix */
      min-height: -webkit-fill-available;
    }

    @media screen and (max-width: 1023px) {
      height: 100%;
      max-height: calc(100vh - 12.625em);
      min-height: calc(100vh - 12.625em);
      /* mobile viewport bug fix */
      min-height: -webkit-fill-available;
    }
  }
}

.mat-drawer-container {
  .mat-drawer-content {
    height: auto;
  }

  &.drawer-isOpen {
    .mat-drawer-content {
      margin: 0 !important;
    }

    .mat-drawer {
      &.navigation-drawer {
        position: fixed;
        top: 0;
      }

      /* smartphones, touchscreens */
      @media (hover: none) and (pointer: coarse) {
        width: 97vw;
        height: 100%;
        // max-height: calc(100vh - 16.071rem);
        // min-height: calc(100vh - 16.071rem);
        // /* mobile viewport bug fix */
        min-height: -webkit-fill-available;
      }

      @media screen and (max-width: 1023px) {
        width: 97vw;
        height: 100%;
        // max-height: calc(100vh - 16.071rem);
        // min-height: calc(100vh - 16.071rem);
        // /* mobile viewport bug fix */
        min-height: -webkit-fill-available;
      }
    }
  }
}

mat-drawer-container {
  &.mat-drawer-container {
    background-color: #ffffff;
  }
}

.mat-drawer-content {
  background: #ffffff;
  width: 100%;
}

.calendar-drawer-container {

  .mat-drawer-content {
    min-height: calc(100vh - 8.857em); // "height:calc(100vh - 124px);" will break it, only use min-height subtracting mat-toolbar height & header
    max-height: calc(100vh - 8.857em);

    @media (max-width: 1023px) {
      min-width: 100%;
      min-height: 100%;
      max-height: 100%;
    }
  }
}

// =============================================
// ===================   17   ===================
//                  Mat-Spinner
// =============================================

mat-spinner {

  circle {
    stroke: #43454e;
  }
}

.busy-spinner {

  mat-spinner,
  .mat-spinner {
    margin: 0 auto;
    position: absolute;
    display: inline-block;
    top: 0.5em;
    left: 0;
    right: 0;
    z-index: 101;

    circle {
      stroke: rgba(0, 0, 0, .5);
    }
  }
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-self: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 1);
  width: 100%;
  z-index: 101;

  .mat-spinner {
    position: relative;
    display: flex;
    justify-content: center;
    align-self: center;
    z-index: 101;
  }
}

.mat-progress-spinner {

  circle {
    stroke-linecap: round;
  }

  &.mat-progress-spinner_colored {
    border-radius: 0.571rem;

    circle {
      -webkit-animation: dash 1s ease-in-out forwards;
      animation: dash 1s ease-in-out forwards;
    }

    &:nth-of-type(1) {
      circle {
        stroke: linear-gradient(to right, #aa99ff, #e09cff);
        stroke: linear-gradient(to left, #00706c, #99c5c4);
      }
    }

    &:nth-of-type(2) {
      circle {
        stroke: linear-gradient(to right, #aa99ff, #e09cff);
        stroke: linear-gradient(to right, #b452ff, #6426e0);
      }
    }

  }
}

@-webkit-keyframes dash {
  from {
    stroke-dashoffset: 0.071rem;
    stroke-dasharray: 0.071rem;
  }
}

@keyframes dash {
  from {
    stroke-dashoffset: 0.071rem;
    stroke-dasharray: 0.071rem;
  }
}

.mat-progress-spinner_percentage {
  position: absolute;

}

.progress-circle-container {
  .mat-progress-spinner {
    circle {
      stroke: #ffffff;
    }
  }
}

.loading {
  padding: 7.143em 0;
}

// =============================================
// ===================   18   ===================
//                  Mat-Select
// =============================================

// ===============
// ===== 18.1 =====
// Mat-select
// ===============

.mat-select {

  .mat-select-value {
    color: $text-color;
  }

  &.mat-select-trigger-custom {
    position: relative;
    top: -3px;

    .payIcon {

      &.mat-icon {
        height: 30px;
      }
    }

    .mat-select-arrow-wrapper {
      transform: translateY(0%);
    }
  }
}


// ===============
// ===== 18.2 =====
// Standalone select
// ===============

.standAlone,
.payWith {
  &.mat-select {
    border-radius: 4px;
    font-size: 1rem;

    .mat-select-trigger {
      padding: 0.857em 0.714em;
    }

    .mat-select-value-text,
    .mat-select-placeholder {
      font-weight: 500;
      font-size: 1rem;
    }

    .mat-select-value-text {
      font-weight: 500;
      font-size: 1rem;

      @media (max-width: 991px) {
        font-size: 1.143em;
      }
    }

    &.err {
      border: 0.143em solid $Rejected;
    }
  }
}


// ===============
// ===== 18.3 =====
// Multiple select
// ===============


.mat-option-pseudo-checkbox {
  &.mat-pseudo-checkbox-checked {
    &::after {
      width: 0.714em;
      height: 0.357em;
    }
  }
}

// =============================================
// ===================   19   ===================
//                  Mat-Card
// =============================================

.mat-dialog-container {
  .mat-card {
    &.warn {
      color: $white;
      background-color: rgba(191, 45, 41, .9);
      border-color: $Elite-Danger;
      position: relative;
      padding: .75rem 1.25rem !important;
      margin-bottom: 1rem;
      max-width: 42.786em;
      box-shadow: none;
      border-radius: 4px !important;
      margin-bottom: 1.071rem !important;

      strong {
        font-weight: 500;
      }
    }
  }
}

.mat-card {
  color: $text-color;

  @media screen and (max-width: 599px) {
    // border-radius: 0 !important;

    .mat-card {
      border-radius: 8px !important;
    }

  }

  &.Canceled,
  &.disabled {
    background: rgba(0, 0, 0, .04);

    *:not(button, a) {
      color: rgba(0, 0, 0, 0.84);
    }

    .mat-table {
      background-color: transparent !important;

      .mat-header-cell {
        background-color: transparent !important;
      }
    }

    .mat-paginator {
      background-color: transparent !important;
    }

    .mat-card-header {

      a,
      button {
        cursor: not-allowed;
        pointer-events: none;
      }
    }
  }

  &.warn {
    color: $white;
    background-color: rgba(191, 45, 41, .9);
    border-color: $Elite-Danger;
    position: relative;
    padding: .75rem 1.25rem !important;
    margin-bottom: 1rem;
    max-width: 42.786em;
    border-radius: 0 !important;

    strong {
      font-weight: 500;
    }
  }


  &.flat {
    box-shadow: none !important;
    border: 0.071rem solid rgb(218, 220, 224);
  }

  &.sm-card {
    height: auto;
    min-height: 35.714em;
    overflow-y: auto;
    padding: 3.429em 1.143em 2.571rem;

    @media screen and (max-width: 599px) {
      min-height: 100vh;
    }

  }

  .mat-card-title {
    // font-size: 1.429em;
    font-size: 1.125rem;
    font-weight: 400;

    h3 {
      font-size: inherit;
    }
  }

  .mat-card-subtitle {
    color: rgba(0, 0, 0, 0.64);
    // font-weight: 500;
  }

  .mat-card-divider {
    position: relative !important;
    margin-bottom: 1.429em !important;

    &.mb-0 {
      margin-bottom: 0 !important;
    }
  }

  &.service-card {

    .mat-card-header {
      .mat-card-header-text {
        width: 100%;
      }

      .mat-card-title {
        font-size: 1.286em;
      }
    }
  }

  &.mat-card-dashboard_header {
    border-radius: 0 0 0.571rem 0.571rem !important;
  }

  .mat-card-floating-avatar {
    position: absolute;
    font-size: 34px;
    height: 66px;
    max-height: 88px;
    width: 66px;
    max-width: 88px;
    top: -41px;
    border-radius: 50%;
    background: #fff;
    border-top: 1px solid rgba(0, 0, 0, .12);
    // box-shadow: 0px -8px 6px -7px rgba(0, 0, 0, .1);
  }

}

// =============================================
// ===================   20   ===================
//                  Mat-List
// =============================================

.mat-subheader {
  height: 3.429em;
  line-height: 1.143em;
  margin: 0;
  font-size: 1rem;
  font-weight: 500;
}

mat-action-list {
  p {
    font-size: 1rem;
    color: $text-color;
  }
}

.mat-list,
.mat-list.mat-list-base {
  padding-top: 0;

  .mat-list-item {
    color: #43454e;
    height: 3.429em;
    font-size: 14px; // Used to set px for em scaling

    @media only screen and (max-width: 599px) {
      font-size: 13px;
    }

    // .mat-list-text>h3 {
    // }
    .mat-line:nth-child(n+1) {
      font-size: 1rem;
      font-weight: 500;
      margin-bottom: 0;
    }

    .mat-line:nth-child(n+2) {
      // font-size: 1rem;
      font-size: 0.929em;
      margin-top: 0.143em;
      font-weight: 400;
      white-space: normal;
    }

    .mat-list-item-content {
      padding: 0 1.143em;
    }

    &.mat-2-line,
    &.mat-3-line {
      height: 72px;

      @media (max-width: 1279px) {
        height: auto;
        min-height: 72px;
        // margin-bottom: 10px;
      }
    }

    &.nav-list {
      &.active {
        background: #77263c;

        * {
          color: $white
        }
      }
    }
  }

  &.list-items {
    margin-top: 1.071rem;

    .mat-list-item,
    .mat-line-item.mat-list-item-with-avatar {

      &.mat-2-line,
      &.mat-3-line {
        min-height: 5.143em;
        height: 72px;
        display: flex;

        @media (max-width: 1279px) {
          height: auto;
          margin-bottom: 10px;
        }
      }

      h4 {
        white-space: normal;
        word-break: break-word;
        font-size: 1.071rem;

        &.mat-line-scroller {
          max-height: 10.714em;
          overflow-y: auto;
        }
      }

      // .sub-mat-line {
      //   font-size: 0.857em;
      //   color: #757575;
      //   margin-top: 0.143em;
      //   white-space: normal;
      // }
    }
  }

  h4 {
    &.mat-line-scroller {
      max-height: 10.714em;
      overflow-y: auto;

      .mat-chip-list-wrapper {
        margin: 0em;
      }
    }
  }

  // .mat-list-item {
  //   .mat-list-icon {
  //     &.mat-list-icon_shadow {
  //       box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
  //     }
  //   }
  // }

  .mat-list-item_textarea {
    height: auto !important;
    min-height: 4.429em !important;
  }

  .mat-line_break {
    white-space: normal;
    text-overflow: initial;
  }
}


// =============================================
// ===================   21   ===================
//                  Mat-Grid
// =============================================

.grid-container {
  margin: 0 auto;
  // padding-top: 1.714em;
  // padding-bottom: 1.714em;
  padding: 1.714em;
  overflow-x: hidden;

  // @media (max-width: 599px) {
  //   margin: 1.714em 0;
  //   padding-top: 0;
  //   padding-bottom: 0;
  // }
  @media (max-width: 599px) {
    padding-left: 0.625em;
    padding-right: 0.625em;
  }

  @media (min-width: 2100px) {
    width: 102.857em;
  }

  @media (min-width: 1300px) {
    width: 73.143em;

    &.grid-container_md {
      width: 60em;
    }

    &.grid-container_small {
      width: 42.786em;
    }

    &.grid-container_xs {
      width: 38.5em;
    }

    &.grid-container_max {
      position: relative;
      margin: 0;
      padding: 0;
      width: 100%;
    }

  }

  @media (min-width: 599px) and (max-width: 1299px) {
    margin: 0 1.714em;
    width: auto;

  }

  &.grid-container_full {
    @media (min-width: 1300px) {
      position: relative;
      margin: 0;
      padding: 1.714em;
      width: 100%;
    }

    @media (max-width: 599px) {
      width: 100%;
      padding: 1.714em 0;
      margin: 0;
    }
  }

  &.grid-container_lg {
    @media (min-width: 1500px) {
      width: 91.429em;
    }

    // @media (min-width: 2160px) {
    //   width: 117.143em;
    // }
  }



  &.grid-container_modal {
    position: relative;
    margin: 0;
    padding: 1.714em;
    width: 100%;

    @media (max-width: 599px) {
      padding: 0.857em;
    }
  }
}

.mat-drawer-container {
  background-color: #ffffff;
  color: #43454e;

  .grid-container {
    margin: 0 auto;
    padding-top: 1.714em;
    padding-bottom: 1.714em;

    @media (min-width: 599px) and (max-width: 1672px) {
      margin: 0 1.714em;
      width: auto;

    }

    &.grid-container_modal {
      margin: 0;
    }
  }

}

// =============================================
// ===================   22   ===================
//                  Mat-Chip
// =============================================

.mat-chip-list {
  &.mat-chip-list-limit-height {
    max-height: 125px;
    min-height: 46px;
    overflow-y: auto;
  }
}

.mat-chip {
  &.mat-standard-chip {
    padding: 1rem 0.714em;
    min-height: 2em;
    background-color: #f9fafc !important;
    border: 2px solid #f9fafc;
  }
}


// =============================================
// ===================   23   ===================
//                  Mat-Button-Toggle
// =============================================


.mat-button-toggle-appearance-standard {
  .mat-button-toggle-label-content {
    padding: 0 0.857em;
    line-height: 2.286em !important;
  }
}


// =============================================
// ===================   24   ===================
//                  Mat-Toolbar
// =============================================

.mat-toolbar {

  &.mat-toolbar-row,
  &.mat-toolbar-single-row {
    min-height: 64px;
    height: 4.571rem;

    @media screen and (max-width: 599px) {
      min-height: 56px;
      height: 4em;
    }
  }
}

.mat-toolbar_header {
  position: relative;
  display: block !important;
  background: #ffffff !important;
  font-size: 90%;
  z-index: 11;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: none;
  // -webkit-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
  // box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);

  @media screen and (max-width: 599px) {
    font-size: 100%;
  }

  .mat-toolbar-row_grid {
    display: block;
  }
}

.mat-toolbar_sm {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  min-height: 2.429em !important;
  padding: 0.357em 0;
  z-index: 1;
  font-size: 80%;
  background: #fff !important;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);

  .mat-toolbar-row,
  .mat-toolbar-single-row {
    height: auto;
  }
}

// =============================================
// ===================   25   ===================
//                  Mat-Table
// =============================================
.mat-table {

  .mat-header-cell,
  .mat-footer-cell,
  .mat-cell {
    &.center {
      justify-content: center;
      align-items: center;
    }
  }
}

.table-container {
  overflow: auto;
  max-width: calc(100vw - (1.714em * 2));
  touch-action: manipulation;

  @media screen and (max-width: 599px) {
    max-width: 100vw;
  }

  .mat-table {
    overflow: auto;
    max-width: calc(100vw - (1.714em * 2));
    touch-action: manipulation;

    @media screen and (max-width: 1024px) {
      max-width: 100vw;
      // max-height: calc(100vh - 300px);
    }

    .mat-header-cell,
    .mat-footer-cell,
    .mat-cell {
      color: rgba(0, 0, 0, 0.72);
      // font-weight: 500;
      white-space: normal;
      word-wrap: break-word;
      word-break: break-word;
      padding-top: 15px;
      padding-bottom: 15px;
      padding-left: 0.3125rem;
      padding-right: 0.3125rem;
      // font-size: 0.875rem;

    }

    .mat-cell,
    .mat-header-cell,
    .mat-footer-cell {
      min-width: 15%;
      max-width: 15%;
      font-size: 0.875rem;

      // @media screen and (max-width: 1024px) {
      //   min-width: 10.714em;
      //   border-width: 0;
      //   border-bottom-width: 0.071rem;
      //   border-style: solid;
      //   border-bottom-color: rgba(0, 0, 0, 0.12);
      // }
      @media screen and (max-width: 599) {
        max-width: 175px;
        min-width: 175px;
      }

      &.cell_75 {

        max-width: 75%;
        min-width: 75%;

        @media screen and (max-width: 1024px) {
          max-width: 300px;
          min-width: 300px;
        }
      }

      &.cell_70 {

        max-width: 70%;
        min-width: 70%;

        @media screen and (max-width: 1024px) {
          max-width: 300px;
          min-width: 300px;
        }
      }

      &.cell_60 {

        max-width: 60%;
        min-width: 60%;

        @media screen and (max-width: 1024px) {
          max-width: 300px;
          min-width: 300px;
        }
      }

      &.cell_50 {

        max-width: 50%;
        min-width: 50%;

        @media screen and (max-width: 1024px) {
          max-width: 300px;
          min-width: 300px;
        }
      }

      &.cell_40 {
        max-width: 40%;
        min-width: 40%;

        @media screen and (max-width: 1024px) {
          max-width: 300px;
          min-width: 300px;
        }
      }

      &.cell_30 {
        max-width: 30%;
        min-width: 30%;

        @media screen and (max-width: 1024px) {
          max-width: 300px;
          min-width: 300px;
        }
      }

      &.cell_25 {
        max-width: 25%;
        min-width: 25%;

        @media screen and (max-width: 1024px) {
          max-width: 300px;
          min-width: 300px;
        }
      }

      &.cell_20 {
        max-width: 20%;
        min-width: 20%;

        @media screen and (max-width: 1024px) {
          max-width: 225px;
          min-width: 225px;
        }
      }

      &.cell_15 {
        max-width: 15%;
        min-width: 15%;

        @media screen and (max-width: 1024px) {
          max-width: 200px;
          min-width: 200px;
        }

      }

      &.cell_10 {
        max-width: 10%;
        min-width: 10%;

        @media screen and (max-width: 1024px) {
          max-width: 150px;
          min-width: 150px;
        }

      }

      &.mat-table-cell-icon {
        max-width: 10%;
        min-width: 10%;

        @media screen and (max-width: 1024px) {
          max-width: 100px;
          min-width: 100px;
        }
      }

      .text-default {
        color: inherit;
      }
    }

    .mat-header-cell {
      top: 0em;
      position: -webkit-sticky;
      position: sticky;
      z-index: 100;
      background: #ffffff;
    }

    mat-header-row,
    mat-row,
    mat-footer-row {
      text-align: left;
      // @media screen and (min-width: 1025px) {
      border-width: 0;
      border-bottom-width: 0.071rem;
      border-style: solid;
      border-bottom-color: rgba(0, 0, 0, 0.12);


      // }
      @media screen and (max-width: 1260px) {
        min-width: 1200px;
        // width: 100%;
        width: fit-content;
      }
    }

    mat-header-row,
    mat-row {
      &:last-of-type {
        border-bottom-color: rgba(0, 0, 0, 0.0);
      }
    }

    /*unnecessary to make table header fixed*/
    .mat-header-row {
      top: 0;
      position: sticky;
      z-index: 1;
      background-color: inherit;
      text-align: center;
    }

    mat-row {

      &:hover,
      &:focus {
        background: rgba(0, 0, 0, .04);
      }

      &.Disabled,
      &.OnHold,
      &.Canceled {
        color: rgba(0, 0, 0, 0.50);

        * {
          color: rgba(0, 0, 0, 0.50);
        }

        .color1 {
          // color: #B04434;
          // display: none;
          color: transparent;
          display: block;
          margin-top: -37px;
        }
      }
    }
  }
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-size: 0.857em;
}

.mat-paginator {
  background-color: transparent !important;
}

// =============================================
// ===================   26   ===================
//                  Mat-Tabs
// =============================================

.mat-tab-group {
  .mat-tab-header {
    .mat-tab-label-container {

      .mat-tab-label,
      .mat-tab-link {
        color: #43454e;
        opacity: .70;

        &.mat-tab-label-active {
          opacity: 1;
        }
      }
    }
  }
}


// =============================================
// ===================   27   ===================
//                  Mat-Controls (custom)
// =============================================



.mat-controls {
  background: #fff;
  padding: 5px;
  border-radius: 0.571rem;
  border: 1px solid rgba(0, 0, 0, .12);
  // box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);

  &.mat-purple-gradient {
    color: #ffffff;
    background: #5456a2;
    background: linear-gradient(58deg, #5456a2 0, #7890c7 100%);

    &:hover,
    &:focus {
      background: linear-gradient(58deg, #5456a2 0, #6566ab 100%);
    }

    &:disabled {
      background: rgba(0, 0, 0, 0.12);
    }

    .vert-button_break {
      border-left: 0.071rem solid rgb(255, 255, 255, 0.42);
    }

    .mat-form-field,
    .mat-datepicker-toggle {
      color: rgba(255, 255, 255, .85);
    }

    .mat-form-field-appearance-outline {

      .mat-form-field-outline,
      .mat-form-field-outline-thick,
      .mat-form-field-label {
        color: rgba(255, 255, 255, .85);
      }

    }
  }
}


// =============================================
// ===================   28   ===================
//                 Mat-Bottom-Sheet
// =============================================


.cdk-overlay-container {

  // .cdk-overlay-pane_full-overlay {
  //   .cdk-global-overlay-wrapper .cdk-overlay-pane {
  //     max-height: 100vh !important;
  //   }
  // }

  &.cdk-overlay-pane_full-overlay {
    .cdk-global-overlay-wrapper {
      .cdk-overlay-pane {
        max-height: 100vh !important;
      }
    }
  }

  .mat-bottom-sheet-container {

    &.mat-bottom-sheet-container_full-overlay {
      min-width: 100vw;
      max-width: 100vw;
      width: 100vw;
      min-height: 100vh;
      max-height: 100vh;
      height: 100vh;
      // background: rgba(255, 255, 255, 0.95);
      background: #fff;

      @media (max-width: 575px) {
        padding-bottom: 100px;
      }
    }

    &.show-overflow {
      overflow: visible;
    }
  }
}


// =============================================
// ===================   29   ===================
//                 Mat-Tree
// =============================================

mat-tree-node,
.mat-nested-tree-node {
  .mat-tree-node {
    font-size: 1rem !important;
  }
}

// =============================================
// ===================   30   ===================
//                 Mat-Badge
// =============================================
.mat-badge.mat-badge-medium {
  .mat-badge-content {
    background: #92344f;
    color: #ffffff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3),
      0 1px 3px 1px rgba(0, 0, 0, 0.15);
  }

  &.mat-badge_danger {
    .mat-badge-content {
      background: #d4504c;
    }
  }

  &.mat-badge-flat {
    .mat-badge-content {
      box-shadow: none;
    }
  }
}

.mat-badge-medium.mat-badge-overlap.mat-badge-after {
  .mat-badge-content {
    background: #92344f;
    color: #ffffff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3),
      0 1px 3px 1px rgba(0, 0, 0, 0.15);
  }
}
